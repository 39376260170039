import { createAction } from '@reduxjs/toolkit';
import { orderService } from './orders.service';

export const fetchOrders = createAction('orders/FETCH_ORDERS_DATA');
export const fetchOrdersLoading = createAction('orders/FETCH_ORDERS_LOADING');

export const clearOrderStatuses = createAction('orders/CLEAR_ORDER_STATUSES');
export const fetchOrderStatuses = createAction('orders/FETCH_ORDER_STATUSES');
export const fetchOrderStatusesLoading = createAction('orders/FETCH_ORDER_STATUSES_LOADING');

export const clearDimWeights = createAction('orders/CLEAR_DIM_WEIGHTS');
export const fetchDimWeights = createAction('orders/FETCH_DIM_WEIGHTS');
export const fetchDimWeightsLoading = createAction('orders/FETCH_DIM_WEIGHTS_LOADING');

export const fetchSyncOrders = createAction('orders/FETCH_SYNC_ORDERS_DATA');
export const fetchSyncOrdersLoading = createAction('orders/FETCH_SYNC_ORDERS_LOADING');

export const changeBillingCycle = createAction('orders/CHANGE_BILLING_CYCLE_DATA');
export const changeBillingCycleLoading = createAction('orders/CHANGE_BILLING_CYCLE_LOADING');

export const fetchChoices = createAction('orders/FETCH_CHOICES_DATA');
export const fetchChoicesLoading = createAction('orders/FETCH_CHOICES_LOADING');

export const setOrderFilters = createAction('orders/SET_FILTERS');
export const resetFilters = createAction('orders/RESET_FILTERS');

export const getOrders =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchOrdersLoading(true));
    orderService
      .getOrders(params)
      .then((data) => {
        dispatch(fetchOrders(data));
      })
      .catch(() => {
        dispatch(fetchOrdersLoading(false));
      });
  };

export const getOrderStatuses =
  (id = '') =>
  async (dispatch) => {
    dispatch(fetchOrderStatusesLoading(true));
    orderService
      .getOrderStatuses(id)
      .then((data) => {
        dispatch(fetchOrderStatuses(data));
      })
      .catch(() => {
        dispatch(fetchOrderStatusesLoading(false));
      });
  };

export const getDimWeights =
  (id = '') =>
  async (dispatch) => {
    dispatch(fetchDimWeightsLoading(true));
    orderService
      .getDimWeights(id)
      .then((data) => {
        dispatch(fetchDimWeights(data));
      })
      .catch(() => {
        dispatch(fetchDimWeightsLoading(false));
      });
  };

export const getChoices = () => async (dispatch) => {
  dispatch(fetchChoicesLoading(true));
  orderService
    .getOrderFilters()
    .then((data) => {
      dispatch(fetchChoices(data));
    })
    .catch(() => {
      dispatch(fetchChoicesLoading(false));
    });
};

export const updateBillingCycle =
  (body = {}) =>
  async (dispatch) => {
    dispatch(changeBillingCycleLoading(true));
    orderService
      .changeBillingCycle(body)
      .then((code) => {
        dispatch(changeBillingCycle(code));
      })
      .catch(() => {
        dispatch(changeBillingCycle(500));
      });
  };

export const getReporter = (description) => {
  if (!description) {
    return '';
  }

  const createdBy = description.match(/Reported by [a-zA-Z]+ [a-zA-Z]+/g);

  let reporter = '';
  if (createdBy && createdBy.length) {
    const splitCreatedBy = createdBy[0].split(' ');
    reporter = `${splitCreatedBy[2]} ${splitCreatedBy[3]}`;
  }

  return reporter;
};

export const removeReporter = (description) => {
  if (!description) {
    return '';
  }

  return description.replace(/Reported by [a-zA-Z]+ [a-zA-Z]+/g, '');
};

export const getAuthor = (body) => {
  if (!body) {
    return '';
  }

  const authoredBy = body.match(/Authored by [a-zA-Z]+ [a-zA-Z]+/g);

  let reporter = '';
  if (authoredBy && authoredBy.length) {
    const splitAuthoredBy = authoredBy[0].split(' ');
    reporter = `${splitAuthoredBy[2]} ${splitAuthoredBy[3]}`;
  }

  return reporter;
};

export const removeAuthor = (body) => {
  if (!body) {
    return '';
  }

  return body.replace(/Authored by [a-zA-Z]+ [a-zA-Z]+/g, '');
};

import { createAction } from '@reduxjs/toolkit';
import { nonComputedOrdersService } from './non-computed-orders.service';

export const fetchNonComputedOrders = createAction('non-computed-orders/FETCH_NON_COMPUTED_ORDERS');
export const fetchNonComputedOrdersLoading = createAction(
  'non-computed-orders/FETCH_NON_COMPUTED_ORDERS_LOADING'
);

export const getNonComputedOrders = (id) => async (dispatch) => {
  dispatch(fetchNonComputedOrdersLoading(true));
  nonComputedOrdersService
    .getNonComputedOrders(id)
    .then((orders) => {
      dispatch(fetchNonComputedOrders(orders));
    })
    .catch(() => {
      dispatch(fetchNonComputedOrdersLoading(false));
    });
};

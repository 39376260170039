import auth0 from 'auth0-js';
import { history } from './store';

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const CONNECTION_TYPE = 'Username-Password-Authentication';
const state = generateString(32);

const configAuth0 = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/callback`,
  audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
  scope: 'openid profile email',
  responseType: 'token id_token',
  state
};

const auth0Client = new auth0.WebAuth(configAuth0);

export const login = (email, password) => {
  return new Promise((resolve, reject) => {
    localStorage.setItem('state', state);
    auth0Client.login({ realm: CONNECTION_TYPE, email, password }, (error, result) => {
      if (error) {
        reject(error);
      } else if (result) {
        resolve(result);
      }
    });
  });
};

export const handleAuthentication = () => {
  auth0Client.parseHash({ state: localStorage.getItem('state') }, (err, authResult) => {
    if (authResult && authResult.accessToken) {
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', authResult.idTokenPayload.exp * 1000); // default 10 hours

      auth0Client.client.userInfo(authResult.accessToken, (err, userInfo) => {
        const nickname = userInfo.nickname.split('.');
        const firstname = nickname[0].charAt(0).toUpperCase() + nickname[0].slice(1);
        const lastname = nickname[1].charAt(0).toUpperCase() + nickname[1].slice(1);
        const profile = {
          fullname: `${firstname} ${lastname}`,
          roles: userInfo['https://finance.janio.asia/roles'],
          ...userInfo
        };
        localStorage.setItem('profile', JSON.stringify(profile));
        history.replace('/track-orders');
      });
    } else if (err) {
      history.replace('/');
    }
  });
};

const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  return Date.now() < expiresAt;
};

function generateString(length) {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const logout = (e) => {
  e.preventDefault();
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('profile');
  localStorage.removeItem('state');
  auth0Client.logout(
    {
      returnTo: `${window.location.origin}/login`,
      clientID: configAuth0.clientID
    },
    () => {}
  );
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    throw new Error('No access token found');
  }
  return accessToken;
};

export const getProfile = () => {
  const profile = localStorage.getItem('profile');
  return profile ? JSON.parse(profile) : {};
};

export const isLoggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = localStorage.getItem('access_token');
  return !!token && isAuthenticated();
};

export const forgotPassword = (e, email, callback) => {
  e.preventDefault();
  auth0Client.changePassword(
    {
      connection: 'Username-Password-Authentication',
      email
    },
    (err, resp) => {
      callback(err, resp);
    }
  );
};

import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchChoices,
  fetchChoicesLoading,
  fetchInvoice,
  fetchInvoiceLoading,
  resetFilters,
  setInvoiceFilters,
  setInvoiceStatus
} from './invoices.action';

const initialState = {
  invoices: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  filters: {
    status: 'All',
    page: 1,
    records: '50',
    trackingNumber: null,
    merchantName: null,
    billingStatus: 1,
    shippingType: null,
    action: 'All',
    fromDate: null,
    toDate: null,
    createdDate: [
      moment().subtract(1, 'month').startOf('month').toISOString(),
      moment().endOf('day').toISOString()
    ],
    origin: 'All',
    destination: 'All',
    invoiceNumber: null,
    weightLimitStart: null,
    weightLimitEnd: null,
    originZone: null,
    destinationZone: null
  },
  choices: {
    loading: false,
    billingCycles: []
  }
};

const invoices = createReducer(initialState, {
  [fetchInvoice](state, action) {
    const { invoices, total } = action.payload;
    state.invoices = {
      data: invoices,
      total,
      loading: false
    };
  },
  [fetchInvoiceLoading](state, action) {
    state.invoices.loading = action.payload;
  },
  [fetchChoicesLoading](state, action) {
    state.choices.loading = action.payload;
  },
  [fetchChoices](state, action) {
    const { billing_cycles } = action.payload;
    state.choices = {
      loading: false,
      billingCycles: [
        {
          value: 'All',
          label: 'All'
        },
        ...billing_cycles.map((billing_cycle) => ({
          value: billing_cycle,
          label: billing_cycle
        }))
      ]
    };
  },
  [setInvoiceStatus](state, action) {
    const payload = state.invoices.data.map((item) => {
      const temp = { ...item };
      if (action.payload.invoice_ids.includes(temp.invoiceDisplayId)) {
        temp.status = 2;
      }
      return temp;
    });
    state.invoices.data = payload;
  },
  [setInvoiceFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
    state.invoices = initialState.invoices;
  }
});

const transformFilters = createTransform(
  (inboundState) => {
    // persist
    return { records: inboundState.records };
  },
  (outboundState) => {
    // hydrate
    return { ...initialState.filters, records: outboundState.records };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['filters'] }
);

export default persistReducer(
  {
    key: 'invoices',
    storage,
    whitelist: ['filters'],
    transforms: [transformFilters]
  },
  invoices
);

import { createReducer } from '@reduxjs/toolkit';
import { fetchTicketLoading, fetchTickets, resetFilters, setFilters } from './tickets.action';

const initialState = {
  data: [],
  total: null,
  loading: false,
  filters: {
    page: 1,
    status: null,
    issueKey: null,
    summary: null,
    priority: null,
    created: []
  }
};

const tickets = createReducer(initialState, {
  [fetchTickets](state, action) {
    const { results, total } = action.payload;
    state.data = results;
    state.total = total;
    state.loading = false;
  },
  [fetchTicketLoading](state, action) {
    state.loading = action.payload;
  },
  [setFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
    state.data = initialState.data;
  }
});

export default tickets;

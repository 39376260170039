import styled from '@emotion/styled';
import { Input as AntInput, Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input, SearchInput } from '../../../components';
import { resetFilters, setFilters, setRangeFilters } from '../pricing-group-rules.action';

const FilterContainer = styled.div`
  user-select: none;
  width: 800px;
  height: 310px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 0 8px !important;
  }
  .inputRange {
    .rangeStart {
      border-right: 0px;
      width: 42%;
      text-align: center;
    }
    .rangeEnd {
      width: 42%;
      text-align: center;
      border-left: 0;
    }
    .rangeInputSplit {
      width: 16%;
      border-left: 0;
      border-right: 0;
      background-color: #fff;
      pointer-events: none;
    }
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdownPricingGroupRules = ({
  onFilter = () => {},
  currencies = [],
  originZones = [],
  destinationZones = [],
  orderTypes = []
}) => {
  const node = useRef();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState();
  const { loading } = useSelector((state) => state.pricingGroupRules.choices);
  const currency = useSelector((state) => state.pricingGroupRules.filters.currency);
  const weightUpperBound = useSelector((state) => state.pricingGroupRules.filters.weightUpperBound);
  const orderType = useSelector((state) => state.pricingGroupRules.filters.orderType);
  const orderDirection = useSelector((state) => state.pricingGroupRules.filters.orderDirection);
  const pricingType = useSelector((state) => state.pricingGroupRules.filters.pricingType);
  const originZone = useSelector((state) => state.pricingGroupRules.filters.originZone);
  const destinationZone = useSelector((state) => state.pricingGroupRules.filters.destinationZone);

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };

  const handleFilter = () => {
    onFilter({
      currency,
      originZone,
      destinationZone,
      pricingType,
      orderType,
      orderDirection,
      weightUpperBound:
        weightUpperBound && (weightUpperBound.min || weightUpperBound.max) ? weightUpperBound : null
    });
    setVisible(false);
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setFilters({ [field]: option?.props?.value }));
  };

  const handleRangeChange = (field, key) => (e) => {
    dispatch(setRangeFilters({ field, key, value: e.target.value }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        label="Filter"
        visible={visible}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[24, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={8} className="field-container">
                  <label htmlFor="currency">Currency</label>
                  <SearchInput
                    id="currency"
                    items={currencies}
                    loading={loading}
                    value={currency}
                    onChange={handleSearchInputChange('currency')}
                    allowClear={true}
                  />
                </Col>
                <Col span={8} className="field-container">
                  <label htmlFor="origin-zone">Origin Zone</label>
                  <SearchInput
                    id="origin-zone"
                    items={originZones}
                    loading={loading}
                    value={originZone}
                    onChange={handleSearchInputChange('originZone')}
                    allowClear={true}
                  />
                </Col>
                <Col span={8} className="field-container">
                  <label htmlFor="destination-zone">Destination Zone</label>
                  <SearchInput
                    id="destination-zone"
                    items={destinationZones}
                    loading={loading}
                    value={destinationZone}
                    onChange={handleSearchInputChange('destinationZone')}
                    allowClear={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={8} className="field-container">
                  <label htmlFor="amountType">Amount Type</label>
                  <SearchInput
                    id="amountType"
                    items={['FLAT', 'RATE']}
                    loading={loading}
                    value={pricingType}
                    onChange={handleSearchInputChange('pricingType')}
                    allowClear={true}
                  />
                </Col>
                <Col span={8} className="field-container">
                  <label htmlFor="orderType">Service Type</label>
                  <SearchInput
                    id="orderType"
                    items={orderTypes}
                    loading={loading}
                    value={orderType}
                    onChange={handleSearchInputChange('orderType')}
                    allowClear={true}
                  />
                </Col>
                <Col span={8} className="field-container">
                  <label htmlFor="orderDirection">Order Direction</label>
                  <SearchInput
                    id="orderDirection"
                    items={['FORWARD', 'RETURNS']}
                    loading={loading}
                    value={orderDirection}
                    onChange={handleSearchInputChange('orderDirection')}
                    allowClear={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={24} className="field-container">
                  <label htmlFor="weightUpperBound">Weight Upper Bound</label>
                  <AntInput.Group compact className="inputRange">
                    <Input
                      className="rangeStart"
                      placeholder="Min"
                      value={weightUpperBound.min}
                      onChange={handleRangeChange('weightUpperBound', 'min')}
                    />
                    <Input className="site-input-split rangeInputSplit" placeholder="~" disabled />
                    <Input
                      className="site-input-right rangeEnd"
                      placeholder="Max"
                      value={weightUpperBound.max}
                      onChange={handleRangeChange('weightUpperBound', 'max')}
                    />
                  </AntInput.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="actions">
            <Button type="link" size="small" className="reset-filter" onClick={handleReset}>
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdownPricingGroupRules;

import request from '../../utils/request';

const getZones = async () => {
  const url = `/pricing/zones`;
  const response = await request().get(url);
  return response.data;
};

const getCountries = async () => {
  const url = `/pricing/countries`;
  const response = await request().get(url);
  return response.data;
};

const getMetadata = async () => {
  const url = `/pricing/metadata`;
  const response = await request().get(url);
  return response.data;
};

const getSurchargeTemplate = async () => {
  const url = `/pricing/surcharges/download`;
  const response = await request().get(url, {
    responseType: 'arraybuffer'
  });
  return response.data;
};

const uploadSurcharges = async (file) => {
  const url = `/pricing/surcharges`;
  const formData = new FormData();
  formData.append('file', file);
  const response = await request().post(url, formData);
  return response.data;
};

const surchargesClientMapping = async (data) => {
  const url = `/pricing/clients/surcharges`;
  const response = await request().post(url, data);
  return response.data;
};

const deleteSurcharge = async (id) => {
  const url = `/pricing/surcharges/${id}`;
  return await request().delete(url);
};

export const surchargeService = {
  getZones,
  getCountries,
  getMetadata,
  getSurchargeTemplate,
  uploadSurcharges,
  surchargesClientMapping,
  deleteSurcharge
};

import { createAction } from '@reduxjs/toolkit';
import service from './tickets.service';

export const fetchTickets = createAction('tickets/FETCH_TICKETS_DATA');
export const fetchTicketLoading = createAction('tickets/FETCH_TICKETS_LOADING');
export const setFilters = createAction('tickets/SET_FILTERS');
export const resetFilters = createAction('tickets/RESET_FILTERS');

export const getAllTickets =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchTicketLoading(true));
    service
      .getTickets(params)
      .then((data) => {
        dispatch(fetchTickets(data));
      })
      .catch(() => {
        dispatch(fetchTicketLoading(false));
      });
  };

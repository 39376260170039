import { Col, Divider, Row, Skeleton as AntSkeleton } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../components';
import { getFullname } from '../../../utils/formatters';
import { getPricingGroupRules } from '../pricing-group-details.action';

const columns = [
  {
    title: 'Origin Zone',
    dataIndex: 'origin_zone',
    key: 'origin_zone'
  },
  {
    title: 'Destination Zone',
    dataIndex: 'destination_zone',
    key: 'destination_zone'
  },
  {
    title: 'Category Type',
    dataIndex: 'order_type',
    key: 'order_type',
    width: 130
  },
  {
    title: 'Order Direction',
    dataIndex: 'order_direction',
    key: 'order_direction',
    width: 145
  },
  // {
  //   title: 'Tier',
  //   dataIndex: 'tier',
  //   key: 'tier',
  //   width: 80
  // },
  {
    title: 'Pricing Type',
    dataIndex: 'pricing_type',
    key: 'pricing_type',
    width: 120
  },
  {
    title: 'Weight UB.',
    dataIndex: 'weight_upper_bound',
    key: 'weight_upper_bound',
    width: 115
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    fixed: 'right',
    width: 85
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    fixed: 'right',
    width: 65
  }
];

const handleDateFormat = (text) => (text ? moment.utc(text).format('DD MMM YYYY') : '-');

const PricingGroupInfo = ({ pricingGroup }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const {
    data,
    total,
    loading: tableLoading
  } = useSelector((state) => state.pricingGroupDetails.rules);

  const handlePage = (page = 1) => {
    setPage(page);
    dispatch(getPricingGroupRules(pricingGroup.name, { page }));
  };

  useEffect(() => {
    dispatch(getPricingGroupRules(pricingGroup.name));
  }, [dispatch, pricingGroup]);

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col>
          <h3>Pricing Group Details</h3>
        </Col>
      </Row>

      <h5>General Info</h5>
      <Row gutter={[24, 16]}>
        <Col span={16}>
          <em className="label">Name</em>
          <span className="value">{pricingGroup.name}</span>
        </Col>
        <Col span={4}>
          <em className="label">Type</em>
          <span className="value">{pricingGroup.type}</span>
        </Col>
        <Col span={4}>
          <em className="label">Start Date</em>
          <span className="value">{handleDateFormat(pricingGroup.start_date)}</span>
        </Col>
        {/* <Col span={4}>
          <em className="label">End Date</em>
          <span className="value">{handleDateFormat(pricingGroup.end_date)}</span>
        </Col> */}
        <Col span={4} offset={16}>
          <em className="label">Created On</em>
          <span className="value">{handleDateFormat(pricingGroup.created_on)}</span>
        </Col>
        <Col span={4}>
          <em className="label">Created By</em>
          <span className="value">{getFullname(pricingGroup.created_by)}</span>
        </Col>
      </Row>

      <Divider />

      <h5>Rules ({total})</h5>
      <Row>
        <Table
          renderEmptyTable
          rowKey={(row) => `${row.id}`}
          columns={columns}
          loading={tableLoading}
          dataSource={data}
          pageSize={50}
          currentPage={page}
          totalCount={total}
          onChange={(page) => handlePage(page)}
          scroll={{ x: 1100 }}
          empty={
            <AntSkeleton
              active
              title={{ width: '100%' }}
              paragraph={{
                rows: 5,
                width: ['100%', '100%', '100%', '100%', '100%']
              }}
            />
          }
        />
      </Row>
    </>
  );
};

export default PricingGroupInfo;

import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionLink, Card, SelectDropdown, Table } from '../../components';
import { convertSingaporeTimeToLocal } from '../../utils/formatters';
import { withRouter } from '../../utils/wrappers';
import { getNonComputedOrders } from './non-computed-orders.action';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
  h1 {
    color: #000;
    margin: 20px;
    text-align: center;
  }
  h4 {
    color: #000;
    margin-bottom: 0px;
  }
  label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: rgb(112, 112, 112);
    margin-bottom: 5px;
    display: block;
  }
  .section {
    margin-top: 20px;
  }
  .priceValue {
    color: #000;
    font-weight: bold;
  }
  .error {
    margin-top: 5px;
  }
`;

const NonComputedOrders = (props) => {
  const dispatch = useDispatch();
  const { invoiceID } = props.params;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { data, loading } = useSelector((state) => state.nonComputedOrders);

  useEffect(() => {
    if (invoiceID) dispatch(getNonComputedOrders(invoiceID));
  }, [dispatch, invoiceID]);

  const columns = [
    {
      title: 'Tracking No.',
      dataIndex: 'tracking_no',
      key: 'tracking_no',
      fixed: 'left'
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (text, record) => <ActionLink to={`/clients/${record.client_id}`}>{text}</ActionLink>
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
      render: (text) => (text ? convertSingaporeTimeToLocal(text).format('DD MMM YYYY') : '-')
    },
    {
      title: 'First Touch Date',
      dataIndex: 'first_touch_date',
      key: 'first_touch_date',
      render: (text) => (text ? convertSingaporeTimeToLocal(text).format('DD MMM YYYY') : '-')
    },
    {
      title: 'Payment Mode',
      dataIndex: 'payment_mode',
      key: 'payment_mode',
      render: (text) => {
        let paymentMode = '-';
        if (text === '1') {
          paymentMode = 'Pre Paid';
        } else if (text === '2') {
          paymentMode = 'Post Paid';
        }
        return paymentMode;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ];

  const handleFilter = (page = 1, size = 10) => {
    setPage(page);
    setSize(size);
  };

  return (
    <StyledCard>
      <Row type="flex" style={{ marginBottom: '25px' }} gutter={16}>
        <Col style={{ marginLeft: 'auto' }}>
          <SelectDropdown
            value={size}
            items={[
              { label: '10 items/page', value: 10 },
              { label: '50 items/page', value: 50 },
              { label: '100 items/page', value: 100 }
            ]}
            onChange={(size) => handleFilter(1, size)}
          />
        </Col>
      </Row>
      <Row>
        <Table
          loading={loading}
          renderEmptyTable
          rowKey={(row) => `${row.tracking_no}`}
          columns={columns}
          dataSource={data}
          pageSize={size}
          currentPage={page}
          totalCount={data.length}
          onChange={(page) => handleFilter(page, size)}
          empty={
            <>
              {!loading && (
                <div>
                  <span>No data found.</span>&nbsp;
                </div>
              )}
              {loading && (
                <AntSkeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 1,
                    width: ['100%']
                  }}
                />
              )}
            </>
          }
        />
      </Row>
    </StyledCard>
  );
};

export default withRouter(NonComputedOrders);

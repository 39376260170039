import styled from '@emotion/styled';
import { Col, Divider, message, Row } from 'antd';
import axios from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import Button from '../../components/Button';
import Card from '../../components/Card';
import FloatInput from '../../components/FloatInput';
import Message from '../../components/Message';
import SearchInput from '../../components/SearchInput';
import VolumnInput from '../../components/VolumnInput';
import CATEGORIES from '../../constants/categories';
import { DESTINATION_ZONES, ORIGIN_ZONES } from '../../constants/zones';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 10px 40px;
  h1 {
    color: #000;
    margin: 20px;
    text-align: center;
  }
  h4 {
    color: #000;
    margin-bottom: 0px;
  }
  label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: rgb(112, 112, 112);
    margin-bottom: 5px;
    display: block;
  }
  .section {
    margin-top: 20px;
  }
  .priceValue {
    color: #000;
    font-weight: bold;
  }
  .error {
    margin-top: 5px;
  }
`;

const initialValues = {
  origin_zone: undefined,
  destination_zone: undefined,
  volumn: {
    length: undefined,
    width: undefined,
    height: undefined
  },
  weight: undefined,
  product_category: undefined,
  incoterm: 'DDP',
  price: undefined,
  currency: 'SGD'
};

const validationSchema = yup.object().shape({
  origin_zone: yup.string().nullable().required('Please select Origin Zone.'),
  destination_zone: yup.string().nullable().required('Please select Destination Zone.'),
  volumn: yup.object().shape({
    length: yup.string().nullable().required('Please enter Length.'),
    width: yup.string().nullable().required('Please enter Width.'),
    height: yup.string().nullable().required('Please enter Height.')
  }),
  weight: yup.string().nullable().required('Please enter Weight.'),
  product_category: yup.string().nullable().required('Please select Category.'),
  incoterm: yup.string().nullable().required('Please select Incoterm.'),
  price: yup.string().nullable().required('Please enter Price.'),
  currency: yup.string().nullable().required('Please select Currency.')
});

const CostEstimation = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [pricing, setPricing] = useState(undefined);

  const originZones = ORIGIN_ZONES.map((obj) => ({
    label: obj.label,
    value: `${obj.zone}+${obj.countryCode}`
  }));
  const destinationZones = DESTINATION_ZONES.map((obj) => ({
    label: obj.label,
    value: `${obj.zone}+${obj.countryCode}`
  }));

  const onSubmit = async (values) => {
    setSubmitting(true);
    const origin_location = values.origin_zone.split('+');
    const destination_location = values.destination_zone.split('+');
    const basePriceRequest = {
      origin_zone: origin_location[0],
      destination_zone: destination_location[0],
      length: values?.volumn?.length,
      width: values?.volumn?.width,
      height: values?.volumn?.height,
      weight: values.weight,
      client_id: 8,
      origin_country: origin_location[1],
      destination_country: destination_location[1],
      incoterm: values.incoterm,
      items: [
        {
          product_category: values.product_category,
          price: values.price,
          currency: values.currency
        }
      ]
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/pricing/pricing',
        basePriceRequest
      );
      const responseData = response?.data?.data;
      setSubmitting(false);
      setPricing(responseData);
    } catch (err) {
      message.error('An error occured while fetching pricing');
      setSubmitting(false);
    }
  };

  return (
    <StyledCard>
      <h1>{'Cost Estimation'}</h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, touched, errors, values, setFieldValue }) => (
          <div style={{ padding: 20 }}>
            <Row gutter={[24, 16]}>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="origin_zone">Origin Zone</label>
                <SearchInput
                  id="origin_zone"
                  allowClear
                  items={originZones}
                  value={values.origin_zone}
                  onChange={(value) => {
                    setFieldValue('origin_zone', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.origin_zone && touched?.origin_zone && (
                  <Message type="error">{errors?.origin_zone}</Message>
                )}
              </Col>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="destination_zone">Destination Zone</label>
                <SearchInput
                  id="destination_zone"
                  allowClear
                  items={destinationZones}
                  value={values.destination_zone}
                  onChange={(value) => {
                    setFieldValue('destination_zone', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.destination_zone && touched?.destination_zone && (
                  <Message type="error">{errors?.destination_zone}</Message>
                )}
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="volumn">Volumn (L x W x H)</label>
                <VolumnInput
                  id="volumn"
                  value={values.volumn}
                  onChange={(value) => {
                    setFieldValue('volumn', value);
                  }}
                />
                {errors?.volumn && touched?.volumn && Object.keys(errors?.volumn).length > 0 && (
                  <Message type="error">
                    {errors?.volumn?.length || errors?.volumn?.width || errors?.volumn?.height}
                  </Message>
                )}
              </Col>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="weight">Weight</label>
                <FloatInput
                  id="weight"
                  value={values.weight}
                  onChange={(e) => {
                    setFieldValue('weight', e.target.value);
                  }}
                  placeholder="Type here..."
                />
                {errors?.weight && touched?.weight && (
                  <Message type="error">{errors?.weight}</Message>
                )}
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="product_category">Category</label>
                <SearchInput
                  id="product_category"
                  items={CATEGORIES}
                  value={values.product_category}
                  onChange={(value) => {
                    setFieldValue('product_category', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.product_category && touched?.product_category && (
                  <Message type="error">{errors?.product_category}</Message>
                )}
              </Col>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="incoterm">Incoterm</label>
                <SearchInput
                  id="incoterm"
                  items={[{ value: 'DDP', label: 'DDP' }]}
                  value={values.incoterm}
                  onChange={(value) => {
                    setFieldValue('incoterm', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.incoterm && touched?.incoterm && (
                  <Message type="error">{errors?.incoterm}</Message>
                )}
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="price">Price</label>
                <FloatInput
                  id="price"
                  value={values.price}
                  onChange={(e) => {
                    setFieldValue('price', e.target.value);
                  }}
                  placeholder="Type here..."
                />
                {errors?.price && touched?.price && <Message type="error">{errors?.price}</Message>}
              </Col>
              <Col span={12} xxl={{ span: 6 }}>
                <label htmlFor="currency">Currency</label>
                <SearchInput
                  id="currency"
                  items={['SGD']}
                  value={values.currency}
                  onChange={(value) => {
                    setFieldValue('currency', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.currency && touched?.currency && (
                  <Message type="error">{errors?.currency}</Message>
                )}
              </Col>
            </Row>
            <Row>
              {pricing && typeof pricing === 'string' && (
                <div>
                  <Message type="error">{pricing}</Message>
                </div>
              )}
              {pricing && typeof pricing !== 'string' && (
                <div>
                  <div className="section">
                    <h4>Base Price</h4>
                    <span>
                      With chargeable weight of{' '}
                      {pricing.overriden_chargable_weight
                        ? pricing.overriden_chargable_weight
                        : pricing.chargable_weight}{' '}
                      kg
                    </span>
                    <div className="priceValue">
                      {pricing.currency}{' '}
                      {parseFloat(
                        pricing.unitPrice ? pricing.unitPrice : pricing.base_cost || 0
                      ).toFixed(2)}
                    </div>
                  </div>
                  {pricing?.items.map((item, idx) => (
                    <div key={idx}>
                      <Divider />
                      {item?.hs_code && (
                        <>
                          <div className="section">
                            <h4>HS Code</h4>
                            <span>{item?.hs_code}</span>
                          </div>
                          <Divider />
                        </>
                      )}
                      {Object.keys(item?.duties).length !== 0 && (
                        <>
                          <div className="section">
                            <h4>Duties</h4>
                            <span>{item?.duties?.description}</span>
                            <div className="priceValue">
                              {values?.currency} {parseFloat(item?.duties?.amount || 0).toFixed(2)}
                            </div>
                          </div>
                          <Divider />
                        </>
                      )}
                      {item?.taxes?.length !== 0 &&
                        item?.taxes?.map((tax, index) => (
                          <div key={index}>
                            <div className="section">
                              <h4>{tax.name}</h4>
                              <span>{tax?.description}</span>
                              <div className="priceValue">
                                {values?.currency} {parseFloat(tax?.amount || 0).toFixed(2)}
                              </div>
                            </div>
                            <Divider />
                          </div>
                        ))}
                      <div className="section">
                        <h4>
                          Total {values.currency}{' '}
                          {parseFloat(pricing?.total_amount || 0).toFixed(2)}
                        </h4>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Row>
            <Divider />
            <Row>
              <Button
                type="primary"
                style={{ width: 150 }}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Row>
          </div>
        )}
      </Formik>
    </StyledCard>
  );
};

export default CostEstimation;

import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/Dropdown';
import Input from '../../../components/Input';
import SearchInput from '../../../components/SearchInput';
import { resetFilters, setOrderFilters } from '../orders.action';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  user-select: none;
  width: 1000px;
  height: 270px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 5px 8px !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdownOrder = ({ loading: isLoading, onFilter = () => {} }) => {
  const node = useRef();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState();

  const { clients, statuses, loading } = useSelector((state) => state.orders.choices);

  const clientId = useSelector((state) => state.orders.filters.clientId);
  const status = useSelector((state) => state.orders.filters.status);
  const trackingNumber = useSelector((state) => state.orders.filters.trackingNumber);
  const firstTouchDate = useSelector((state) =>
    state.orders.filters.firstTouchDate
      ? state.orders.filters.firstTouchDate?.map((d) => moment(d)) || []
      : []
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleFilter = () => {
    onFilter({
      trackingNumber,
      firstTouchDate,
      status: status === 'All' ? null : status,
      clientId: clientId === 'All' ? null : clientId
    });
    setVisible(false);
  };

  const handleInputChange = (field) => (e) => {
    dispatch(setOrderFilters({ [field]: e.target.value }));
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setOrderFilters({ [field]: option?.props?.value }));
  };

  const handleDatePickerChange = (field) => (value) => {
    dispatch(
      setOrderFilters({
        [field]: value?.length
          ? [value[0]?.startOf('day').toISOString(), value[1]?.endOf('day').toISOString()]
          : []
      })
    );
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        label="Filter"
        visible={visible}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]} className="field-row">
            <Col span={8} className="field-container">
              <label htmlFor="tracking-number">Tracking No.</label>
              <Input
                id="tracking-number"
                placeholder="Type tracking no ..."
                value={trackingNumber}
                onChange={handleInputChange('trackingNumber')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="client-name">Client Name</label>
              <SearchInput
                id="client-name"
                allowClear
                placeholder="Type or select..."
                items={[{ key: 'All', value: 'All', label: 'All' }, ...clients]}
                value={clientId}
                loading={loading}
                onChange={handleSearchInputChange('clientId')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="status">Status</label>
              <SearchInput
                id="status"
                allowClear
                placeholder="Type or select..."
                items={[{ key: 'All', value: 'All', label: 'All' }, ...statuses]}
                value={status}
                onChange={handleSearchInputChange('status')}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="field-row">
            <Col span={8} className="field-container">
              <label htmlFor="first-touch-date">First Touch Date</label>
              <RangePicker
                id="first-touch-date"
                contained
                allowClear
                value={firstTouchDate}
                onChange={handleDatePickerChange('firstTouchDate')}
              />
            </Col>
          </Row>

          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              onClick={handleReset}
              disabled={isLoading}
            >
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleFilter} disabled={isLoading}>
              Filter
            </Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdownOrder;

import { DeleteTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, List, message, Modal, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { Anchor, Button } from '../../../components';
import { formatBytes, formatDateDisplay } from '../../../utils/formatters';
import service from '../ticket-details.service.js';

const StyledDiv = styled.div`
  font-family: AvenirLTStdHeavy;
  font-size: 16px;
  color: #000000;
  span.value {
    font-family: AvenirLTStdBook;
    line-height: 1.25;
    word-break: break-word;

    &.anchor {
      color: #050593;
    }
  }
  .view-link {
    color: #1d1d9d;
    cursor: pointer;
  }
  .header {
    font-size: 14px;
    font-style: normal;
    color: #707070;
    display: block;
  }
`;

const TicketAttachments = ({ ticketId, data = [] }) => {
  const [id, setId] = useState('');
  const [view, setView] = useState(false);
  const [content, setContent] = useState([]);
  const [filename, setFilename] = useState('');
  const [visible, setVisible] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const props = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    beforeUpload: (file, files) => {
      const key = `upload-${new Date().getTime()}`;
      message.loading({ content: 'Uploading...', key });

      service
        .addAttachments(ticketId, files)
        .then((res) => {
          message.success({ content: 'Uploaded!', key });
          setAttachments([...res, ...attachments]);
        })
        .catch(() => {
          message.error({ content: 'Failed to upload attachment', key });
        });

      return false;
    }
  };

  const confirmDelete = (id, name) => {
    setId(id);
    setFilename(name);
    setVisible(true);
  };

  const handleDelete = () => {
    const key = `delete-${new Date().getTime()}`;
    message.loading({ content: 'Deleting...', key });

    service
      .deleteAttachment(id)
      .then((id) => {
        message.success({ content: 'Deleted!', key });
        setVisible(false);

        const newAttachments = attachments.filter((item) => item.id !== id);
        setAttachments(newAttachments);
      })
      .catch(() => {
        message.error({ content: 'Failed to delete attachment', key });
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const afterClose = () => {
    setId('');
    setFilename('');
  };

  useEffect(() => {
    if (view) {
      setContent(attachments);
    } else {
      setContent(attachments?.slice(0, 5));
    }
  }, [view]);

  useEffect(() => {
    if (view) {
      setContent(attachments);
    } else {
      setContent(attachments?.slice(0, 5));
    }
  }, [attachments]);

  useEffect(() => {
    setAttachments(data);
  }, [data]);

  return (
    <StyledDiv>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <h5>
            Attachments{' '}
            <Upload {...props}>
              <PlusCircleTwoTone twoToneColor="#5050f9" style={{ cursor: 'pointer' }} />
            </Upload>
          </h5>

          <div>
            <List
              header={
                <Row gutter={[24, 16]}>
                  <Col span={14}>
                    <span className="header">Filename</span>
                  </Col>
                  <Col span={4}>
                    <span className="header">Size</span>
                  </Col>
                  <Col span={4}>
                    <span className="header">Uploaded On</span>
                  </Col>
                </Row>
              }
              footer={
                attachments?.length > 5 && (
                  <div style={{ textAlign: 'center' }}>
                    <>
                      {!view && (
                        <span className="view-link" onClick={() => setView(true)}>
                          View All
                        </span>
                      )}
                      {view && (
                        <span className="view-link" onClick={() => setView(false)}>
                          View Less
                        </span>
                      )}
                    </>
                  </div>
                )
              }
              bordered
              size="small"
              dataSource={content}
              renderItem={(item) => (
                <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                  <Col span={14}>
                    <Anchor href={item.content}>
                      <span className="value anchor">{item.filename}</span>
                    </Anchor>
                  </Col>
                  <Col span={4}>
                    <span className="value">{formatBytes(item.size)}</span>
                  </Col>
                  <Col span={4}>
                    <span className="value">
                      {formatDateDisplay(item.created).format('DD MMM YYYY')}
                    </span>
                  </Col>
                  <Col span={2}>
                    <DeleteTwoTone
                      twoToneColor="red"
                      style={{ cursor: 'pointer' }}
                      onClick={() => confirmDelete(item.id, item.filename)}
                    />
                  </Col>
                </Row>
              )}
            />
          </div>
        </Col>
      </Row>

      <Modal
        title="Delete"
        visible={visible}
        onOk={handleDelete}
        onCancel={handleCancel}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Button key="back" type="secondary" onClick={handleCancel}>
            No
          </Button>,
          <Button key="submit" onClick={handleDelete}>
            Yes
          </Button>
        ]}
      >
        <p>
          Are you sure want to delete <strong>{filename}</strong>?
        </p>
      </Modal>
    </StyledDiv>
  );
};

export default TicketAttachments;

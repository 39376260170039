import { formatToISOString, queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const getOrders = async ({
  page = 1,
  size = 100,
  trackingNumber = '',
  clientId = '',
  status = '',
  firstTouchDate = []
}) => {
  const params = removeEmpty({
    page,
    size,
    trackingNumber,
    clientId,
    status,
    firstTouchDate: firstTouchDate.length > 0 ? firstTouchDate.map(formatToISOString) : null
  });

  const queryParams = queryString(params);
  const url = `/pricing/orders?${queryParams}`;
  const response = await request().get(url);
  return response.data.data;
};

const getOrderStatuses = async (id) => {
  const url = `/pricing/orders/${id}/statuses`;
  const response = await request().get(url);
  return response.data.data;
};

const getDimWeights = async (id) => {
  const url = `/pricing/orders/${id}/weights`;
  const response = await request().get(url);
  return response.data.data;
};

const getOrderFilters = async () => {
  const url = '/pricing/orders/filters';
  const response = await request().get(url);
  return response.data.data;
};

const changeBillingCycle = async (body = {}) => {
  const url = '/pricing/tns';
  const response = await request().patch(url, body);
  return response.data.code;
};

export const orderService = {
  getOrders,
  getOrderStatuses,
  getDimWeights,
  getOrderFilters,
  changeBillingCycle
};

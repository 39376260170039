import axios from 'axios';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

export function useAxiosRequest(request, params) {
  // TODO: fix this to be just accept request(params), instead of (request,params)
  const isCancelled = useRef(false);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [requestData, setRequestData] = useState(null);
  useEffect(() => {
    setLoading(true);
    const axiosRequest = request(params);
    axiosRequest
      .exec()
      .then((response) => {
        if (!isCancelled.current) {
          setRequestData(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!isCancelled.current) {
          if (!axios.isCancel(error)) {
            setRequestError(error);
          }
          setLoading(false);
        }
        isCancelled.current = false;
      });
    return () => {
      isCancelled.current = true;
      axiosRequest.cancel();
    };
  }, [params]);
  return [requestData, loading, requestError];
}

export function useDebounce(propValue, onChange = () => {}, timeout = 3000) {
  // debounced react controlled component 😎
  const [value, setValue] = useState(() => propValue);

  const debouncedOnChangeRef = useRef();
  debouncedOnChangeRef.current = onChange;

  const debouncedOnChange = useCallback(debounce(debouncedOnChangeRef.current, timeout), [
    onChange
  ]);

  const inputEventRef = useRef();

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  useEffect(() => {
    if (inputEventRef.current) {
      debouncedOnChange(inputEventRef.current);
    }
  }, [value]);

  const handleOnChange = (e) => {
    e.persist();
    inputEventRef.current = e;
    setValue(e.target.value);
  };
  return [value, handleOnChange];
}

export function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useScript(url) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
}

import { createAction } from '@reduxjs/toolkit';
import service from './reports.service';

export const fetchReports = createAction('reports/FETCH_REPORTS_DATA');
export const fetchReportLoading = createAction('reports/FETCH_REPORTS_LOADING');

export const getReports = () => async (dispatch) => {
  dispatch(fetchReportLoading(true));
  service
    .getReports()
    .then((data) => {
      dispatch(fetchReports(data));
    })
    .catch(() => {
      dispatch(fetchReportLoading(false));
    });
};

import { createAction } from '@reduxjs/toolkit';
import service from './pricing-group-details.service';

export const fetchPricingGroupDetails = createAction('pricingGroup/FETCH_PRICING_GROUP_DETAILS');
export const fetchPricingGroupDetailsLoading = createAction(
  'pricingGroup/FETCH_PRICING_GROUP_DETAILS_LOADING'
);

export const fetchPricingGroupRules = createAction('pricingGroup/FETCH_PRICING_GROUP_RULES');
export const fetchPricingGroupRulesLoading = createAction(
  'pricingGroup/FETCH_PRICING_GROUP_RULES_LOADING'
);

export const getPricingGroupDetails = (name) => async (dispatch) => {
  dispatch(fetchPricingGroupDetailsLoading(true));
  service
    .getPricingGroupDetails(name)
    .then((data) => {
      dispatch(fetchPricingGroupDetails(data));
    })
    .catch(() => {
      dispatch(fetchPricingGroupDetailsLoading(false));
    });
};

export const getPricingGroupRules =
  (name, params = {}) =>
  async (dispatch) => {
    dispatch(fetchPricingGroupRulesLoading(true));
    service
      .getPricingGroupRules(name, params)
      .then((data) => {
        dispatch(fetchPricingGroupRules(data));
      })
      .catch(() => {
        dispatch(fetchPricingGroupRulesLoading(false));
      });
  };

/**
 * Parsing Jira Markup format string to Markdown format string
 *
 * @param {string} str Jira Markup format string
 * @returns Markdown format string
 */
export const jira2markdown = (str) => {
  return (
    str
      // Un-ordered Lists
      .replace(/^[ \t]*(\*+)\s+/gm, function (match, stars) {
        return Array(stars.length).join('\t') + '* ';
      })
      // Ordered lists
      .replace(/^[ \t]*(#+)\s+/gm, function (match, nums) {
        return Array(nums.length).join('\t') + '1. ';
      })
      // Headers 1-6
      .replace(/^h([0-6])\.(.*)$/gm, function (match, level, content) {
        return Array(parseInt(level) + 1).join('#') + content;
      })
      // Bold
      .replace(/\*(\S.*)\*/g, '**$1**')
      // Italic
      .replace(/_(\S.*)_/g, '*$1*')
      // Monospaced text
      .replace(/\{\{([^}]+)\}\}/g, '`$1`')
      // Underline (convert to bold)
      .replace(/\+([^+]*)\+/g, '**$1**')
      // Superscript  (convert to italic)
      .replace(/\^([^^]*)\^/g, '*$1*')
      // Subscript  (convert to italic)
      .replace(/~([^~]*)~/g, '*$1*')
      // Quote (convert to bold italic)
      .replace(/{quote}/g, '***')
      // Horizontal Line
      .replace(/----/g, '---')
      // Strikethrough
      .replace(/(\s+)-(\S+.*?\S)-(\s+)/g, '$1~~$2~~$3')
      // Code Block
      .replace(
        /\{code(:([a-z]+))?([:|]?(title|borderStyle|borderColor|borderWidth|bgColor|titleBGColor)=.+?)*\}([^]*?)\n?\{code\}/gm,
        '```$2\n$5\n```'
      )
      // Pre-formatted text
      .replace(/{noformat}/g, '\n```\n')
      // Images
      .replace(/!(.+)\|.+!/g, "Attached '$1'")
      // Attachment (file)
      .replace(/\[\^(.+)\]/g, "Attached '$1'")
      // Un-named Links
      .replace(/\[([^|]+?)\]/g, '<$1>')
      // Named Links
      .replace(/\[(.+?)\|(.+?)\]/g, '[$1]($2)')
      // Remove jira smart link
      .replace(/\|smart-link/g, '')
      // Single Paragraph Blockquote
      .replace(/^bq\.\s+/gm, '> ')
      // Remove color: unsupported in md
      .replace(/\{color:[^}]+\}([^]*)\{color\}/gm, '$1')
      // Panel into table
      .replace(/\{panel:title=([^}]*)\}\n?([^]*?)\n?\{panel\}/gm, '\n| $1 |\n| --- |\n| $2 |')
      // Table header
      .replace(/^[ \t]*((?:\|\|.*?)+\|\|)[ \t]*$/gm, function (match, headers) {
        var singleBarred = headers.replace(/\|\|/g, '|').replace(/\*/g, '');
        return '\n' + singleBarred + '\n' + singleBarred.replace(/\|[^|]+/g, '| --- ');
      })
      // Remove leading-space of table headers and rows
      .replace(/^[ \t]*\|/gm, '|')
  );
};

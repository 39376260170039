import { createReducer } from '@reduxjs/toolkit';
import {
  fetchNonComputedOrders,
  fetchNonComputedOrdersLoading
} from './non-computed-orders.action';

const initialState = {
  data: [],
  loading: false
};

export default createReducer(initialState, {
  [fetchNonComputedOrdersLoading](state, action) {
    state.loading = action.payload;
  },
  [fetchNonComputedOrders](state, action) {
    state.data = action.payload;
    state.loading = false;
  }
});

import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FETCH_METADATA, FETCH_ZONES } from './surcharges.action';
import { Card } from '../../components';
import Surcharge from './shared/Surcharge';
import SurchargeMapping from './shared/SurchargeMapping';
import TabContent from '../../components/TabContent';
import Tabs from '../../components/Tabs';
import { withRouter } from '../../utils/wrappers';
import { surchargeService } from './surcharges.service';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 20px 30px;
  min-height: 400px;
`;

const tabs = {
  surcharges: {
    title: 'Surcharges',
    component: Surcharge
  },
  mapping: {
    title: 'Surcharge Mapping',
    component: SurchargeMapping
  }
};

const Surcharges = (props) => {
  const { clientID } = props.params;
  const [activeTab, setActiveTab] = useState(clientID ? 'mapping' : 'surcharges');
  const handleTabChange = useCallback((tabKey) => {
    setActiveTab(tabKey);
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    surchargeService.getZones().then((data) => {
      dispatch(FETCH_ZONES(data?.data));
    });
    surchargeService.getMetadata().then((data) => {
      dispatch(FETCH_METADATA(data?.data));
    });
  }, [dispatch]);

  return (
    <StyledCard>
      <Tabs activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange} />
      <TabContent activeTab={activeTab} tabs={tabs} clientID={clientID} />
    </StyledCard>
  );
};

export default withRouter(Surcharges);

import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/Dropdown';
import Input from '../../../components/Input';
import SearchInput from '../../../components/SearchInput';
import { resetFilters, setInvoiceFilters } from '../billing-records.action';

const FilterContainer = styled.div`
  user-select: none;
  width: 1000px;
  height: 370px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 0 8px !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdownBilling = ({
  label,
  onFilter = () => {},
  originZones = [],
  destinationZones = [],
  originCountries = [],
  destinationCountries = []
}) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  // eslint-disable-next-line no-unused-vars
  const [endOpen, setEndOpen] = useState(false);
  const dispatch = useDispatch();
  const { statuses, loading } = useSelector((state) => state.billingRecords.choices);
  const status = useSelector((state) => state.billingRecords.filters.status);
  const trackingNumber = useSelector((state) => state.billingRecords.filters.trackingNumber);
  const weightLimitStart = useSelector((state) => state.billingRecords.filters.weightLimitStart);
  const weightLimitEnd = useSelector((state) => state.billingRecords.filters.weightLimitEnd);
  const invoiceNumber = useSelector((state) => state.billingRecords.filters.invoiceNumber);
  const merchantName = useSelector((state) => state.billingRecords.filters.merchantName);
  const billingType = useSelector((state) => state.billingRecords.filters.billingType);
  const originZone = useSelector((state) => state.billingRecords.filters.originZone);
  const destinationZone = useSelector((state) => state.billingRecords.filters.destinationZone);
  const originCountry = useSelector((state) => state.billingRecords.filters.originCountry);
  const destinationCountry = useSelector(
    (state) => state.billingRecords.filters.destinationCountry
  );
  const action = useSelector((state) => state.billingRecords.filters.action);
  const fromDate = useSelector((state) =>
    state.billingRecords.filters.fromDate ? moment(state.billingRecords.filters.fromDate) : null
  );
  const toDate = useSelector((state) =>
    state.billingRecords.filters.toDate ? moment(state.billingRecords.filters.toDate) : null
  );
  const origin = useSelector((state) => state.billingRecords.filters.origin);
  const destination = useSelector((state) => state.billingRecords.filters.destination);

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };
  const handleFilter = () => {
    onFilter({
      status: status === 'All' ? null : status,
      trackingNumber,
      invoiceNumber,
      merchantName,
      billingType,
      action: action === 'All' ? null : action,
      fromDate,
      weightLimitStart,
      weightLimitEnd,
      toDate,
      origin: origin === 'All' ? null : origin,
      destination: destination === 'All' ? '' : destination,
      originZone,
      destinationZone,
      originCountry,
      destinationCountry
    });
    setVisible(false);
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setInvoiceFilters({ [field]: option?.props?.value }));
  };

  const handleStartOpenChange = (open) => {
    if (!open && !toDate) {
      setEndOpen(true);
    }
  };

  // const handleEndOpenChange = (open) => {
  //   setEndOpen(open);
  // };

  const disabledStartDate = (startDate) => {
    if (!startDate || !toDate) {
      return false;
    }
    return startDate.valueOf() > toDate.valueOf();
  };

  // const disabledEndDate = (endDate) => {
  //   if (!endDate || !fromDate) {
  //     return false;
  //   }
  //   return endDate.valueOf() <= fromDate.valueOf();
  // };

  const handleDatePickerChange = (field) => (value) => {
    if (field === 'fromDate') {
      dispatch(setInvoiceFilters({ fromDate: value.startOf('day').toISOString() }));
    }
    if (field === 'toDate') {
      dispatch(setInvoiceFilters({ toDate: value.endOf('day').toISOString() }));
    }
  };

  const handleTrackerChange = (e) => {
    dispatch(setInvoiceFilters({ trackingNumber: e.target.value }));
  };

  const handleWeightLimitStartChange = (e) => {
    dispatch(setInvoiceFilters({ weightLimitStart: e.target.value }));
  };

  const handleWeightLimitEndChange = (e) => {
    dispatch(setInvoiceFilters({ weightLimitEnd: e.target.value }));
  };

  const handleInvoiceChange = (e) => {
    dispatch(setInvoiceFilters({ invoiceNumber: e.target.value }));
  };

  const handleOriginZoneChange = (e) => {
    dispatch(setInvoiceFilters({ originZone: e }));
  };

  const handleDestinationZoneChange = (e) => {
    dispatch(setInvoiceFilters({ destinationZone: e }));
  };

  const handleOriginCountryChange = (e) => {
    dispatch(setInvoiceFilters({ originCountry: e }));
  };

  const handleDestinationCountryChange = (e) => {
    dispatch(setInvoiceFilters({ destinationCountry: e }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Filter'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <header>By Invoice</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="tracking-number">Invoice Number</label>
                  <Input
                    id="tracking-number"
                    placeholder="Type or select..."
                    value={invoiceNumber}
                    onChange={handleInvoiceChange}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="tracking-number">Tracking Number</label>
                  <Input
                    id="tracking-number"
                    placeholder="Type or select..."
                    value={trackingNumber}
                    onChange={handleTrackerChange}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="chargable-weight">Chargable Weight Start</label>
                  <Input
                    id="chargable-weight"
                    placeholder="Start weight..."
                    value={weightLimitStart}
                    onChange={handleWeightLimitStartChange}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="chargable-weight">Chargable Weight End</label>
                  <Input
                    id="chargable-weight"
                    placeholder="End weight..."
                    value={weightLimitEnd}
                    onChange={handleWeightLimitEndChange}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="merchant-name">Billing Status</label>
                  <SearchInput
                    allowClear
                    placeholder="Type or select..."
                    id="merchant-name"
                    items={[
                      { value: 1, key: 1, label: 'To Invoice' },
                      { value: 2, key: 2, label: 'Billed' },
                      { value: 3, key: 3, label: 'On Hold' },
                      { value: 4, key: 4, label: 'Cancelled' },
                      { value: 5, key: 5, label: 'Lost' },
                      { value: 6, key: 6, label: 'Others' }
                    ]}
                    // loading={loading}
                    value={merchantName}
                    onChange={handleSearchInputChange('merchantName')}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="merchant-name">Service Type</label>
                  <SearchInput
                    allowClear
                    placeholder="Type or select..."
                    id="merchant-name"
                    items={[
                      { value: 1, key: 1, label: 'Shipping' },
                      { value: 2, key: 2, label: 'Surcharge' }
                    ]}
                    // loading={loading}
                    value={billingType}
                    onChange={handleSearchInputChange('billingType')}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <header>By Date</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="from-date">Shipment Date</label>
                  <DatePicker
                    id="from-date"
                    disabledDate={disabledStartDate}
                    value={fromDate}
                    onChange={handleDatePickerChange('fromDate')}
                    onOpenChange={handleStartOpenChange}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="quote-public-status">Billing Cycle</label>
                  <SearchInput
                    id="quote-public-status"
                    items={statuses}
                    loading={loading}
                    value={status}
                    onChange={handleSearchInputChange('status')}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="origin-country">Origin Country</label>
                  <SearchInput
                    id="origin-country"
                    items={originCountries}
                    loading={loading}
                    value={originCountry}
                    onChange={handleOriginCountryChange}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="destination-country">Destination Country</label>
                  <SearchInput
                    id="destination-country"
                    items={destinationCountries}
                    loading={loading}
                    value={destinationCountry}
                    onChange={handleDestinationCountryChange}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="origin-zone">Origin Zone</label>
                  <SearchInput
                    id="origin-zone"
                    items={originZones}
                    loading={loading}
                    value={originZone}
                    onChange={handleOriginZoneChange}
                  />
                </Col>
                <Col span={12} style={{ marginTop: 10 }} className="field-container">
                  <label htmlFor="destination-zone">Destination Zone</label>
                  <SearchInput
                    id="destination-zone"
                    items={destinationZones}
                    loading={loading}
                    value={destinationZone}
                    onChange={handleDestinationZoneChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="actions">
            <Button type="link" size="small" className="reset-filter" onClick={handleReset}>
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdownBilling;

import { Col, Divider, message, Row, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, MultiSelect, SearchInput } from '../../../components';
import {
  BILLING_CYCLES,
  BILLING_DAYS,
  BILLING_ENTITIES,
  DIM_WEIGHT_PREFERENCES,
  WEIGHT_POLICIES
} from '../../../constants';
import { withRouter } from '../../../utils/wrappers';
import { getClientDetail } from '../client-details.action';
import { clientService } from '../client-details.service';

const ClientInfo = ({ params, client, editMode = false, setEditMode = () => {} }) => {
  const dispatch = useDispatch();
  const { clientID } = params;
  // Remove edit client config feature temporary
  // const { roles } = getProfile();
  // const isAdmin = roles.includes(ROLES.ADMIN);
  const isAdmin = false;
  const [loading, setLoading] = useState(false);
  const [billingEntity, setBillingEntity] = useState('');
  const [consolidateInvoice, setConsolidateInvoice] = useState(false);
  const [dailyBilling, setDailyBilling] = useState(false);
  const [dayOfMonth, setDayOfMonth] = useState([]);
  const [daysBeforeBillingCreated, setDaysBeforeBillingCreated] = useState('');
  const [dimWeightPreference, setDimWeightPreference] = useState(undefined);
  const [weightPolicy, setWeightPolicy] = useState(undefined);

  const getBillingCycle = (billingCycles = []) => {
    if (!billingCycles.length) {
      return '-';
    }

    if (billingCycles.length === 31) {
      return 'Daily';
    }

    return billingCycles.join(', ');
  };

  const getDimWeightPreference = (dimWeightPreference) => {
    switch (dimWeightPreference) {
      case '1':
        return 'Janio Dimweight';
      case '2':
        return 'Partner Dimweight';
      case '3':
        return 'Client Dimweight';
      default:
        return 'Default';
    }
  };

  const handleBillingEntity = (value) => {
    setBillingEntity(value);
  };

  const handleDayOfMonth = (value) => {
    setDayOfMonth(value);
  };

  const handleDaysBeforeBillingCreated = (value) => {
    setDaysBeforeBillingCreated(value);
  };

  const handleConsolidatedInvoice = (value) => {
    setConsolidateInvoice(value);
  };

  const handleDailyBilling = (value) => {
    setDailyBilling(value);
  };

  const handleDimWeightPreference = (value) => {
    setDimWeightPreference(value);
  };

  const handleWeightPolicy = (value) => {
    setWeightPolicy(value);
  };

  const handleCancel = () => {
    setBillingEntity(client.billing_entity);
    setConsolidateInvoice(client.consolidate_invoice);
    setDailyBilling(client.daily_billing);
    setDayOfMonth(client.day_of_month);
    setDaysBeforeBillingCreated(client.days_before_billing_created);
    setDimWeightPreference(client.dim_weight_preference);
    setWeightPolicy(client.weight_policy);
  };

  const handleSave = () => {
    const key = `client-info-${new Date().getTime()}`;
    let body = {};

    setLoading(true);
    message.loading({ content: 'Processing...', key });

    if (client.billing_entity !== billingEntity) {
      body.billing_entity = billingEntity;
    }

    if (client.consolidate_invoice !== consolidateInvoice) {
      body.consolidate_invoice = consolidateInvoice;
    }

    if (dailyBilling === false && dayOfMonth.length === 31) {
      setLoading(false);
      message.warn({
        content: 'Billing Cycle (day of month) cannot be 31 days if the Daily Billing is uncheck',
        key
      });
      return;
    }

    if (client.daily_billing !== dailyBilling) {
      body.daily_billing = dailyBilling;
    }

    const dayOfMonthSorted = dayOfMonth.slice().sort((a, b) => a - b);
    if (
      !(
        client.day_of_month.length === dayOfMonthSorted.length &&
        client.day_of_month.every((value, index) => value === dayOfMonthSorted[index])
      ) ||
      client.days_before_billing_created !== daysBeforeBillingCreated
    ) {
      if (!dayOfMonthSorted.length) {
        setLoading(false);
        message.warn({ content: 'Billing Cycle (day of month) cannot be empty', key });
        return;
      }

      body.day_of_month = dayOfMonthSorted;
      body.days_before_billing_date_orders_created = Number(daysBeforeBillingCreated);
    }

    if (client.dim_weight_preference !== dimWeightPreference) {
      body.dim_weight_preference = dimWeightPreference;
    }

    if (client.weight_policy !== weightPolicy) {
      body.weight_policy = weightPolicy;
    }

    if (!Object.keys(body).length) {
      setLoading(false);
      message.warn({ content: 'No changes have been made', key });
      return;
    }

    clientService
      .editClientConfig(clientID, body)
      .then(() => {
        setLoading(false);
        setEditMode(false);
        message.success({ content: 'Saved!', key });
        dispatch(getClientDetail(clientID));
      })
      .catch((err) => {
        setLoading(false);
        message.error({ content: 'Failed to process', key });
        console.error(err);
      });
  };

  useEffect(() => {
    setBillingEntity(client.billing_entity);
    setConsolidateInvoice(client.consolidate_invoice);
    setDailyBilling(client.daily_billing);
    setDayOfMonth(client.day_of_month);
    setDaysBeforeBillingCreated(client.days_before_billing_created);
    setDimWeightPreference(client.dim_weight_preference);
    setWeightPolicy(client.weight_policy);
  }, [client]);

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col>
          <h3>{editMode ? 'Edit Configuration' : 'Client Details'}</h3>
        </Col>
        {isAdmin ? (
          <Col style={{ marginLeft: 'auto' }}>
            <Button
              type={editMode ? 'primary' : 'secondary'}
              onClick={() => {
                setEditMode(!editMode);
                if (editMode) {
                  handleCancel();
                }
              }}
            >
              Edit
            </Button>
          </Col>
        ) : (
          <div />
        )}
      </Row>

      <h5>Personal Info</h5>
      <Row gutter={[24, 16]}>
        <Col span={6}>
          <em className="label">Name</em>
          <span className="value">{client.name}</span>
        </Col>
        <Col span={6}>
          <em className="label">Email</em>
          <span className="value">{client.email}</span>
        </Col>
        <Col span={6}>
          <em className="label">Contact</em>
          <span className="value">{client.contact}</span>
        </Col>
        <Col span={6}>
          <em className="label">Billing Entity</em>
          {editMode && !client.billing_entity ? (
            <SearchInput
              placeholder="Type or select..."
              id="status"
              items={BILLING_ENTITIES}
              value={billingEntity}
              onChange={handleBillingEntity}
            />
          ) : (
            <span className="value">{billingEntity || '-'}</span>
          )}
        </Col>
      </Row>

      <Divider />

      <h5>Configurations</h5>
      <Row gutter={[24, 16]}>
        <Col span={6}>
          <em className="label">Billing Cycle (day of month)</em>
          {editMode ? (
            <MultiSelect
              name="labels"
              maxTagCount="responsive"
              disabled={dailyBilling}
              items={BILLING_CYCLES}
              value={dayOfMonth}
              onChange={handleDayOfMonth}
            />
          ) : (
            <span className="value">{getBillingCycle(dayOfMonth)}</span>
          )}
        </Col>
        <Col span={6}>
          <em className="label">Billing Cycle (day before billing created)</em>
          {editMode ? (
            <SearchInput
              placeholder="Type or select..."
              id="billing-date"
              disabled={dailyBilling}
              items={BILLING_DAYS}
              value={daysBeforeBillingCreated}
              onChange={handleDaysBeforeBillingCreated}
            />
          ) : (
            <span className="value">{daysBeforeBillingCreated || '-'}</span>
          )}
        </Col>
        <Col span={6}>
          <em className="label">Consolidated Invoice</em>
          {editMode ? (
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={consolidateInvoice}
              onChange={handleConsolidatedInvoice}
            />
          ) : (
            <span className="value">{consolidateInvoice ? 'Yes' : 'No'}</span>
          )}
        </Col>
        <Col span={6}>
          <em className="label">Daily Billing</em>
          {editMode ? (
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={dailyBilling}
              onChange={handleDailyBilling}
            />
          ) : (
            <span className="value">{dailyBilling ? 'Yes' : 'No'}</span>
          )}
        </Col>
        <Col span={6}>
          <em className="label">Dim Weight Preference (Only Postpaid)</em>
          {editMode ? (
            <SearchInput
              allowClear
              placeholder="Type or select..."
              id="dim-weight-preference"
              items={DIM_WEIGHT_PREFERENCES}
              value={dimWeightPreference}
              onChange={handleDimWeightPreference}
            />
          ) : (
            <span className="value">{getDimWeightPreference(dimWeightPreference)}</span>
          )}
        </Col>
        <Col span={6}>
          <em className="label">Weight Policy</em>
          {editMode ? (
            <SearchInput
              allowClear
              placeholder="Type or select..."
              id="weight-policy"
              items={WEIGHT_POLICIES}
              value={weightPolicy}
              onChange={handleWeightPolicy}
            />
          ) : (
            <span className="value">{weightPolicy || '-'}</span>
          )}
        </Col>
      </Row>

      {editMode ? (
        <Row gutter={[24, 16]}>
          <Divider />

          <Col style={{ marginLeft: 'auto' }}>
            <Button className="ant-btn-dangerous" disabled={loading} onClick={handleCancel}>
              Reset
            </Button>
          </Col>
          <Col>
            <Button loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(ClientInfo);

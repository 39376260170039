import { createAction } from '@reduxjs/toolkit';
import { pricingGroupRulesService } from './pricing-group-rules.service';

export const fetchPricingGroups = createAction('pricingGroupRules/FETCH_DATA');
export const fetchPricingGroupsLoading = createAction('pricingGroupRules/FETCH_DATA_LOADING');

export const fetchPricingGroupRules = createAction('pricingGroupRules/FETCH_RULES_DATA');
export const fetchPricingGroupRulesLoading = createAction(
  'pricingGroupRules/FETCH_RULES_DATA_LOADING'
);

export const fetchChoices = createAction('pricingGroupRules/FETCH_CHOICES');
export const fetchChoicesLoading = createAction('pricingGroupRules/FETCH_CHOICES_LOADING');

export const setRangeFilters = createAction('pricingGroupRuleFilter/SET_RANGE_FILTERS');
export const setFilters = createAction('pricingGroupRuleFilter/SET_FILTERS');
export const resetFilters = createAction('pricingGroupRuleFilter/RESET_FILTERS');

export const getPricingGroup =
  (name = '') =>
  async (dispatch) => {
    dispatch(fetchPricingGroupsLoading(true));
    pricingGroupRulesService
      .getPricingGroup(name)
      .then((data) => {
        dispatch(fetchPricingGroups(data));
      })
      .catch(() => {
        dispatch(fetchPricingGroupsLoading(false));
      });
  };

export const getPricingGroupRules =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchPricingGroupRulesLoading(true));
    pricingGroupRulesService
      .getPricingGroupRules(params)
      .then((data) => {
        dispatch(fetchPricingGroupRules(data));
      })
      .catch(() => {
        dispatch(fetchPricingGroupRulesLoading(false));
      });
  };

export const getChoices = () => async (dispatch) => {
  dispatch(fetchChoicesLoading(true));
  pricingGroupRulesService
    .getPricingGroupFilters()
    .then((data) => {
      dispatch(fetchChoices(data));
    })
    .catch(() => {
      dispatch(fetchChoicesLoading(false));
    });
};

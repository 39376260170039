import { InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, Modal, notification, Row, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, MultiSelect, Select, TextArea, UploadField } from '../';
import { LABELS, PRIORITIES, TICKET_TYPES } from '../../constants';
import { formatBytesToMegabytes } from '../../utils/formatters.js';
import { createTicket } from './create-ticket.service.js';

const { Text } = Typography;

const StyledDiv = styled.div`
  font-family: AvenirLTStdBook;
  font-size: 16px;
`;

const ItemDiv = styled.div`
  margin-bottom: 30px;
`;

const initialState = {
  summary: '',
  description: '',
  ticketType: TICKET_TYPES.TASK,
  priority: PRIORITIES.MEDIUM,
  labels: [],
  files: []
};

const CreateTicketModal = ({ data = null, visible = false, setVisible = () => {} }) => {
  const [size, setSize] = useState(0);
  const [type, setType] = useState('secondary');
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);
  const [{ summary, description, ticketType, priority, labels, files }, setState] =
    useState(initialState);

  const handleText = (e) => {
    const { name, value } = e.target;

    if (name === 'summary') {
      const trimValue = value.trim();
      if (!trimValue) {
        setError(true);
        return;
      }

      setError(false);
    }

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleValue = (name) => (value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onFileChange = (newFiles) => {
    setState((prevState) => ({ ...prevState, files: [...files, ...newFiles] }));

    let total = size;
    for (const file of newFiles) {
      total += file.size;
    }

    setSize(total);
    if (Number(formatBytesToMegabytes(total)) > 5) {
      setType('danger');
    }
  };

  const onFileRemove = (file) => {
    const newFiles = files.filter((item) => item.uid !== file.uid);
    setState((prevState) => ({ ...prevState, files: newFiles }));

    let total = size;
    total -= file.size;

    setSize(total);
    if (Number(formatBytesToMegabytes(total)) < 5) {
      setType('secondary');
    }
  };

  const handleDisabledSubmit = () => {
    if (!error) {
      if (Number(formatBytesToMegabytes(size)) > 5) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const handleOk = () => {
    setLoading(true);

    const body = {
      summary: summary.trim(),
      description: description.trim(),
      ticketType,
      priority,
      labels,
      files
    };

    const key = `notification-${Date.now()}`;

    createTicket(body)
      .then((res) => {
        setLoading(false);
        setVisible(false);

        const btn = (
          <Button
            type="link"
            href={`/tickets/${res.key}`}
            target="_blank"
            onClick={() => notification.close(key)}
          >
            View ticket
          </Button>
        );

        notification.success({
          key,
          btn,
          message: `You've created "${res.key}" ticket`,
          description: `Click the link to view the ticket.`,
          placement: 'bottomLeft',
          duration: 0
        });
      })
      .catch(() => {
        setLoading(false);

        notification.error({
          key,
          message: `Couldn't create the ticket`,
          description: `We're having some trouble creating the ticket. Refresh the page or try again.`,
          placement: 'bottomLeft',
          duration: 0
        });
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const afterClose = () => {
    setState({ ...initialState });
    setSize(0);
    setType('secondary');
    setError(true);
  };

  useEffect(() => {
    const stringifyData = JSON.stringify(data, null, '\t');
    const replaceData = stringifyData.replace(/[[\]{}",\t]/g, '').trim();

    setState((prevState) => ({
      ...prevState,
      ['description']: replaceData
    }));
  }, [data]);

  return (
    <Modal
      title="Create ticket"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={820}
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
      afterClose={afterClose}
      footer={[
        <Tooltip key="back" title="Your changes won't be saved">
          <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
            Cancel
          </Button>
        </Tooltip>,
        <Button key="submit" loading={loading} disabled={handleDisabledSubmit()} onClick={handleOk}>
          Submit
        </Button>
      ]}
    >
      <StyledDiv>
        <ItemDiv>
          <label htmlFor="summary">
            Summary <Text type="danger">*</Text>{' '}
            <Tooltip placement="right" title="A brief description of this ticket. (Required)">
              <InfoCircleOutlined />
            </Tooltip>
          </label>
          <TextArea
            name="summary"
            value={summary}
            maxLength={255}
            onChange={handleText}
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
        </ItemDiv>

        <ItemDiv>
          <label htmlFor="description">Description</label>
          <TextArea
            name="description"
            value={description}
            onChange={handleText}
            autoSize={{ minRows: 5, maxRows: 25 }}
          />
        </ItemDiv>

        <ItemDiv>
          <label htmlFor="priority">Priority</label>
          <Select name="priority" value={priority} onChange={handleValue('priority')}>
            <Select.Option value={PRIORITIES.HIGHEST}>Highest</Select.Option>
            <Select.Option value={PRIORITIES.HIGH}>High</Select.Option>
            <Select.Option value={PRIORITIES.MEDIUM}>Medium</Select.Option>
            <Select.Option value={PRIORITIES.LOW}>Low</Select.Option>
            <Select.Option value={PRIORITIES.LOWEST}>Lowest</Select.Option>
          </Select>
        </ItemDiv>

        <ItemDiv>
          <label htmlFor="labels">Labels</label>
          <MultiSelect
            name="labels"
            items={LABELS}
            value={labels}
            onChange={handleValue('labels')}
          />
        </ItemDiv>

        <ItemDiv>
          <Row gutter={16}>
            <Col>
              Attachments{' '}
              <Tooltip placement="right" title="File size limit (total) is 5 MB">
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <Typography.Text type={type}>
                Total size: {formatBytesToMegabytes(size)} MB
              </Typography.Text>
            </Col>
          </Row>
          <UploadField onChange={onFileChange} onRemove={onFileRemove} />
        </ItemDiv>
      </StyledDiv>
    </Modal>
  );
};

export default CreateTicketModal;

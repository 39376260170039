import styled from '@emotion/styled';
import { Input } from 'antd';
import React, { forwardRef } from 'react';
import { useDebounce } from '../../utils/hooks';

const { TextArea: AntTextArea } = Input;

const StyledTextArea = styled(AntTextArea)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  color: #000;
  &:hover {
    border-color: #050593;
  }
  &:focus {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &:hover::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    border-radius: 5px;
  }
  &.ticket-details {
    border: 0px;
    :hover {
      box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
    }
  }
`;

const TextArea = (props, ref) => {
  const { onChange = () => {}, value: propValue, timeout = 300, ...rest } = props;
  const [value, handleOnChange] = useDebounce(propValue, onChange, timeout);

  return <StyledTextArea {...rest} onChange={handleOnChange} ref={ref} value={value} />;
};

export default forwardRef(TextArea);

import styled from '@emotion/styled';
import { Modal as AntModal } from 'antd';
import React, { useRef } from 'react';

const StyledModal = styled.div`
  .ant-modal-footer button {
    padding: 0 30px;
  }
  .ant-modal-footer button + button {
    margin-left: 15px;
  }
  .ant-modal-footer {
    border-top: none;
    display: block;
    padding: 0 25px 25px 25px;
  }
  .ant-modal-content {
    border-radius: 0;
  }
  .ant-modal-title {
    font-family: AvenirLTStdHeavy;
    font-size: 20px;
    color: #050593;
  }
  .ant-modal-header {
    padding: 25px 25px 20px 25px;
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 0 15px;
  }
  .ant-modal-confirm-body-wrapper {
    border: 1px solid;
  }
`;

const Modal = ({ ...props }) => {
  const node = useRef();
  return (
    <StyledModal ref={node}>
      <AntModal getContainer={() => node.current} {...props} />
    </StyledModal>
  );
};
Modal.confirm = AntModal.confirm;

export default Modal;

import { DeleteTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Col, List, message, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionLink } from '../../../components';
import { ROLES } from '../../../constants';
import { getProfile } from '../../../utils/auth';
import { formatDateDisplay } from '../../../utils/formatters';
import { withRouter } from '../../../utils/wrappers';
import { surchargeService } from '../../surcharges/surcharges.service';
import { getClientDetail } from '../client-details.action';

const MappedSurcharges = ({ params, mappedSurcharges = [] }) => {
  const dispatch = useDispatch();

  const { clientID } = params;
  const { roles } = getProfile();
  const isAdmin = roles.includes(ROLES.FINANCE);
  const [surcharges, setSurcharges] = useState([]);
  const [viewAllSurcharges, setViewAllSurcharges] = useState(false);

  useEffect(() => {
    if (mappedSurcharges.length) {
      setSurcharges(mappedSurcharges.slice(0, 5));
    }
  }, [mappedSurcharges]);

  useEffect(() => {
    if (viewAllSurcharges) {
      setSurcharges(mappedSurcharges);
    } else {
      setSurcharges(mappedSurcharges.slice(0, 5));
    }
  }, [viewAllSurcharges]);

  function confirmDelete(id) {
    Modal.confirm({
      title: 'Delete',
      onOk: async () => {
        await surchargeService
          .deleteSurcharge(id)
          .then(() => {
            dispatch(getClientDetail(clientID));
            message.success('Surcharge mapping deleted successfully');
          })
          .catch(() => {
            message.error('An error occured while deleting surcharge mapping');
          });
      },
      confirmLoading: true,
      content: 'Are you sure you want to delete?',
      okText: 'Yes',
      cancelText: 'No'
    });
  }

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        <h5>
          Mapped Surcharge &nbsp;{' '}
          {isAdmin ? (
            <ActionLink to={`/surcharges/${clientID}`}>
              <PlusCircleTwoTone twoToneColor="#5050f9" style={{ cursor: 'pointer' }} />
            </ActionLink>
          ) : (
            <div />
          )}
        </h5>
        <div>
          <List
            header={
              <Row gutter={[24, 16]}>
                <Col span={12}>
                  <span className="header">Name</span>
                </Col>
                <Col span={6}>
                  <span className="header">Start Date</span>
                </Col>
                <Col span={6}>
                  <span className="header">End Date</span>
                </Col>
              </Row>
            }
            footer={
              (surcharges.length !== mappedSurcharges.length || mappedSurcharges.length > 5) && (
                <div style={{ textAlign: 'center' }}>
                  <>
                    {!viewAllSurcharges && (
                      <span className="view-link" onClick={() => setViewAllSurcharges(true)}>
                        View All
                      </span>
                    )}
                    {viewAllSurcharges && (
                      <span className="view-link" onClick={() => setViewAllSurcharges(false)}>
                        View Less
                      </span>
                    )}
                  </>
                </div>
              )
            }
            bordered
            size="small"
            dataSource={surcharges}
            renderItem={(item) => (
              <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                <Col span={12}>
                  <span className="value">{item.name}</span>
                </Col>
                <Col span={6}>
                  <span className="value">
                    {item.start_date
                      ? formatDateDisplay(item.start_date).format('DD MMM YYYY')
                      : '-'}
                  </span>
                </Col>
                <Col span={5}>
                  <span className="value">
                    {item.end_date ? formatDateDisplay(item.end_date).format('DD MMM YYYY') : '-'}
                  </span>
                </Col>
                <Col span={1}>
                  <DeleteTwoTone
                    twoToneColor="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => confirmDelete(item.id)}
                  />
                </Col>
              </Row>
            )}
          />
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(MappedSurcharges);

import styled from '@emotion/styled';
import { Spin } from 'antd';
import React from 'react';

const SpinnerStyled = styled(Spin)`
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
  margin-top: -..px; /* half of the elements height */
  margin-right: -..px; /* half of the elements width */
`;

const Spinner = () => <SpinnerStyled />;

export default Spinner;

const ROLES = {
  FINANCE: 'Finance/Admin',
  COMMERCIAL: 'Commercial/Admin',
  KYC: 'KYC/Admin',
  DEV: 'DEV/Admin'
};

const PAYMENT_MODE = {
  'pre-paid': 'Pre Paid',
  'post-paid': 'Post Paid'
};

const TICKET_TYPES = {
  TASK: process.env.REACT_APP_JIRA_TASK_ID
};

const PRIORITIES = {
  HIGHEST: '1',
  HIGH: '2',
  MEDIUM: '3',
  LOW: '4',
  LOWEST: '5'
};

const PRIORITY_COLOR = {
  HIGHEST: '#d04437',
  HIGH: '#f15C75',
  MEDIUM: '#f79232',
  LOW: '#707070',
  LOWEST: '#999999'
};

const JIRA_STATUSES = [
  {
    value: 'All',
    key: 0,
    label: 'All'
  },
  ...process.env.REACT_APP_JIRA_STATUS.split(',').map((status, index) => {
    return {
      value: `'${status}'`,
      key: ++index,
      label: status
    };
  })
];

const LABELS = [
  { value: 'Wrong_Zone', key: 1, label: 'Wrong Zone' },
  { value: 'Missing_Origin_Zone', key: 2, label: 'Missing Origin Zone' },
  { value: 'Missing_Destination_Zone', key: 3, label: 'Missing Destination Zone' },
  { value: 'Xero_Supp_Docs_Not_Uploaded', key: 4, label: 'Xero Supp Docs Not Uploaded' },
  { value: 'Billed_TN_Showing_For_Billing', key: 5, label: 'Billed TN Showing For Billing' },
  {
    value: 'Order_Computed_Invoice_Not_Attached',
    key: 6,
    label: 'Order Computed Invoice Not Attached'
  },
  { value: 'Wrong_Measurements', key: 7, label: 'Wrong Measurements' },
  { value: 'Missing_Measurement', key: 8, label: 'Missing Measurement' },
  { value: 'Prepaid_Amt_Mismatch', key: 9, label: 'Prepaid Amt Mismatch' },
  { value: 'Wrong_Rate_Card_Applied', key: 10, label: 'Wrong Rate Card Applied' },
  { value: 'Wrong_Billing_Cycle', key: 11, label: 'Wrong Billing Cycle' },
  { value: 'Draft_Inv_Pushed_To_Xero', key: 12, label: 'Draft Inv Pushed To Xero' },
  { value: 'Split_Invoice', key: 13, label: 'Split Invoice' },
  { value: 'Order_Not_Computed', key: 14, label: 'Order Not Computed' },
  { value: 'Unable_To_Export_Inv_To_Xero', key: 15, label: 'Unable To Export Inv To Xero' },
  {
    value: 'Multiple_Inv_For_Same_Billing_Cycle',
    key: 16,
    label: 'Multiple Inv For Same Billing Cycle'
  },
  {
    value: 'Duties_Taxes_Not_Computed_Correctly',
    key: 17,
    label: 'Duties Taxes Not Computed Correctly'
  },
  {
    value: 'Surcharges_Not_Computed_Correctly',
    key: 18,
    label: 'Surcharges Not Computed Correctly'
  },
  { value: 'Pickup_Fee_Not_Correct', key: 19, label: 'Pickup Fee Not Correct' },
  { value: 'Prepaid_Currency_Mismatch', key: 20, label: 'Prepaid Currency Mismatch' },
  {
    value: 'Prepaid_Order_Computed_As_Postpaid',
    key: 21,
    label: 'Prepaid Order Computed As Postpaid'
  },
  { value: 'Other_Issues', key: 22, label: 'Other Issues' }
];

const TIER_MAPPINGS = [
  { value: 'Tier 1', key: 0, label: 'Tier 1' },
  { value: 'Tier 2', key: 1, label: 'Tier 2' },
  { value: 'Tier 3', key: 2, label: 'Tier 3' }
];

const BILLING_ENTITIES = [
  { value: 'Taiwan', key: 0, label: 'Taiwan' },
  { value: 'China', key: 1, label: 'China' },
  { value: 'Hong Kong', key: 2, label: 'Hong Kong' },
  { value: 'Singapore', key: 3, label: 'Singapore' },
  { value: 'Malaysia', key: 4, label: 'Malaysia' },
  { value: 'Indonesia', key: 5, label: 'Indonesia' },
  { value: 'Thailand', key: 6, label: 'Thailand' }
];

const BILLING_CYCLES = [
  { value: 1, key: 1, label: '1' },
  { value: 2, key: 2, label: '2' },
  { value: 3, key: 3, label: '3' },
  { value: 4, key: 4, label: '4' },
  { value: 5, key: 5, label: '5' },
  { value: 6, key: 6, label: '6' },
  { value: 7, key: 7, label: '7' },
  { value: 8, key: 8, label: '8' },
  { value: 9, key: 9, label: '9' },
  { value: 10, key: 10, label: '10' },
  { value: 11, key: 11, label: '11' },
  { value: 12, key: 12, label: '12' },
  { value: 13, key: 13, label: '13' },
  { value: 14, key: 14, label: '14' },
  { value: 15, key: 15, label: '15' },
  { value: 16, key: 16, label: '16' },
  { value: 17, key: 17, label: '17' },
  { value: 18, key: 18, label: '18' },
  { value: 19, key: 19, label: '19' },
  { value: 20, key: 20, label: '20' },
  { value: 21, key: 21, label: '21' },
  { value: 22, key: 22, label: '22' },
  { value: 23, key: 23, label: '23' },
  { value: 24, key: 24, label: '24' },
  { value: 25, key: 25, label: '25' },
  { value: 26, key: 26, label: '26' },
  { value: 27, key: 27, label: '27' },
  { value: 28, key: 28, label: '28' },
  { value: 29, key: 29, label: '29' },
  { value: 30, key: 30, label: '30' },
  { value: 31, key: 31, label: '31' }
];

const BILLING_DAYS = [
  { value: '0', key: 0, label: '0' },
  { value: '1', key: 1, label: '1' },
  { value: '2', key: 2, label: '2' }
];

const DIM_WEIGHT_PREFERENCES = [
  { value: '1', key: 0, label: 'Janio Dimweight' },
  { value: '2', key: 1, label: 'Partner Dimweight' },
  { value: '3', key: 2, label: 'Client Dimweight' }
];

const WEIGHT_POLICIES = [
  { value: 'Actual weight', key: 0, label: 'Actual weight' },
  { value: 'Volumetric weight', key: 1, label: 'Volumetric weight' },
  {
    value: 'Max of actual and volumetric weight',
    key: 2,
    label: 'Max of actual and volumetric weight'
  }
];

const VOLUMETRIC_WEIGHT_FACTORS = [
  { value: 5000, key: 0, label: '5000' },
  { value: 6000, key: 1, label: '6000' }
];

const FIRST_TOUCH_STATUSES = [{ value: 'SUCCESS', key: 0, label: 'On Delivery' }];

const INVOICE_REMARK = {
  NON_COMPUTED_ORDERS: 'Non-computed orders were found for this invoice',
  BILLED_ORDERS: 'Already billed orders were found in this invoice',
  FAILED: 'Creating the invoice to xero failed. Please reach out to the tech team'
};

const PRICING_GROUP_TYPES = {
  PUBLISHED: 'Published',
  CUSTOM: 'Custom'
};

export {
  BILLING_CYCLES,
  BILLING_DAYS,
  BILLING_ENTITIES,
  DIM_WEIGHT_PREFERENCES,
  FIRST_TOUCH_STATUSES,
  INVOICE_REMARK,
  JIRA_STATUSES,
  LABELS,
  PAYMENT_MODE,
  PRICING_GROUP_TYPES,
  PRIORITIES,
  PRIORITY_COLOR,
  ROLES,
  TICKET_TYPES,
  TIER_MAPPINGS,
  VOLUMETRIC_WEIGHT_FACTORS,
  WEIGHT_POLICIES
};

import { SyncOutlined, UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Avatar, Col, Comment, Divider, List, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import rehypeRaw from 'rehype-raw';
import { Button, TextArea } from '../../../components';
import { formatDateDisplay } from '../../../utils/formatters';
import { getComments } from '../ticket-details.action';
import service from '../ticket-details.service';

const StyledDiv = styled.div`
  font-family: AvenirLTStdHeavy;
  .ant-comment-content-author-name {
    font-size: 16px;
    color: #707070;
  }
  .ant-comment-content-author-time {
    font-size: 14px;
  }
  .ant-comment-content-detail {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
  }
  .user-mention {
    background-color: #f2f2f9;
    color: #000000;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    cursor: default;
  }
  tt,
  pre {
    font-family: monospace;
    padding: 2px;
    border-radius: 5px;
    background-color: #f2f2f9;
  }
  pre > p {
    font-family: monospace;
    padding: 2px;
    border-radius: 5px;
    background-color: #f2f2f9;
  }
  th {
    background-color: #f2f2f9;
  }
  th,
  td {
    padding: 10px;
    border: 1px solid #707070;
  }
`;

const TicketComments = ({ ticketId }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [spin, setSpin] = useState(false);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    data,
    total,
    loading: commentsLoading
  } = useSelector((state) => state.ticketDetails.comments);

  const handleContent = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleSend = () => {
    const key = `delete-${new Date().getTime()}`;
    message.loading({ content: 'Sending...', key });
    setLoading(true);

    service
      .postComment(ticketId, comment)
      .then(() => {
        message.success({ content: 'Sent!', key });
        setLoading(false);
        setComment('');

        dispatch(getComments({ id: ticketId, page: 1, maxResults: 50 }));
      })
      .catch(() => {
        setLoading(false);
        message.error({ content: 'Failed to send comment', key });
      });
  };

  const handleRefresh = () => {
    setSpin(true);
    dispatch(getComments({ id: ticketId, page: 1, maxResults: 50 }));
  };

  useEffect(() => {
    dispatch(getComments({ id: ticketId, page: 1, maxResults: 50 }));
  }, []);

  useEffect(() => {
    setComments(data);
    setCount(total);
  }, [data, total]);

  useEffect(() => {
    if (!commentsLoading) {
      setSpin(false);
    }
  }, [commentsLoading]);

  return (
    <StyledDiv>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h5>Comments ({count})</h5>
        </Col>
        <Col>
          <SyncOutlined spin={spin} style={{ cursor: 'pointer' }} onClick={handleRefresh} />
        </Col>
      </Row>

      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={(item) => (
          <li>
            <Comment
              avatar={
                <Avatar
                  style={
                    item.finance ? { backgroundColor: '#9b9bd4' } : { backgroundColor: '#f56a00' }
                  }
                  icon={<UserOutlined />}
                />
              }
              author={item.author}
              content={<ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.body}</ReactMarkdown>}
              datetime={formatDateDisplay(item.created).fromNow()}
            />
          </li>
        )}
      />
      <Divider />

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <span className="value">
            <TextArea
              name="content"
              value={comment}
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={handleContent}
            ></TextArea>
          </span>
        </Col>
      </Row>
      <Row type="flex" justify="end" style={{ marginTop: '10px' }}>
        <Col>
          <Button disabled={!comment.trim()} loading={loading} onClick={handleSend}>
            Send
          </Button>
        </Col>
      </Row>
    </StyledDiv>
  );
};

export default TicketComments;

import { createReducer } from '@reduxjs/toolkit';
import {
  fetchBillingLoading,
  fetchBillingRecords,
  fetchInvoiceDetail,
  resetDetails
} from './invoice-details.action';

const initialState = {
  invoice: {
    data: [],
    total: null,
    loading: false
  },
  invoice_detail: {}
};

export default createReducer(initialState, {
  [fetchBillingRecords](state, action) {
    const { invoice, total } = action.payload;
    state.invoice = {
      data: invoice,
      total,
      loading: false
    };
  },
  [fetchInvoiceDetail](state, action) {
    state.invoice_detail = action.payload;
  },
  [resetDetails](state) {
    state.invoice_detail = {};
    state.invoice = { data: [], total: null, loading: false };
  },
  [fetchBillingLoading](state) {
    state.invoice.loading = true;
  }
});

import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  margin: 10px;
  padding-bottom: 30px;
  & .row {
    margin: 0px -10px 0px -10px;
    & > .form-group {
      padding: 0px 10px;
      & > label {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 900;
        color: #707070;
      }
      & > div.value {
        color: #000000;
        font-size: 16px;
        word-break: break-word;
      }
    }
  }
  h5 {
    font-size: 20px;
    color: #000000;
  }
`;
function TabContent(props) {
  const { activeTab, tabs, ...rest } = props;
  const Component = tabs[activeTab].component;
  return (
    <Container>
      <Component {...rest} />
    </Container>
  );
}

export default TabContent;

import { getProfile } from '../../utils/auth';
import { formatLabels, queryString, removeEmpty } from '../../utils/formatters';
import { jira2markdown } from '../../utils/jira2markdown';
import request from '../../utils/request';
import { getAuthor, getReporter, removeAuthor, removeReporter } from '../../utils/serializers';

const getTicketDetails = async (id) => {
  const url = `/pricing/jira/issue/${id}`;
  const response = await request().get(url);
  const ticket = response.data.data;
  const reporter = getReporter(ticket.fields.description);
  const description = removeReporter(ticket.fields.description);
  const review =
    ticket.fields.status.id === process.env.REACT_APP_JIRA_REVIEW_WITH_FINANCE_USER_ID || false;
  const todo = ticket.fields.status.id === process.env.REACT_APP_JIRA_TODO_ID || false;

  return {
    id: ticket.id,
    key: ticket.key,
    summary: ticket.fields.summary,
    description: description.trim(),
    status: ticket.fields.status.name,
    labels: formatLabels(ticket.fields.labels.join(', ')) || '-',
    created: ticket.fields.created,
    updated: ticket.fields.updated,
    priority: ticket.fields.priority.name,
    assignee: ticket.fields.assignee?.displayName || '-',
    reporter: reporter || '-',
    comments: ticket.fields.comment.comments,
    attachments: ticket.fields.attachment,
    review,
    todo
  };
};

const editTicketDetails = async (id, name, value) => {
  const url = `/pricing/jira/issue/${id}`;
  const body = {
    update: {
      [name]: [{ set: value }]
    },
    fields: {}
  };

  await request().put(url, body);
  return;
};

const addAttachments = async (id, files) => {
  const url = `/pricing/jira/attachment/issue/${id}/attachments`;

  const formData = new FormData();
  for (const file of files) {
    formData.append('file', file);
  }

  const response = await request().post(url, formData);
  return response.data.data;
};

const deleteAttachment = async (id) => {
  const url = `/pricing/jira/delete/attachment/${id}`;

  await request().post(url);
  return id;
};

const getComments = async ({ id, page = 1, maxResults = 20 }) => {
  const startAt = (page - 1) * maxResults;
  const queryParams = queryString(removeEmpty({ startAt, maxResults }));
  const url = `/pricing/jira/issue/${id}/comment?expand=renderedBody&${queryParams}`;
  const response = await request().get(url);
  const { comments, total } = response.data.data;

  const results = comments?.map((comment) => {
    const author = getAuthor(comment.renderedBody);
    const body = removeAuthor(comment.renderedBody);
    const markdown = jira2markdown(comment.body);
    const finance = comment.author.emailAddress === 'finance.ticketing@janio.asia' || false;

    const htmlElementBody = body
      // Change attachments link
      .replace(
        /.+\/secure\/attachment\/([0-9]+)\/.+/g,
        `<p><span><a href="https://janio.atlassian.net/rest/api/2/attachment/content/$1">${markdown}</a></span></p>`
      )
      // Change Account ID link
      .replace(/.+ class="user-hover"/g, `<p><a href="" className="user-mention"`);

    return {
      id: comment.id,
      body: htmlElementBody,
      created: comment.created,
      updated: comment.updated,
      author: author || comment.author.displayName,
      finance
    };
  });

  return { results, total };
};

const postComment = async (id, message) => {
  const url = `/pricing/jira/issue/${id}/comment`;
  const { fullname } = getProfile();
  const data = {
    body: message.concat(`\n\nAuthored by ${fullname}`)
  };

  await request().post(url, data);
  return;
};

const transitionTicket = async (ticketId, transitionId, comment = '') => {
  const url = `/pricing/jira/issue/${ticketId}/transitions`;
  const { fullname } = getProfile();
  const data = {
    transition: {
      id: transitionId
    }
  };

  await request().post(url, data);

  let status = 'Done';
  if (
    transitionId === process.env.REACT_APP_JIRA_ISSUE_REPRODUCIBLE_ID ||
    transitionId === process.env.REACT_APP_JIRA_INITIAL_INVESTIGATION_ID
  ) {
    status = 'Initial Investigation';
  }
  if (transitionId === process.env.REACT_APP_JIRA_INVALID_ISSUE_ID) {
    status = 'Review With Finance User';
  }

  let message = `${fullname} moved this ticket to ${status}`;
  if (comment) {
    message += `\n\nReason: \n${comment.trim()}`;
  }

  await postComment(ticketId, message);

  return;
};

export default {
  getTicketDetails,
  editTicketDetails,
  addAttachments,
  deleteAttachment,
  getComments,
  postComment,
  transitionTicket
};

import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import service from './billing-records.service';

export const fetchInvoice = createAction('quotes/FETCH_DATA');
export const fetchAllBillingRecords = createAction('quotes/FETCH_BILLING');
export const fetchInvoiceLoading = createAction('quotes/FETCH_DATA_LOADING');

export const fetchChoices = createAction('quotesChoices/FETCH_DATA');
export const fetchInvoiceChoices = createAction('quotesInvoiceChoices/FETCH_DATA');
export const fetchChoicesLoading = createAction('quotesChoices/FETCH_DATA_LOADING');
export const fetchInvoiceChoicesLoading = createAction('quotesInvoiceChoices/FETCH_DATA_LOADING');

export const setPageFilter = createAction('quotesFilter/SET_PAGE');
export const setPageSizeFilter = createAction('quotesFilter/SET_PAGE_SIZE');
export const setInvoiceFilters = createAction('quotesFilter/SET_FILTERS');
export const resetFilters = createAction('quotesFilter/RESET_FILTERS');
export const setStatus = createAction('quotesFilter/SET_STATUS');
export const setInvoiceStatus = createAction('quotesFilter/SET_INVOICE_STATUS');
export const revertInvoiceStatus = createAction('quotesFilter/REVERT_INVOICE_STATUS');
export const changeDimWeight = createAction('quotesFilter/CHANGE_DIM_WEIGHT');

export const getChoices = () => async (dispatch) => {
  dispatch(fetchChoicesLoading());
  service.getBillingFilters().then((data) => {
    dispatch(fetchChoices(data));
  });
};

// get invoice filters from local storage
function getLocalStorageWithKey(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = moment().valueOf();
  // compare the expiry time of the item with the current time
  if (now > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const getInvoiceChoices = () => async (dispatch) => {
  // check filter exists in local storage
  let filters = getLocalStorageWithKey('invoice_filters');
  if (filters) {
    dispatch(fetchInvoiceChoices(filters));
  } else {
    dispatch(fetchInvoiceChoicesLoading(true));
    service
      .getInvoiceFilters()
      .then((data) => {
        data.billing_cycles = data.billing_cycles
          ?.filter((item) => !!item?.billing_cycle)
          ?.map((item) => moment(item?.billing_cycle).format('YYYY-MM-DD'));
        data.billing_cycles = [...new Set(data.billing_cycles)];
        dispatch(fetchInvoiceChoices(data));
        // store invoice filter in local storage for a day
        let item = {
          value: data,
          expiry: moment().endOf('day').valueOf()
        };
        localStorage.setItem('invoice_filters', JSON.stringify(item));
      })
      .catch(() => {
        dispatch(fetchInvoiceChoicesLoading(false));
      });
  }
};

export const getInvoices =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchInvoiceLoading(true));
    service
      .getAllInvoices(params)
      .then((data) => {
        dispatch(fetchInvoice(data));
      })
      .catch(() => {
        dispatch(fetchInvoiceLoading(false));
      });
  };

export const fetchBilling =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchInvoiceLoading(true));
    service
      .getAllBillingRecords(params)
      .then((data) => {
        dispatch(fetchAllBillingRecords(data));
      })
      .catch(() => {
        dispatch(fetchInvoiceLoading(false));
      });
  };

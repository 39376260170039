import React from 'react';
import TicketInfo from './TicketInfo';

const TicketDetails = (props) => {
  return (
    <div>
      <TicketInfo {...props} />
    </div>
  );
};

export default TicketDetails;

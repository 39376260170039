import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPricingGroupDetails,
  fetchPricingGroupDetailsLoading,
  fetchPricingGroupRules,
  fetchPricingGroupRulesLoading
} from './pricing-group-details.action';

const initialState = {
  data: null,
  loading: false,
  rules: {
    data: [],
    loading: false
  }
};

export default createReducer(initialState, {
  [fetchPricingGroupDetailsLoading](state, action) {
    state.data = initialState.data;
    state.loading = action.payload;
  },
  [fetchPricingGroupDetails](state, action) {
    state.data = action.payload;
    state.loading = false;
  },
  [fetchPricingGroupRulesLoading](state, action) {
    state.rules.loading = action.payload;
  },
  [fetchPricingGroupRules](state, action) {
    const { rules, total } = action.payload;
    state.rules.data = rules;
    state.rules.total = total;
    state.rules.loading = false;
  }
});

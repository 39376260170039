/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { Col, Row, Skeleton as AntSkeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import {
  ActionLink,
  Button,
  Card,
  CreateTicketModal,
  SelectDropdown,
  Table
} from '../../components';
import { formatDateDisplay } from '../../utils/formatters';
import FilterDropdown from './shared/FilterDropdown';
import { getAllTickets } from './tickets.action';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const columns = [
  {
    title: 'Ticket ID',
    dataIndex: 'key',
    width: 100,
    fixed: 'left',
    render: (text) => <ActionLink to={`/tickets/${text}`}>{text}</ActionLink>,
    key: 'key'
  },
  {
    title: 'Summary',
    dataIndex: 'summary',
    key: 'summary'
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    width: 100,
    key: 'priority'
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
    width: 120,
    key: 'comments'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 170,
    key: 'status'
  },
  {
    title: 'Labels',
    dataIndex: 'labels',
    width: 180,
    key: 'labels'
  },
  {
    title: 'Assignee',
    dataIndex: 'assignee',
    width: 150,
    key: 'assignee'
  },
  {
    title: 'Reporter',
    dataIndex: 'reporter',
    width: 150,
    key: 'reporter'
  },
  {
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    width: 130,
    render: (text) => formatDateDisplay(text).fromNow(),
    key: 'updatedAt'
  },
  {
    title: 'Created On',
    dataIndex: 'createdAt',
    width: 120,
    render: (text) => formatDateDisplay(text).utc().format('DD MMM YYYY'),
    key: 'createdAt'
  }
];

const per_page = 20;

const Tickets = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(false);
  const { data, total: totalCount, loading } = useSelector((state) => state?.tickets);

  const [tableColumns, setTableColumns] = useState([
    'summary',
    'priority',
    'comments',
    'status',
    'labels',
    'assignee',
    'reporter',
    'updatedAt',
    'createdAt'
  ]);

  useEffect(() => {
    dispatch(getAllTickets({ page: 1, per_page }));
  }, []);

  const handleFilter = (filters, page = 1) => {
    setPage(page);
    setFilters(filters);
    const recordFilters = {
      page,
      per_page,
      ...filters
    };
    dispatch(getAllTickets(recordFilters));
  };

  const handleCreateTicket = () => {
    setVisible(true);
  };

  return (
    <div>
      <CreateTicketModal
        key={new Date().getTime()}
        data={[]}
        visible={visible}
        setVisible={setVisible}
      />
      <StyledCard>
        <Row sx={{ mb: '25px' }} gutter={16}>
          <Col>
            <FilterDropdown onFilter={(filters) => handleFilter(filters)} loading={loading} />
          </Col>
          <Col>
            <SelectDropdown
              placeholder="Hide/Show Columns"
              multiple
              items={columns
                .map((column) => ({
                  label: column.title,
                  value: column.key
                }))
                .filter((column) => !['key'].includes(column.value))}
              value={tableColumns}
              onChange={(value) => {
                setTableColumns(['key', ...value]);
              }}
            />
          </Col>
          <Col sx={{ ml: 'auto' }}>
            <Button danger onClick={() => handleCreateTicket()}>
              Create Ticket
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            loading={loading}
            rowKey={(row) => `${row.key}`}
            renderEmptyTable
            dataSource={data}
            pageSize={per_page}
            currentPage={page}
            totalCount={totalCount}
            scroll={{ x: 1700 }}
            columns={columns.filter((column) => tableColumns.includes(column.key))}
            onChange={(page) => handleFilter(filters, page)}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No Tickets found.</span>&nbsp;
                  </div>
                )}
                {loading && (
                  <AntSkeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
          />
        </Row>
      </StyledCard>
    </div>
  );
};

export default Tickets;

import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Input, Modal, Popover } from 'antd';
import React from 'react';

const StyledDiv = styled.div`
  font-family: AvenirLTStdBook;
  font-size: 16px;

  .ant-input-disabled {
    font-size: 16px;
    padding: 6px 11px 3px 11px;
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    margin-bottom: 15px;
  }
`;

const DimWeightModal = (props) => {
  const record = { ...props.selectedRecord.record };

  const handleCancel = () => {
    props.setVisible(false);
  };

  const getDimWeightPreferenceText = (val) => {
    switch (val) {
      case '1':
        return 'Janio Dimweight';
      case '2':
        return 'Partner Dimweight';
      case '3':
        return 'Client Dimweight';
      default:
        return 'Default';
    }
  };

  return (
    <Modal visible={props.visible} onCancel={handleCancel} footer={null}>
      <StyledDiv>
        <p>
          <b>{props.selectedRecord.trackingId} </b>
          <Popover
            placement="right"
            trigger="hover"
            title="Dimensional Weight Preference"
            content={getDimWeightPreferenceText(record.dim_weight_preference)}
          >
            <ExclamationCircleOutlined />
          </Popover>
        </p>

        {record?.billing_type === 1 && (
          <div>
            <label>Length (cm):</label>
            <Input disabled value={record?.length} />
            <label>Width (cm):</label>
            <Input disabled value={record?.width} />
            <label>Height (cm):</label>
            <Input disabled value={record?.height} />
            <label>Actual Weight (kg):</label>
            <Input disabled value={record?.weight} />
            <label>Chargable Weight (kg):</label>
            <Input disabled value={record?.weight_upper_bound} />
            <label>Price:</label>
            <Input disabled value={record?.total_amount} />
            <label>Rate Card:</label>
            <Input disabled value={record?.rate_card} />
          </div>
        )}

        {record?.billing_type === 2 && (
          <div>
            <label>Origin Country:</label>
            <Input disabled value={record?.origin_country} />
            <label>Destination Country:</label>
            <Input disabled value={record?.destination_country} />
            <label>Currency:</label>
            <Input disabled value={record?.currency} />
            <label>Price:</label>
            <Input disabled value={record?.total_amount} />
          </div>
        )}
      </StyledDiv>
    </Modal>
  );
};

export default DimWeightModal;

import { invoke } from '../../utils/aws-sdk';
import { formatToISOString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const getInvoices = async ({
  page = 1,
  size = 20,
  status = '',
  trackingNumber = '',
  invoiceNumber = '',
  billingStatus = '',
  paymentMode = '',
  clientID = '',
  action = '',
  origin = '',
  destination = '',
  createdDate = []
}) => {
  let defaultDateRange = [];
  let createdDateRange = createdDate.length ? createdDate : defaultDateRange;
  const params = removeEmpty({
    page,
    records: size,
    status: billingStatus,
    invoiceId: invoiceNumber,
    clientName: trackingNumber,
    clientID,
    action,
    paymentMode,
    billing_cycle: status,
    origin,
    destination,
    created_date: createdDateRange?.map(formatToISOString) || []
  });

  if (params.created_date && params.created_date.length) {
    params.created_date = params.created_date.join(',');
  } else {
    delete params.created_date;
  }

  const response = await invoke('invoices', 'getInvoiceRecords', {
    queryStringParameters: params
  });

  const { invoices, total } = response.data;

  const resultSet = invoices.map((a) => {
    return {
      invoiceNumber: `INV-${a.invoice_id}`,
      invoiceDisplayId: a.invoice_display_id,
      clientName: a.company_name,
      clientID: a.order_client_id,
      createdOn: a.created_on,
      serviceType: a.service_type,
      numberOfShipments: a.shipment_count,
      amount: a.total_amount,
      currency: a.currency,
      billingCycle: a.billing_cycle,
      status: a.status,
      payment_mode: a.payment_mode,
      remark: a.remark
    };
  });

  return {
    invoices: resultSet.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

const getInvoiceFilters = async () => {
  const url = '/invoices/filters';
  const response = await request().get(url);
  return response.data.data;
};

export default {
  getInvoices,
  getInvoiceFilters
};

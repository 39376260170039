import { createReducer } from '@reduxjs/toolkit';
import { fetchClients, fetchClientsLoading, resetFilters, setFilters } from './clients.action';

const initialState = {
  data: null,
  total: 0,
  loading: false,
  filters: {
    page: 1,
    id: null,
    name: null,
    email: null
  }
};

export default createReducer(initialState, {
  [fetchClientsLoading](state, action) {
    state.loading = action.payload;
  },
  [fetchClients](state, action) {
    const { clients, total } = action.payload;
    state.data = clients;
    state.total = total;
    state.loading = false;
  },
  [setFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
    state.data = initialState.data;
  }
});

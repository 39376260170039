import { MenuOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Dropdown as AntDropdown } from 'antd';
import React from 'react';
import Account from '../../assets/images/account.png';
import ImgLogoReverse from '../../assets/images/logo-reverse.png';
import Breadcrumb from '../Breadcrumb';
import ProfileDropdown from './ProfileDropdown';

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: transparent linear-gradient(270deg, #1fceca 0%, #050593 100%) 0% 0% no-repeat
    padding-box;
  display: flex;
  align-items: center;
  padding: 5px 40px;
  box-shadow: 0px 3px 6px #00000029;

  img {
    width: 100px;
  }

  ul {
    position: relative;
    white-space: nowrap;
    list-style: none;
    padding: 0;
    margin-left: 80px;
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 8px 20px;
      transition: 0.5s;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #fff;
      margin-left: 5px;
      &:hover,
      &.menu-enable {
        background: #ffffff40 0% 0% no-repeat padding-box;
        cursor: pointer;
      }
      &:first-of-type:before {
        content: '';
        position: absolute;
        border: 0.5px solid #fff;
        background-color: #ffffff;
        top: 7px;
        bottom: 7px;
        left: -30px;
      }
      .bell {
        width: 21px;
      }
      .account {
        width: 25px;
      }
    }
    &.right-part {
      position: absolute;
      right: 40px;
      li {
        padding: 0;
        border-radius: 100%;
        position: relative;
        .notice-num {
          top: -10px;
          right: -5px;
          position: absolute;
          span {
            background-color: #fa3e3e;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: AvenirLTStdHeavy;
            font-size: 10px;
            color: #fff;
          }
        }
        &:first-of-type:before {
          top: -3px;
          bottom: -3px;
        }
        &:last-of-type {
          margin-left: 30px;
        }
      }
    }
  }
`;

const Header = ({ onToggle }) => {
  return (
    <div>
      <HeaderContainer>
        <MenuOutlined
          className="trigger"
          onClick={onToggle}
          style={{ fontSize: '18px', color: '#fff', marginRight: '40px' }}
        />
        <img alt="logo" src={ImgLogoReverse} />
        <ul className="right-part">
          <AntDropdown
            overlay={() => <ProfileDropdown key={new Date().getTime()} />}
            trigger={['click']}
            placement="bottomRight"
          >
            <li>
              <i>
                <img alt="logo" className="account" src={Account} />
              </i>
            </li>
          </AntDropdown>
        </ul>
      </HeaderContainer>
      <Breadcrumb />
    </div>
  );
};

export default Header;

import styled from '@emotion/styled';
import { Empty, Skeleton as AntSkeleton } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../components';
import { withRouter } from '../../utils/wrappers';
import { getPricingGroupDetails } from './pricing-group-details.action';
import { PricingGroupInfo } from './shared';

const StyledDetails = styled.div`
  max-width: 1200px;
  width: auto;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  padding: 30px 40px;
`;

const StyledInfo = styled.div`
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
  }
  em.label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  span.value {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
  .view-link {
    color: rgb(24, 144, 255);
    cursor: pointer;
  }
  .header {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
  }
`;

const PricingGroupDetails = (props) => {
  const dispatch = useDispatch();

  const { name } = props.params;
  const { data, loading } = useSelector((state) => state.pricingGroupDetails);

  useEffect(() => {
    dispatch(getPricingGroupDetails(name));
  }, [dispatch, name]);

  return (
    <StyledDetails>
      <StyledCard>
        {loading ? (
          <AntSkeleton
            active
            title={{ width: '100%' }}
            paragraph={{
              rows: 5,
              width: ['100%', '100%', '100%', '100%', '100%']
            }}
          />
        ) : (
          <div>
            {data ? (
              <StyledInfo>
                <PricingGroupInfo pricingGroup={data} />
              </StyledInfo>
            ) : (
              <Empty description="No pricing group found" />
            )}
          </div>
        )}
      </StyledCard>
    </StyledDetails>
  );
};

export default withRouter(PricingGroupDetails);

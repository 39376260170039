/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import { ActionLink, Card, SelectDropdown, Table } from '../../components';
import { getClients } from './clients.action';
import FilterDropdown from './shared/FilterDropdown';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const columns = [
  {
    title: 'Client ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 100
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    render: (text, item) => <ActionLink to={`/clients/${item.id}`}>{text}</ActionLink>
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text) => text || '-'
  },
  {
    title: 'Billing Country',
    dataIndex: 'billing_country',
    key: 'billing_country',
    render: (text) => text || '-'
  },
  {
    title: 'Billing Entity',
    dataIndex: 'billing_entity',
    key: 'billing_entity',
    render: (text) => text || '-'
  },
  {
    title: 'Consolidate Flag',
    dataIndex: 'consolidate_flag',
    key: 'consolidate_flag',
    render: (text) => (text ? 'Yes' : 'No')
  },
  {
    title: 'Weight Policy',
    dataIndex: 'weight_policy',
    key: 'weight_policy',
    render: (text) => text || '-'
  }
];

const size = 50;

const Clients = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const { data, total, loading } = useSelector((state) => state?.clients);

  const [tableColumns, setTableColumns] = useState([
    'email',
    'billing_country',
    'billing_entity',
    'consolidate_flag',
    'weight_policy'
  ]);

  useEffect(() => {
    dispatch(getClients({ page: 1, size }));
  }, []);

  const handleFilter = (filters, page = 1) => {
    setPage(page);
    setFilters(filters);
    const recordFilters = {
      page,
      size,
      ...filters
    };
    dispatch(getClients(recordFilters));
  };

  return (
    <StyledCard>
      <Row sx={{ mb: '25px' }} gutter={16}>
        <Col>
          <FilterDropdown onFilter={(filters) => handleFilter(filters)} loading={loading} />
        </Col>
        <Col>
          <SelectDropdown
            placeholder="Hide/Show Columns"
            multiple
            items={columns
              .map((column) => ({
                label: column.title,
                value: column.key
              }))
              .filter((column) => !['id', 'name'].includes(column.value))}
            value={tableColumns}
            onChange={(value) => {
              setTableColumns(['id', 'name', ...value]);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Table
          loading={loading}
          rowKey={(row) => `${row.id}`}
          renderEmptyTable
          dataSource={data}
          pageSize={size}
          currentPage={page}
          totalCount={total}
          scroll={{ x: 1700 }}
          columns={columns.filter((column) => tableColumns.includes(column.key))}
          onChange={(page) => handleFilter(filters, page)}
          empty={
            <React.Fragment>
              {!loading && (
                <div>
                  <span>No client found.</span>&nbsp;
                </div>
              )}
              {loading && (
                <Skeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 5,
                    width: ['100%', '100%', '100%', '100%', '100%']
                  }}
                />
              )}
            </React.Fragment>
          }
        />
      </Row>
    </StyledCard>
  );
};

export default Clients;

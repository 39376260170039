import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  changeDimWeight,
  fetchAllBillingRecords,
  fetchChoices,
  fetchChoicesLoading,
  fetchInvoice,
  fetchInvoiceChoices,
  fetchInvoiceChoicesLoading,
  fetchInvoiceLoading,
  resetFilters,
  revertInvoiceStatus,
  setInvoiceFilters,
  setInvoiceStatus,
  setPageFilter,
  setPageSizeFilter,
  setStatus
} from './billing-records.action';

const initialState = {
  quotes: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  billing_records: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  filters: {
    status: 'All',
    page: 1,
    records: '50',
    trackingNumber: null,
    merchantName: null,
    billingStatus: 1,
    shippingType: null,
    action: 'All',
    fromDate: null,
    toDate: null,
    createdDate: [
      moment().subtract(1, 'month').startOf('month').toISOString(),
      moment().endOf('day').toISOString()
    ],
    origin: 'All',
    destination: 'All',
    invoiceNumber: null,
    weightLimitStart: null,
    weightLimitEnd: null,
    originZone: null,
    destinationZone: null
  },
  choices: {
    loading: false,
    merchants: [],
    statuses: [],
    actions: [],
    originCountries: [],
    destinationCountries: [],
    originZones: [],
    destinationZones: []
  },
  invoice_choices: {
    loading: false,
    billingCycles: []
  }
};

const billingRecords = createReducer(initialState, {
  [fetchInvoice](state, action) {
    const { quotes, total } = action.payload;
    state.quotes = {
      data: quotes,
      total,
      loading: false
    };
  },
  [fetchAllBillingRecords](state, action) {
    const { billing_records, total } = action.payload;
    state.billing_records = {
      data: billing_records,
      total,
      loading: false
    };
  },
  [fetchInvoiceLoading](state, action) {
    state.quotes.loading = action.payload;
    state.billing_records.loading = action.payload;
  },
  [fetchInvoiceChoicesLoading](state, action) {
    state.invoice_choices.loading = action.payload;
  },
  [fetchInvoiceChoices](state, action) {
    const { billing_cycles } = action.payload;
    state.invoice_choices = {
      loading: false,
      billingCycles: [
        {
          value: 'All',
          label: 'All'
        },
        ...billing_cycles.map((billing_cycle) => ({
          value: billing_cycle,
          label: billing_cycle
        }))
      ]
    };
  },
  [fetchChoices](state, action) {
    const {
      billing_cycles,
      destination_countries,
      origin_countries,
      destination_zones,
      origin_zones
    } = action.payload;
    state.choices = {
      loading: false,
      statuses: [
        {
          value: 'All',
          label: 'All'
        },
        ...billing_cycles.map((item) => ({
          value: item,
          label: moment(item).format('YYYY-MM-DD')
        }))
      ],
      destinationCountries: destination_countries,
      originCountries: origin_countries,
      destinationZones: destination_zones,
      originZones: origin_zones
    };
  },
  [fetchChoicesLoading](state) {
    state.choices.loading = true;
  },
  [setPageFilter](state, action) {
    state.filters.page = action.payload;
  },
  [setStatus](state, action) {
    const payload = state.billing_records.data.map((item) => {
      const temp = { ...item };
      if (temp.record.invoice_record_id === action.payload.invoice_record_id) {
        temp.status = action.payload.transitionState;
        if (action.payload.new_invoice_record_id) {
          temp.record.invoice_record_id = action.payload.new_invoice_record_id;
          temp.record.invoice_ref_display_id = action.payload.new_invoice_ref_display_id;
        }
      }
      return temp;
    });
    state.billing_records.data = payload;
  },
  [setInvoiceStatus](state, action) {
    const payload = state.quotes.data.map((item) => {
      const temp = { ...item };
      if (action.payload.invoice_ids.includes(temp.invoiceDisplayId)) {
        temp.status = 2;
      }
      return temp;
    });
    state.quotes.data = payload;
  },
  [revertInvoiceStatus](state, action) {
    const payload = state.quotes.data.map((item) => {
      const temp = { ...item };
      if (action.payload.invoice_ids.includes(temp.invoiceDisplayId)) {
        temp.status = 1;
      }
      return temp;
    });
    state.quotes.data = payload;
  },
  [changeDimWeight](state, action) {
    const payload = state.billing_records.data.map((item) => {
      const temp = { ...item };
      if (temp.record.invoice_record_id === action.payload.invoice_record_id) {
        temp.totalAmount = action.payload.total_amount;
        temp.record.width = action.payload.width;
        temp.record.weight = action.payload.weight;
        temp.record.length = action.payload.length;
        temp.record.height = action.payload.height;
        temp.record.weight_upper_bound = action.payload.chargableWeight;
        temp.record.total_amount = action.payload.total_amount;
      }
      return temp;
    });
    state.billing_records.data = payload;
  },
  [setPageSizeFilter](state, action) {
    state.filters.page = 1;
    state.filters.records = action.payload;
  },
  [setInvoiceFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
  }
});

const transformFilters = createTransform(
  (inboundState) => {
    // persist
    return { records: inboundState.records };
  },
  (outboundState) => {
    // hydrate
    return { ...initialState.filters, records: outboundState.records };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['filters'] }
);

export default persistReducer(
  {
    key: 'billingRecords',
    storage,
    whitelist: ['filters'],
    transforms: [transformFilters]
  },
  billingRecords
);

import React from 'react';
import styled from '@emotion/styled';

const StyledMessage = styled.p`
  line-height: 24px;
  &.error {
    color: #cf4242 !important;
  }

  &.notice {
    color: #050593 !important;
  }

  &.response {
    color: #0a8e4f !important;
    margin-top: 10px;
  }
`;

const Message = ({ children, type }) => {
  return <StyledMessage className={type}>{children}</StyledMessage>;
};

export default Message;

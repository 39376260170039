import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import styled from '@emotion/styled';
const { confirm } = Modal;

const StyledContainer = styled.div`
  p {
    font-size: 16px;
  }
  div.note {
    margin-top: 15px;
  }
  span.noteText {
    font-size: 14px;
  }
`;

const ModalContent = (props) => {
  return (
    <StyledContainer>
      <p>System found some non-computed orders for below invoices:-</p>
      {props.invoices.map((invoice, i) => (
        <div key={i}>
          <a target="_blank" href={`/non-computed-orders/${invoice}`} rel="noreferrer">
            {invoice}
          </a>
        </div>
      ))}
      <div className="note">
        <b>Note: </b>
        <span className="noteText">
          Clicking on &apos;Continue&apos; button will ignore non-computed orders and push invoices
          to Xero.
        </span>
      </div>
    </StyledContainer>
  );
};

const nonComputedOrdersWarningModal = async (invoices, createInvoicesHandler) => {
  confirm({
    title: 'Warning!',
    width: 680,
    icon: <ExclamationCircleOutlined />,
    content: <ModalContent invoices={invoices} />,
    okText: 'Continue',
    onOk: async () => {
      try {
        return await createInvoicesHandler(0);
      } catch (err) {
        let error_msg = err?.response?.data?.result || 'Create invoices in xero failed.';
        message.error(error_msg);
      }
    }
  });
};

export default nonComputedOrdersWarningModal;

import { queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const getClients = async ({ page = 1, size = 50, id = '', name = '', email = '' }) => {
  const params = removeEmpty({
    page,
    size,
    id,
    name,
    email
  });

  const queryParams = queryString(params);
  const url = `/clients?${queryParams}`;
  const response = await request().get(url);
  return response.data.data;
};

export const clientService = {
  getClients
};

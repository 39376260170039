import { createAction } from '@reduxjs/toolkit';
import { pricingGroupService } from './pricing-groups.service';

export const fetchPricingGroups = createAction('pricingGroups/FETCH_DATA');
export const fetchPricingGroupsLoading = createAction('pricingGroups/FETCH_DATA_LOADING');

export const setFilters = createAction('pricingGroups/SET_FILTERS');
export const resetFilters = createAction('pricingGroups/RESET_FILTERS');

export const getPricingGroups =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchPricingGroupsLoading(true));
    pricingGroupService
      .getPricingGroups(params)
      .then((data) => {
        dispatch(fetchPricingGroups(data));
      })
      .catch(() => {
        dispatch(fetchPricingGroupsLoading(false));
      });
  };

import styled from '@emotion/styled';
import React from 'react';
import { Card } from '../../components';
import { OrderDetails } from './shared';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 10px 40px;
  h1 {
    color: #000;
    margin: 20px;
    text-align: center;
  }
  h4 {
    color: #000;
    margin-bottom: 0px;
  }
  label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: rgb(112, 112, 112);
    margin-bottom: 5px;
  }
  .section {
    margin-top: 20px;
  }
  .priceValue {
    color: #000;
    font-weight: bold;
  }
  .error {
    margin-top: 5px;
  }
`;

const TrackOrders = () => {
  return (
    <StyledCard>
      <OrderDetails />
    </StyledCard>
  );
};

export default TrackOrders;

import { createAction } from '@reduxjs/toolkit';
import { clientService } from './clients.service';

export const fetchClients = createAction('clients/FETCH_CLIENTS_DATA');
export const fetchClientsLoading = createAction('clients/FETCH_CLIENTS_LOADING');

export const setFilters = createAction('clients/SET_FILTERS');
export const resetFilters = createAction('clients/RESET_FILTERS');

export const getClients = (params) => async (dispatch) => {
  dispatch(fetchClientsLoading(true));

  clientService
    .getClients(params)
    .then((data) => {
      dispatch(fetchClients(data));
    })
    .catch(() => {
      dispatch(fetchClientsLoading(false));
    });
};

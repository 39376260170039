import styled from '@emotion/styled';
import { Select as AntSelect } from 'antd';
import React from 'react';

const StyledSelect = styled.div`
  .ant-select {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    height: 32px;
    color: #000;
    width: 100%;
    display: block;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #050593;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
`;

const StyledDropdownMenu = styled.div`
  .ant-select-item-option {
    font-family: AvenirLTStdBook;
    color: #000;
    font-size: 16px;
  }
  .ant-select-item-option-selected {
    font-family: AvenirLTStdHeavy;
    font-weight: normal;
    color: #000;
    font-size: 16px;
  }
  .ant-select-item-option-selected,
  .ant-select-item-option-selected-active:not(.ant-select-item-option-selected-disabled),
  .ant-select-item-option-selected:hover:not(.ant-select-item-option-selected-disabled) {
    background-color: #f2f2f9;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f2f2f9;
  }
`;

const Select = (props) => {
  const { children, ...rest } = props;
  return (
    <StyledSelect>
      <AntSelect
        dropdownRender={(menu) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
        {...rest}
      >
        {children}
      </AntSelect>
    </StyledSelect>
  );
};

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

export default Select;

import styled from '@emotion/styled';
import { List, message, Modal, Statistic } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, SearchInput } from '../../../components';
import { updateBillingCycle } from '../../orders/orders.action';

const TotalContainer = styled(Statistic)`
  display: flex;
  justify-content: end;
  margin: 10px 0px 5px 0px;
`;

const ChangeBillingCycleModal = ({
  data: [tns = '', total = 0],
  visible = false,
  setVisible = () => {}
}) => {
  const key = 'update-billing-cycle';
  const dispatch = useDispatch();
  const { code, loading } = useSelector((state) => state.orders.changeCycles);
  const [cycle, setCycle] = useState(moment().format('MM/DD/YYYY'));

  const handleOk = () => {
    message.loading({ content: 'Action in progress...', key, duration: 0 });
    let month = moment(cycle).format('M');
    let year = moment(cycle).format('YYYY');
    dispatch(updateBillingCycle({ tns, month: parseInt(month), year: parseInt(year) }));
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInputChange = (e) => {
    setCycle(e);
  };

  const handleBillingCycles = () => {
    const futureMonth = moment().add(1, 'M');
    const currentMonth = moment();
    const lastMonth = moment().subtract(1, 'M');
    const last2Months = moment().subtract(2, 'M');
    const last3Months = moment().subtract(3, 'M');

    return [
      {
        value: futureMonth.format('MM/DD/YYYY'),
        label: futureMonth.format('MMM YYYY')
      },
      {
        value: currentMonth.format('MM/DD/YYYY'),
        label: currentMonth.format('MMM YYYY')
      },
      {
        value: lastMonth.format('MM/DD/YYYY'),
        label: lastMonth.format('MMM YYYY')
      },
      {
        value: last2Months.format('MM/DD/YYYY'),
        label: last2Months.format('MMM YYYY')
      },
      {
        value: last3Months.format('MM/DD/YYYY'),
        label: last3Months.format('MMM YYYY')
      }
    ];
  };

  useEffect(() => {
    if (code === 200) {
      message.success({ content: 'Success!', key, duration: 3 });
    } else if (code === 500) {
      message.error({ content: 'Failed to update!', key, duration: 5 });
    }
  }, [code]);

  return (
    <Modal
      title="Change Billing Cycle"
      visible={visible}
      width={600}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="back" onClick={() => handleCancel()}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={total === 0 || total > 500}
          onClick={() => handleOk()}
        >
          Submit
        </Button>
      ]}
    >
      <List
        grid={{ gutter: 16, column: 3 }}
        style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: '200px' }}
        dataSource={total ? tns.split(',') : []}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      {total ? (
        <>
          <TotalContainer value={total} suffix="/ 500" />
          <label htmlFor="billing-cycle">Billing Cycle</label>
          <SearchInput
            id="billing-cycle"
            items={handleBillingCycles()}
            value={cycle}
            onChange={handleInputChange}
          />
        </>
      ) : null}
    </Modal>
  );
};

export default ChangeBillingCycleModal;

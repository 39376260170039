import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist';
import billingRecords from '../pages/billing-records/billing-records.reducer';
import clientDetails from '../pages/client-details/client-details.reducer';
import clients from '../pages/clients/clients.reducer';
import invoiceDetails from '../pages/invoice-details/invoice-details.reducer';
import invoices from '../pages/invoices/invoices.reducer';
import nonComputedOrders from '../pages/non-computed-orders/non-computed-orders.reducer';
import orders from '../pages/orders/orders.reducer';
import pricingGroupDetails from '../pages/pricing-group-details/pricing-group-details.reducer';
import pricingGroupManage from '../pages/pricing-group-manage/pricing-group-manage.reducer';
import pricingGroupRules from '../pages/pricing-group-rules/pricing-group-rules.reducer';
import pricingGroups from '../pages/pricing-groups/pricing-groups.reducer';
import reports from '../pages/reports/reports.reducer';
import surcharges from '../pages/surcharges/surcharges.reducer';
import ticketDetails from '../pages/ticket-details/ticket-details.reducer';
import tickets from '../pages/tickets/tickets.reducer';
import generateCsv from './generate-csv/generate-csv.reducer';

const rootReducer = {
  billingRecords,
  invoices,
  invoiceDetails,
  surcharges,
  clients,
  clientDetails,
  orders,
  nonComputedOrders,
  pricingGroups,
  pricingGroupRules,
  pricingGroupManage,
  pricingGroupDetails,
  generateCsv,
  reports,
  tickets,
  ticketDetails
};

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

export const store = configureStore({
  reducer: combineReducers({ router: routerReducer, ...rootReducer }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(routerMiddleware)
});

export const history = createReduxHistory(store);

export const persistor = persistStore(store);

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

const { Dragger } = Upload;

const UploadField = ({
  name = 'file',
  multiple = true,
  onChange = () => {},
  onRemove = () => {}
}) => (
  <Dragger
    name={name}
    multiple={multiple}
    beforeUpload={(file, files) => {
      onChange(files);
      return false;
    }}
    onRemove={(file) => {
      onRemove(file);
    }}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
  </Dragger>
);

export default UploadField;

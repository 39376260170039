import { createAction } from '@reduxjs/toolkit';
import { generateCsvServices as services } from './generate-csv.service';

export const clearCode = createAction('generate/CLEAR_CODE');
export const generateCsv = createAction('generate/GENERATE_CSV');
export const generateCsvLoading = createAction('generate/GENERATE_CSV_LOADING');

export const generateCsvFn =
  (action = '', params = {}) =>
  async (dispatch) => {
    dispatch(generateCsvLoading(true));

    try {
      if (action === 'orders') {
        const code = await services.getOrders(params);
        dispatch(generateCsv(code));
      } else if (action === 'invoices') {
        const code = await services.getInvoices(params);
        dispatch(generateCsv(code));
      } else {
        dispatch(generateCsvLoading(false));
      }
    } catch (err) {
      dispatch(generateCsv(500));
    }

    setTimeout(() => {
      dispatch(clearCode());
    }, 5000);
  };

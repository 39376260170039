import request from '../../utils/request';

const getReports = async () => {
  const response = await request().get('/orders/reports');
  const { reports } = response.data.data;
  return reports;
};

export default {
  getReports
};

const ORIGIN_ZONES = [
  {
    label: 'West Malaysia Returns Origin Pickup (Malaysia)',
    zone: 'West Malaysia Returns Origin Pickup',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Taipei Origin (Taiwan)',
    zone: 'Taipei Origin',
    country: 'Taiwan',
    countryCode: 'TW'
  },
  {
    label: 'Australia - Sydney Origin (Australia)',
    zone: 'Australia - Sydney Origin',
    country: 'Australia',
    countryCode: 'AU'
  },
  {
    label: 'Australia - Melbourne Origin (Australia)',
    zone: 'Australia - Melbourne Origin',
    country: 'Australia',
    countryCode: 'AU'
  },
  {
    label: 'Hong Kong Origin (Hong Kong)',
    zone: 'Hong Kong Origin',
    country: 'Hong Kong',
    countryCode: 'HK'
  },
  {
    label: 'Turkey Origin (Turkey)',
    zone: 'Turkey Origin',
    country: 'Turkey',
    countryCode: 'TR'
  },
  {
    label: 'Germany Origin (Germany)',
    zone: 'Germany Origin',
    country: 'Germany',
    countryCode: 'DE'
  },
  {
    label: 'East Malaysia Returns Origin Pickup (Malaysia)',
    zone: 'East Malaysia Returns Origin Pickup',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Malaysia - Johor Origin (Malaysia)',
    zone: 'Malaysia - Johor Origin',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Brunei Origin (Brunei)',
    zone: 'Brunei Origin',
    country: 'Brunei',
    countryCode: 'BN'
  },
  {
    label: 'India Origin (India)',
    zone: 'India Origin',
    country: 'India',
    countryCode: 'IN'
  },
  {
    label: 'Japan - Tokyo Origin (Japan)',
    zone: 'Japan - Tokyo Origin',
    country: 'Japan',
    countryCode: 'JP'
  },
  {
    label: 'Klang Valley Origin (Malaysia)',
    zone: 'Klang Valley Origin',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Philippines Origin (Philippines)',
    zone: 'Philippines Origin',
    country: 'Philippines',
    countryCode: 'PH'
  },
  {
    label: 'Singapore Origin (Singapore)',
    zone: 'Singapore Origin',
    country: 'Singapore',
    countryCode: 'SG'
  },
  {
    label: 'UK Origin (United Kingdom)',
    zone: 'UK Origin',
    country: 'United Kingdom',
    countryCode: 'GB'
  },
  {
    label: 'Vietnam Origin (Vietnam)',
    zone: 'Vietnam Origin',
    country: 'Vietnam',
    countryCode: 'VN'
  },
  {
    label: 'Thailand - Bangkok Origin (Thailand)',
    zone: 'Thailand - Bangkok Origin',
    country: 'Thailand',
    countryCode: 'TH'
  },
  {
    label: 'China - Shenzhen Origin (China)',
    zone: 'China - Shenzhen Origin',
    country: 'China',
    countryCode: 'CN'
  },
  {
    label: 'Singapore Returns Origin Pickup (Singapore)',
    zone: 'Singapore Returns Origin Pickup',
    country: 'Singapore',
    countryCode: 'SG'
  },
  {
    label: 'Indonesia NBD Origin Pickup (Indonesia)',
    zone: 'Indonesia NBD Origin Pickup',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'South Korea - Incheon Origin (South Korea)',
    zone: 'South Korea - Incheon Origin',
    country: 'South Korea',
    countryCode: 'KR'
  },
  {
    label: 'USA - Los Angeles Origin (United States)',
    zone: 'USA - Los Angeles Origin',
    country: 'United States',
    countryCode: 'US'
  },
  {
    label: 'Indonesia - Jakarta Origin (Indonesia)',
    zone: 'Indonesia - Jakarta Origin',
    country: 'Indonesia',
    countryCode: 'ID'
  }
];

export default ORIGIN_ZONES;

import { createAction } from '@reduxjs/toolkit';
import service from './ticket-details.service';

export const fetchTicketDetailsLoading = createAction('tickets/FETCH_TICKET_DETAILS_LOADING');
export const fetchTicketDetail = createAction('tickets/FETCH_TICKET_DATA');

export const editTicketDetailsLoading = createAction('tickets/EDIT_TICKET_DETAILS_LOADING');
export const editTicketDetails = createAction('tickets/EDIT_TICKET_DATA');

export const fetchCommentsLoading = createAction('tickets/FETCH_COMMENTS_LOADING');
export const fetchComments = createAction('tickets/FETCH_COMMENTS_DATA');

export const getTicketDetail = (ticketId) => async (dispatch) => {
  dispatch(fetchTicketDetailsLoading(true));
  service
    .getTicketDetails(ticketId)
    .then((data) => {
      dispatch(fetchTicketDetail(data));
    })
    .catch(() => {
      dispatch(fetchTicketDetailsLoading(false));
    });
};

export const editTicketDetail = (ticketId, name, value) => async (dispatch) => {
  dispatch(editTicketDetailsLoading(true));
  service
    .editTicketDetails(ticketId, name, value)
    .then(() => {
      dispatch(editTicketDetails(name));
    })
    .catch(() => {
      dispatch(editTicketDetailsLoading(false));
    });
};

export const getComments =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchCommentsLoading(true));
    service
      .getComments(params)
      .then((data) => {
        dispatch(fetchComments(data));
      })
      .catch(() => {
        dispatch(fetchCommentsLoading(false));
      });
  };

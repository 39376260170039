import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../components';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/Dropdown';
import { resetFilters, setFilters } from '../pricing-group-manage.action';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  user-select: none;
  width: 800px;
  height: 170px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 0 8px !important;
  }
  .inputRange {
    .rangeStart {
      border-right: 0px;
      width: 42%;
      text-align: center;
    }
    .rangeEnd {
      width: 42%;
      text-align: center;
      border-left: 0;
    }
    .rangeInputSplit {
      width: 16%;
      border-left: 0;
      border-right: 0;
      background-color: #fff;
      pointer-events: none;
    }
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdown = ({ onFilter = () => {}, loading }) => {
  const node = useRef();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState();

  const name = useSelector((state) => state?.pricingGroupManage?.filters?.name);

  const startDate = useSelector((state) =>
    state?.pricingGroupManage?.filters?.startDate
      ? state.pricingGroupManage.filters.startDate?.map((d) => moment(d)) || []
      : []
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };

  const handleFilter = () => {
    onFilter({
      name,
      startDate
    });
    setVisible(false);
  };

  const handleOnChange = (field) => (e) => {
    dispatch(setFilters({ [field]: e.target.value.trim() }));
  };

  const handleDatePickerChange = (field) => (value) => {
    if (!value || !value?.length) {
      dispatch(setFilters({ [field]: [] }));
      return;
    }

    const startDate = value[0].format();
    const endDate = value[1].format();

    dispatch(setFilters({ [field]: [startDate, endDate] }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={'Filter'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]} className="field-row">
            <Col span={12} className="field-container">
              <label htmlFor="pricingGroup">Pricing Group</label>
              <Input
                id="pricingGroupName"
                placeholder="Type here"
                value={name}
                onChange={handleOnChange('name')}
              />
            </Col>
            <Col span={12} className="field-container">
              <label htmlFor="startDate">Start Date</label>
              <RangePicker
                id="startDate"
                contained
                allowClear
                value={startDate}
                onChange={handleDatePickerChange('startDate')}
              />
            </Col>
          </Row>
          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              disabled={loading}
              onClick={handleReset}
            >
              Reset filter
            </Button>
            <Button type="secondary" disabled={loading} onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleFilter}>
              Filter
            </Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdown;

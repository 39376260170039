const CATEGORIES = [
  'Accessory (no-battery)',
  'Accessory (with battery)',
  'Audio Video',
  'Bags & Luggages',
  'Board Games',
  'Books & Collectibles',
  'Cameras',
  'Computers & Laptops',
  'Documents',
  'Dry Food & Supplements',
  'Fashion',
  'Gaming',
  'Health & Beauty',
  'Home Appliances',
  'Home Decor',
  'Jewelry',
  'Mobile Phones',
  'Pet Accessory',
  'Shoes',
  'Sport & Leisure',
  'Tablets',
  'Toys',
  'Watches'
];

export default CATEGORIES;

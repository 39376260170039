import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, Divider, message, Modal, Row, Skeleton as AntSkeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextArea } from '../../../components';
import { formatDateDisplay } from '../../../utils/formatters';
import { editTicketDetail, getComments, getTicketDetail } from '../ticket-details.action';
import service from '../ticket-details.service';
import TicketAttachments from './TicketAttachments';
import TicketComments from './TicketComments';

const issueReproducibleId = process.env.REACT_APP_JIRA_ISSUE_REPRODUCIBLE_ID;
const noFurtherIssuesId = process.env.REACT_APP_JIRA_NO_FURTHER_ISSUES_ID;
const initialInvestigationId = process.env.REACT_APP_JIRA_INITIAL_INVESTIGATION_ID;
const invalidIssueId = process.env.REACT_APP_JIRA_INVALID_ISSUE_ID;

const StyledDiv = styled.div`
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
  }
  em.label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  span.value {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
`;

const handleDate = (text) => formatDateDisplay(text).format('DD MMM YYYY');
const handleTime = (text) => formatDateDisplay(text).fromNow();

const TicketInfo = (props) => {
  const { ticketId } = props;
  const dispatch = useDispatch();

  const [summary, setSummary] = useState('');
  const [oldSummary, setOldSummary] = useState('');
  const [description, setDescription] = useState('');
  const [oldDescription, setOldDescription] = useState('');
  const [errorSummary, setErrorSummary] = useState(true);
  const [errorDescription, setErrorDescription] = useState(true);
  const [editSummaryMode, setEditSummaryMode] = useState(false);
  const [editDescriptionMode, setEditDescriptionMode] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [toDoStatus, setToDoStatus] = useState(false);
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [transition, setTransition] = useState('');
  const [transitionId, setTransitionId] = useState('');
  const [transitionLoading, setTransitionLoading] = useState(false);

  const { data: ticketData, loading: infoLoading } = useSelector((state) => state.ticketDetails);
  const { name: editData, loading: editLoading } = useSelector((state) => state.ticketDetails.edit);

  const handleSummary = (e) => {
    const { value } = e.target;
    const trimValue = value.trim();

    if (!trimValue) {
      setErrorSummary(true);
      return;
    }

    if (oldSummary === trimValue) {
      setErrorSummary(true);
      return;
    }

    setErrorSummary(false);
    setSummary(value);
    setEditSummaryMode(true);
  };

  const handleDescripton = (e) => {
    const { value } = e.target;

    if (oldDescription === value) {
      setErrorDescription(true);
      return;
    }

    setErrorDescription(false);
    setDescription(value);
    setEditDescriptionMode(true);
  };

  const handleCancelSummaryMode = () => {
    setSummary(oldSummary);
    setEditSummaryMode(false);
  };

  const handleCancelDescriptionMode = () => {
    setDescription(oldDescription);
    setEditDescriptionMode(false);
  };

  const handleSubmit = (name, value) => {
    let content = value;
    if (name === 'description') {
      content = value.concat(`\n\nReported by ${ticketData.reporter}`);
    }
    dispatch(editTicketDetail(ticketId, name, content.trim()));
  };

  const handleChangeStatus = (transitionId, transition, status) => {
    setTransitionId(transitionId);
    setTransition(transition);
    setNewStatus(status);
    setVisible(true);
  };

  const handleComment = (e) => {
    const { value } = e.target;
    const trim = value.trim();

    if (!trim) {
      setComment('');
      return;
    }

    setComment(value);
  };

  const handleTransition = async () => {
    const key = `transition-${new Date().getTime()}`;
    message.loading({ content: 'Updating...', key });
    setTransitionLoading(true);

    try {
      if (toDoStatus) {
        await service.transitionTicket(ticketId, initialInvestigationId, '');
        await service.transitionTicket(ticketId, invalidIssueId, '');
      }
      await service.transitionTicket(ticketId, transitionId, comment);
      setStatus(newStatus);
      setReviewMode(false);
      setToDoStatus(false);
      setTransitionLoading(false);
      setVisible(false);
      message.success({ content: 'Updated!', key });
      dispatch(getComments({ id: ticketId, page: 1, maxResults: 50 }));
    } catch (error) {
      console.log(error);
      setTransitionLoading(false);
      message.error({ content: 'Failed to update ticket status', key });
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const afterClose = () => {
    setComment('');
    setNewStatus('');
    setTransition('');
    setTransitionId('');
  };

  useEffect(() => {
    dispatch(getTicketDetail(ticketId));
  }, [dispatch, ticketId]);

  useEffect(() => {
    setSummary(ticketData.summary);
    setOldSummary(ticketData.summary);
    setDescription(ticketData.description || '');
    setOldDescription(ticketData.description || '');
    setStatus(ticketData.status);
    setReviewMode(ticketData.review);
    setToDoStatus(ticketData.todo);
  }, [ticketData]);

  useEffect(() => {
    if (!editLoading && editData === 'summary') {
      setOldSummary(summary);
      setEditSummaryMode(false);
      return;
    }

    if (!editLoading && editData === 'description') {
      setOldDescription(description);
      setEditDescriptionMode(false);
      return;
    }
  }, [editData, editLoading]);

  return (
    <>
      {infoLoading ? (
        <AntSkeleton
          active
          title={{ width: '100%' }}
          paragraph={{
            rows: 5,
            width: ['100%', '100%', '100%', '100%', '100%']
          }}
        />
      ) : (
        <StyledDiv>
          <Row gutter={[24, 16]}>
            <Col style={{ marginRight: 'auto' }}>
              <h3>Ticket Details</h3>
            </Col>
            {reviewMode && (
              <Col>
                <Button
                  className="warning-btn"
                  disabled={transitionLoading}
                  onClick={() =>
                    handleChangeStatus(issueReproducibleId, 'Re-Open', 'Initial Investigation')
                  }
                >
                  Re-Open
                </Button>
              </Col>
            )}
            {(reviewMode || toDoStatus) && (
              <Col>
                <Button
                  className="grey-btn"
                  disabled={transitionLoading}
                  onClick={() => handleChangeStatus(noFurtherIssuesId, 'Close', 'Done')}
                >
                  Close
                </Button>
              </Col>
            )}
          </Row>

          <h5>Summary</h5>
          <Row gutter={[24, 16]}>
            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <span className="value">
                <TextArea
                  className="ticket-details"
                  name="summary"
                  value={summary}
                  maxLength={255}
                  bordered={false}
                  onChange={handleSummary}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </span>

              {editSummaryMode ? (
                <Row type="flex" justify="end" style={{ margin: '5px' }} gutter={4}>
                  <Col>
                    <Button
                      loading={editLoading}
                      disabled={errorSummary}
                      icon={<CheckOutlined />}
                      onClick={() => handleSubmit('summary', summary)}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="danger"
                      icon={<CloseOutlined />}
                      onClick={() => handleCancelSummaryMode()}
                    />
                  </Col>
                </Row>
              ) : (
                <div />
              )}
            </Col>
          </Row>
          <Divider />

          <h5>Details</h5>
          <Row gutter={[24, 16]}>
            <Col span={4}>
              <em className="label">Ticket ID</em>
              <span className="value">{ticketData.key}</span>
            </Col>

            <Col span={4}>
              <em className="label">Status</em>
              <span className="value">{status}</span>
            </Col>

            <Col span={4}>
              <em className="label">Priority</em>
              <span className="value">{ticketData.priority}</span>
            </Col>

            <Col span={4}>
              <em className="label">Assignee</em>
              <span className="value">{ticketData.assignee}</span>
            </Col>

            <Col span={4}>
              <em className="label">Reporter</em>
              <span className="value">{ticketData.reporter}</span>
            </Col>

            <Col span={4}>
              <em className="label">Created On</em>
              <span className="value">{handleDate(ticketData.created)}</span>
            </Col>

            <Col span={4}>
              <em className="label">Last Updated</em>
              <span className="value">{handleTime(ticketData.updated)}</span>
            </Col>

            <Col span={20}>
              <em className="label">Labels</em>
              <span className="value">{ticketData.labels}</span>
            </Col>
          </Row>
          <Divider />

          <h5>Description</h5>
          <Row gutter={[24, 16]}>
            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <span className="value">
                <TextArea
                  className="ticket-details"
                  name="description"
                  value={description}
                  autoSize={{ minRows: 5, maxRows: 25 }}
                  onChange={handleDescripton}
                ></TextArea>
              </span>

              {editDescriptionMode ? (
                <Row type="flex" justify="end" style={{ margin: '5px' }} gutter={4}>
                  <Col>
                    <Button
                      loading={editLoading}
                      disabled={errorDescription}
                      icon={<CheckOutlined />}
                      onClick={() => handleSubmit('description', description)}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="danger"
                      icon={<CloseOutlined />}
                      onClick={() => handleCancelDescriptionMode()}
                    />
                  </Col>
                </Row>
              ) : (
                <div />
              )}
            </Col>
          </Row>
          <Divider />

          <TicketAttachments ticketId={ticketId} data={ticketData.attachments || []} />
          <Divider />

          <TicketComments ticketId={ticketId} />

          <Modal
            title="Change status"
            visible={visible}
            closable={false}
            maskClosable={false}
            onOk={handleTransition}
            onCancel={handleCancel}
            destroyOnClose={true}
            afterClose={afterClose}
            footer={[
              <Button key="back" type="secondary" onClick={handleCancel}>
                No
              </Button>,
              <Button
                key="submit"
                disabled={newStatus !== 'Done' && !comment}
                onClick={handleTransition}
              >
                Yes
              </Button>
            ]}
          >
            <p>
              Are you sure you want to <strong>{transition}</strong> ticket{' '}
              <strong>[{ticketId}]</strong>?
            </p>

            {newStatus === 'Initial Investigation' ? (
              <TextArea
                name="comment"
                placeholder="Please write your reason here..."
                value={comment}
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={handleComment}
              ></TextArea>
            ) : (
              <div />
            )}
          </Modal>
        </StyledDiv>
      )}
    </>
  );
};

export default TicketInfo;

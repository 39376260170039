import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from './components';
import PrivateOutlet from './layouts';
import * as Pages from './pages';
import { handleAuthentication } from './utils/auth';

/**
 * Top level application router
 *
 * @returns {Component}
 */
export const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/callback') {
      return handleAuthentication();
    }
  }, [location]);

  return (
    <Routes>
      {/* Public Route */}
      <Route path="*" element={<Pages.NotFound />} />
      <Route path="/callback" element={<Spinner />} />
      <Route path="/login" element={<Pages.Login />} />
      <Route path="/forget-password" element={<Pages.ForgetPassword />} />
      {/* Private Route */}
      <Route path="/" element={<PrivateOutlet />}>
        <Route exact path="/" element={<Navigate replace to="/track-orders" />} />
        <Route path="invoices/:quoteId" element={<Pages.InvoiceDetails />} />
        <Route path="invoices" element={<Pages.Invoices />} />
        <Route
          path="billing-records/:invoiceId/:originCountry/:destinationCountry/:billingType/:unitPrice/:weightLimit"
          element={<Pages.BillingRecords />}
        />
        <Route
          path="billing-records/:invoiceId/:originCountry/:destinationCountry/:billingType/:unitPrice"
          element={<Pages.BillingRecords />}
        />
        <Route path="billing-records/:invoiceId/:billingType" element={<Pages.BillingRecords />} />
        <Route path="billing-records" element={<Pages.BillingRecords />} />
        <Route path="clients" element={<Pages.Clients />} />
        <Route path="clients/:clientID" element={<Pages.ClientDetails />} />
        <Route path="orders" element={<Pages.Orders />} />
        <Route path="surcharges/:clientID" element={<Pages.Surcharges />} />
        <Route path="surcharges" element={<Pages.Surcharges />} />
        <Route path="track-orders" element={<Pages.TrackOrders />} />
        <Route path="cost-estimation" element={<Pages.CostEstimation />} />
        <Route path="pricing-groups/:name" element={<Pages.PricingGroupRules />} />
        <Route path="pricing-groups" element={<Pages.PricingGroups />} />
        <Route path="pricing-groups/manage/:name" element={<Pages.PricingGroupDetails />} />
        <Route path="pricing-groups/manage" element={<Pages.PricingGroupManage />} />
        <Route path="non-computed-orders/:invoiceID" element={<Pages.NonComputedOrders />} />
        <Route path="reports" element={<Pages.Reports />} />
        <Route path="tickets" element={<Pages.Tickets />} />
        <Route path="tickets/:ticketId" element={<Pages.TicketDetails />} />
        <Route path="not-found" element={<Pages.NotFound />} />
      </Route>
    </Routes>
  );
};

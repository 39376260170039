import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Divider, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../components';
import { ROLES } from '../../constants';
import { getProfile } from '../../utils/auth';
import { withRouter } from '../../utils/wrappers';
import { getClientDetail } from './client-details.action';
import {
  ClientInfo,
  FirstTouchStatuses,
  MappedCustomPricingGroups,
  MappedGlobalPricingGroups,
  MappedSurcharges,
  MappedTiers,
  PendingInvoices,
  VolumetricWeightFactors
} from './shared';

const StyledDetails = styled.div`
  max-width: 1200px;
  width: auto;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  padding: 30px 40px;
`;

const StyledInfo = styled.div`
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
  }
  em.label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  span.value {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;

    &.expired,
    &.expired > a {
      color: red;
    }
  }

  .view-link {
    color: rgb(24, 144, 255);
    cursor: pointer;
  }
  .header {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
  }
`;

const ClientDetails = (props) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const { clientID } = props.params;
  const { data, loading } = useSelector((state) => state.clientDetails);

  const { roles } = getProfile();
  const isCommercial = roles.includes(ROLES.COMMERCIAL);

  useEffect(() => {
    dispatch(getClientDetail(clientID));
  }, [dispatch, clientID]);

  return (
    <StyledDetails>
      <StyledCard>
        {loading ? (
          <AntSkeleton
            active
            title={{ width: '100%' }}
            paragraph={{
              rows: 5,
              width: ['100%', '100%', '100%', '100%', '100%']
            }}
          />
        ) : (
          <div>
            {data ? (
              <StyledInfo>
                <ClientInfo client={data} editMode={editMode} setEditMode={setEditMode} />
                {!editMode ? (
                  <>
                    <Divider />
                    <VolumetricWeightFactors mappedFactors={data.volumetric_weight_factors} />
                    <Divider />
                    <FirstTouchStatuses mappedStatuses={data.first_touch_statuses} />
                    <Divider />
                    {isCommercial ? <MappedTiers mappedTiers={data.tiers} /> : <div />}
                    <MappedGlobalPricingGroups mappedGlobalPricings={data.global_pricing_groups} />
                    <Divider />
                    <MappedCustomPricingGroups
                      mappedCustomPricingGroups={data.custom_pricing_groups}
                      hasPostpaidOnly={data.has_postpaid_only}
                    />
                    <Divider />
                    <MappedSurcharges mappedSurcharges={data.surcharges} />
                    <Divider />
                    <PendingInvoices clientID={clientID} />
                  </>
                ) : (
                  <></>
                )}
              </StyledInfo>
            ) : (
              <Empty description="No client found" />
            )}
          </div>
        )}
      </StyledCard>
    </StyledDetails>
  );
};

export default withRouter(ClientDetails);

import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import service from './invoices.service';

export const fetchInvoice = createAction('invoices/FETCH_INVOICES_DATA');
export const fetchInvoiceLoading = createAction('invoices/FETCH_INVOICES_LOADING');

export const fetchChoices = createAction('invoices/FETCH_CHOICES_DATA');
export const fetchChoicesLoading = createAction('invoices/FETCH_CHOICES_LOADING');

export const setInvoiceStatus = createAction('invoices/SET_INVOICE_STATUS');

export const setInvoiceFilters = createAction('invoices/SET_FILTERS');
export const resetFilters = createAction('invoices/RESET_FILTERS');

// get invoice filters from local storage
function getLocalStorageWithKey(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = moment().valueOf();
  // compare the expiry time of the item with the current time
  if (now > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const getChoices = () => async (dispatch) => {
  // check filter exists in local storage
  let filters = getLocalStorageWithKey('invoice_filters');
  if (filters) {
    dispatch(fetchChoices(filters));
  } else {
    dispatch(fetchChoicesLoading(true));
    service
      .getInvoiceFilters()
      .then((data) => {
        data.billing_cycles = data.billing_cycles
          ?.filter((item) => !!item?.billing_cycle)
          ?.map((item) => moment(item?.billing_cycle).format('YYYY-MM-DD'));
        data.billing_cycles = [...new Set(data.billing_cycles)];
        dispatch(fetchChoices(data));
        // store invoice filter in local storage for a day
        let item = {
          value: data,
          expiry: moment().endOf('day').valueOf()
        };
        localStorage.setItem('invoice_filters', JSON.stringify(item));
      })
      .catch(() => {
        dispatch(fetchChoicesLoading(false));
      });
  }
};

export const getInvoices =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchInvoiceLoading(true));
    service
      .getInvoices(params)
      .then((data) => {
        dispatch(fetchInvoice(data));
      })
      .catch(() => {
        dispatch(fetchInvoiceLoading(false));
      });
  };

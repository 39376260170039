import AWS from 'aws-sdk';
import { history } from './store';

const ACCESS_ID = process.env.REACT_APP_AWS_ACCESS_ID;
const SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;
const REGION = process.env.REACT_APP_AWS_REGION;
const BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const ENV = process.env.REACT_APP_ENVIRONMENT || 'dev';

AWS.config.update({
  credentials: new AWS.Credentials(ACCESS_ID, SECRET_KEY),
  region: REGION,
  sslEnabled: true,
  httpOptions: {
    timeout: 300000
  },
  maxRetries: 0
});

const lambda = new AWS.Lambda({
  // only use for local development
  // endpoint: 'http://localhost:4000',
});

const s3 = new AWS.S3({ params: { Bucket: BUCKET } });

/**
 * Invoke AWS Lambda function. For more documentation, refer here:
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Lambda.html#invoke-property
 *
 * @param {string} serviceName Name of service
 * @param {string} functionName Name of lambda function
 * @param {object} payload Object need to pass in parameter
 * @returns Response data from backend server
 */
export const invoke = async (serviceName = '', functionName = '', payload = {}) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('id_token');

    payload.Authorization = `Bearer ${token}`;

    const req = {
      FunctionName: `${serviceName}-${ENV}-${functionName}`,
      Payload: JSON.stringify(payload)
    };

    lambda.invoke(req, (err, res) => {
      if (err) {
        console.error(err);
        return reject(err);
      }

      const payload = JSON.parse(res.Payload);

      if (payload.statusCode === 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        return history.replace('/login');
      }

      const body = JSON.parse(payload.body);

      if (payload.statusCode >= 400) {
        return reject(body);
      }

      return resolve(body);
    });
  });
};

/**
 * Upload file to S3 Bucket
 * @param {string} folderName Name of folder use
 * @param {buffer| blob| string| readableStream | array} file Object data
 * @returns Key and location of the uploaded file
 */
export const upload = async (folderName, file) => {
  return new Promise((resolve, reject) => {
    const params = {
      Key: `finance_portal/${folderName}/${Date.now()}`,
      Body: file
    };

    // Uploading a stream with concurrency of 1 and partSize of 100mb
    const options = { partSize: 100 * 1024 * 1024, queueSize: 1 };

    s3.upload(params, options, (err, res) => {
      if (err) {
        return reject(err);
      }

      return resolve({ key: params.Key, location: res.Location });
    });
  });
};

import { createReducer } from '@reduxjs/toolkit';
import { clearCode, generateCsv, generateCsvLoading } from './generate-csv.action';

const initialState = {
  code: null,
  loading: false
};

export default createReducer(initialState, {
  [generateCsvLoading](state, action) {
    state.code = null;
    state.loading = action.payload;
  },
  [generateCsv](state, action) {
    state.code = action.payload;
    state.loading = false;
  },
  [clearCode](state) {
    state.code = initialState.code;
  }
});

/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import { Card, SelectDropdown, Table } from '../../components';
import { formatDateDisplay } from '../../utils/formatters';
import { withRouter } from '../../utils/wrappers';
import { getChoices, getPricingGroupRules } from './pricing-group-rules.action';
import FilterDropdownPricingGroupRules from './shared/FilterDropdownPricingGroupRules';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
  }
  em.label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  span.value {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
`;

const PricingGroupRules = (props) => {
  const { name } = props.params;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [filters, setFilters] = useState({});
  const { data: rules, total, loading } = useSelector((state) => state.pricingGroupRules.rules);
  const choices = useSelector((state) => state.pricingGroupRules.choices);
  const originZones = choices.originZones;
  const destinationZones = choices.destinationZones;
  const currencies = choices.currencies;
  const orderTypes = choices.orderTypes;

  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
    },
    {
      title: 'Origin Zone',
      dataIndex: 'originZone',
      render: (text) => text || '-',
      key: 'originZone'
    },
    {
      title: 'Destination Zone',
      dataIndex: 'destinationZone',
      render: (text) => text || '-',
      key: 'destinationZone'
    },
    {
      title: 'Amount',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Amount Type',
      dataIndex: 'pricingType',
      render: (text) => text || '-',
      key: 'pricingType'
    },
    {
      title: 'Weight Upper Bound',
      dataIndex: 'weightUpperBound',
      key: 'weightUpperBound'
    },
    {
      title: 'Service Type',
      dataIndex: 'orderType',
      render: (text) => text || '-',
      key: 'orderType'
    },
    {
      title: 'Order Direction',
      dataIndex: 'orderDirection',
      render: (text) => text || '-',
      key: 'orderDirection'
    }
  ];

  const [tableColumns, setTableColumns] = useState([
    'currency',
    'originZone',
    'destinationZone',
    'price',
    'pricingType',
    'weightUpperBound',
    'orderType',
    'orderDirection'
  ]);

  const handleFilter = (filters, page = 1, size = 50) => {
    setPage(page);
    setSize(size);
    setFilters(filters);

    const recordFilters = { page, size, pricingGroup: name, ...filters };
    if (Object.keys(filters).length !== 0) {
      dispatch(getPricingGroupRules(recordFilters));
    }
  };

  useEffect(() => {
    const recordFilters = {
      page,
      size,
      pricingGroup: name,
      ...filters
    };

    dispatch(getChoices());
    dispatch(getPricingGroupRules(recordFilters));
  }, [dispatch, page, size, filters]);
  console.log(rules[0]?.effectiveDate);
  return (
    <StyledCard>
      <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
        <Col>
          <h3>Pricing Group Details</h3>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col>
          <em className="label">Name</em>
          <span className="value">{name}</span>
        </Col>
        <Col offset={2} span={4}>
          <em className="label">Effective Date</em>
          <span className="value">
            {rules[0]?.effectiveDate
              ? formatDateDisplay(rules[0].effectiveDate).format('DD MMM YYYY')
              : '-'}
          </span>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
        <Col>
          <FilterDropdownPricingGroupRules
            onFilter={handleFilter}
            originZones={originZones}
            destinationZones={destinationZones}
            currencies={currencies}
            orderTypes={orderTypes}
          />
        </Col>
        <Col>
          <SelectDropdown
            placeholder="Hide/Show Columns"
            multiple
            value={tableColumns}
            items={columns.map((column) => ({
              label: column.title,
              value: column.key
            }))}
            onChange={(value) => setTableColumns([...value])}
          />
        </Col>
        <Col sx={{ ml: 'auto' }}>
          <SelectDropdown
            value={size}
            items={[
              { label: '20 items/page', value: 20 },
              { label: '50 items/page', value: 50 },
              { label: '100 items/page', value: 100 }
            ]}
            onChange={(size) => handleFilter(filters, 1, size)}
          />
        </Col>
      </Row>
      <Row>
        <Table
          loading={loading}
          rowKey={(row) => `${row.index}`}
          columns={columns.filter((column) => tableColumns.includes(column.key))}
          renderEmptyTable
          dataSource={rules}
          pageSize={size}
          currentPage={page}
          totalCount={total}
          onChange={(page) => handleFilter(filters, page, size)}
          scroll={{ x: 1700 }}
          empty={
            <React.Fragment>
              {!loading && (
                <div>
                  <span>No data found.</span>&nbsp;
                </div>
              )}
              {loading && (
                <AntSkeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 5,
                    width: ['100%', '100%', '100%', '100%', '100%']
                  }}
                />
              )}
            </React.Fragment>
          }
        />
      </Row>
    </StyledCard>
  );
};
export default withRouter(PricingGroupRules);

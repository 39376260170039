const DESTINATION_ZONES = [
  {
    label: 'USA - Mainland (United States)',
    zone: 'USA - Mainland',
    country: 'United States',
    countryCode: 'US'
  },
  {
    label: 'USA - US Territories (United States)',
    zone: 'USA - US Territories',
    country: 'United States',
    countryCode: 'US'
  },
  {
    label: 'USA - US Territories (Puerto Rico)',
    zone: 'USA - US Territories',
    country: 'Puerto Rico',
    countryCode: 'PR'
  },
  {
    label: 'Indonesia - AirAsia - North Sumatra (Indonesia)',
    zone: 'Indonesia - AirAsia - North Sumatra',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Indonesia - AirAsia - South Sumatra (Indonesia)',
    zone: 'Indonesia - AirAsia - South Sumatra',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Indonesia - AirAsia - Kalimantan (Indonesia)',
    zone: 'Indonesia - AirAsia - Kalimantan',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Indonesia - AirAsia - Bali (Indonesia)',
    zone: 'Indonesia - AirAsia - Bali',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Vietnam (Vietnam)',
    zone: 'Vietnam',
    country: 'Vietnam',
    countryCode: 'VN'
  },
  {
    label: 'Singapore (Singapore)',
    zone: 'Singapore',
    country: 'Singapore',
    countryCode: 'SG'
  },
  {
    label: 'Indonesia (Indonesia)',
    zone: 'Indonesia',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Malaysia (Malaysia)',
    zone: 'Malaysia',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Philippines (Philippines)',
    zone: 'Philippines',
    country: 'Philippines',
    countryCode: 'PH'
  },
  {
    label: 'Thailand (Thailand)',
    zone: 'Thailand',
    country: 'Thailand',
    countryCode: 'TH'
  },
  {
    label: 'Brunei (Brunei)',
    zone: 'Brunei',
    country: 'Brunei',
    countryCode: 'BN'
  },
  {
    label: 'South Korea (South Korea)',
    zone: 'South Korea',
    country: 'South Korea',
    countryCode: 'KR'
  },
  {
    label: 'Japan (Japan)',
    zone: 'Japan',
    country: 'Japan',
    countryCode: 'JP'
  },
  {
    label: 'India (India)',
    zone: 'India',
    country: 'India',
    countryCode: 'IN'
  },
  {
    label: 'Australia New Zealand (Australia)',
    zone: 'Australia New Zealand',
    country: 'Australia',
    countryCode: 'AU'
  },
  {
    label: 'Australia New Zealand (New Zealand)',
    zone: 'Australia New Zealand',
    country: 'New Zealand',
    countryCode: 'NZ'
  },
  {
    label: 'USA Canada (United States)',
    zone: 'USA Canada',
    country: 'United States',
    countryCode: 'US'
  },
  {
    label: 'USA Canada (Canada)',
    zone: 'USA Canada',
    country: 'Canada',
    countryCode: 'CA'
  },
  {
    label: 'UK (United Kingdom)',
    zone: 'UK',
    country: 'United Kingdom',
    countryCode: 'GB'
  },
  {
    label: 'Greater China - Taiwan (Taiwan)',
    zone: 'Greater China - Taiwan',
    country: 'Taiwan',
    countryCode: 'TW'
  },
  {
    label: 'New Zealand (New Zealand)',
    zone: 'New Zealand',
    country: 'New Zealand',
    countryCode: 'NZ'
  },
  {
    label: 'Indonesia - Java (Indonesia)',
    zone: 'Indonesia - Java',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Indonesia - Jabodetabek (Indonesia)',
    zone: 'Indonesia - Jabodetabek',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Philippines - Greater Manila (Philippines)',
    zone: 'Philippines - Greater Manila',
    country: 'Philippines',
    countryCode: 'PH'
  },
  {
    label: 'Europe (1) (Germany)',
    zone: 'Europe (1)',
    country: 'Germany',
    countryCode: 'DE'
  },
  {
    label: 'Greater China - Hong Kong (Hong Kong)',
    zone: 'Greater China - Hong Kong',
    country: 'Hong Kong',
    countryCode: 'HK'
  },
  {
    label: 'Middle East (Turkey)',
    zone: 'Middle East',
    country: 'Turkey',
    countryCode: 'TR'
  },
  {
    label: 'Greater China - South China (China)',
    zone: 'Greater China - South China',
    country: 'China',
    countryCode: 'CN'
  },
  {
    label: 'Vietnam - Da Nang (Vietnam)',
    zone: 'Vietnam - Da Nang',
    country: 'Vietnam',
    countryCode: 'VN'
  },
  {
    label: 'USA (United States)',
    zone: 'USA',
    country: 'United States',
    countryCode: 'US'
  },
  {
    label: 'Indonesia NBD Destination (Indonesia)',
    zone: 'Indonesia NBD Destination',
    country: 'Indonesia',
    countryCode: 'ID'
  },
  {
    label: 'Vietnam - Hanoi (Vietnam)',
    zone: 'Vietnam - Hanoi',
    country: 'Vietnam',
    countryCode: 'VN'
  },
  {
    label: 'Canada (Canada)',
    zone: 'Canada',
    country: 'Canada',
    countryCode: 'CA'
  },
  {
    label: 'Malaysia - Klang Valley (Malaysia)',
    zone: 'Malaysia - Klang Valley',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Malaysia - Peninsular (Malaysia)',
    zone: 'Malaysia - Peninsular',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Malaysia - Sabah (Malaysia)',
    zone: 'Malaysia - Sabah',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Malaysia - Sarawak (Malaysia)',
    zone: 'Malaysia - Sarawak',
    country: 'Malaysia',
    countryCode: 'MY'
  },
  {
    label: 'Philippines - Luzon (Philippines)',
    zone: 'Philippines - Luzon',
    country: 'Philippines',
    countryCode: 'PH'
  },
  {
    label: 'Philippines - Mindanao (Philippines)',
    zone: 'Philippines - Mindanao',
    country: 'Philippines',
    countryCode: 'PH'
  },
  {
    label: 'Philippines - Visayas (Philippines)',
    zone: 'Philippines - Visayas',
    country: 'Philippines',
    countryCode: 'PH'
  },
  {
    label: 'Thailand - Greater Bangkok (Thailand)',
    zone: 'Thailand - Greater Bangkok',
    country: 'Thailand',
    countryCode: 'TH'
  },
  {
    label: 'Vietnam - Ho Chi Minh (Vietnam)',
    zone: 'Vietnam - Ho Chi Minh',
    country: 'Vietnam',
    countryCode: 'VN'
  },
  {
    label: 'Greater China - North China (China)',
    zone: 'Greater China - North China',
    country: 'China',
    countryCode: 'CN'
  },
  {
    label: 'Greater China - Taiwan (Offshore) (Taiwan)',
    zone: 'Greater China - Taiwan (Offshore)',
    country: 'Taiwan',
    countryCode: 'TW'
  },
  {
    label: 'South America (United Kingdom)',
    zone: 'South America',
    country: 'United Kingdom',
    countryCode: 'GB'
  },
  {
    label: 'South America (Suriname)',
    zone: 'South America',
    country: 'Suriname',
    countryCode: 'SR'
  },
  {
    label: 'South America (Uruguay)',
    zone: 'South America',
    country: 'Uruguay',
    countryCode: 'UY'
  },
  {
    label: 'South America (Venezuela)',
    zone: 'South America',
    country: 'Venezuela',
    countryCode: 'VE'
  },
  {
    label: 'South America (Argentina)',
    zone: 'South America',
    country: 'Argentina',
    countryCode: 'AR'
  },
  {
    label: 'South America (Bolivia)',
    zone: 'South America',
    country: 'Bolivia',
    countryCode: 'BO'
  },
  {
    label: 'South America (Brazil)',
    zone: 'South America',
    country: 'Brazil',
    countryCode: 'BR'
  },
  {
    label: 'South America (Chile)',
    zone: 'South America',
    country: 'Chile',
    countryCode: 'CL'
  },
  {
    label: 'South America (Colombia)',
    zone: 'South America',
    country: 'Colombia',
    countryCode: 'CO'
  },
  {
    label: 'South America (Ecuador)',
    zone: 'South America',
    country: 'Ecuador',
    countryCode: 'EC'
  },
  {
    label: 'South America (French Guiana)',
    zone: 'South America',
    country: 'French Guiana',
    countryCode: 'GF'
  },
  {
    label: 'South America (Guyana)',
    zone: 'South America',
    country: 'Guyana',
    countryCode: 'GY'
  },
  {
    label: 'South America (Paraguay)',
    zone: 'South America',
    country: 'Paraguay',
    countryCode: 'PY'
  },
  {
    label: 'South America (Peru)',
    zone: 'South America',
    country: 'Peru',
    countryCode: 'PE'
  },
  {
    label: 'Middle East (Afghanistan)',
    zone: 'Middle East',
    country: 'Afghanistan',
    countryCode: 'AF'
  },
  {
    label: 'Middle East (Azerbaijan)',
    zone: 'Middle East',
    country: 'Azerbaijan',
    countryCode: 'AZ'
  },
  {
    label: 'Middle East (Bahrain)',
    zone: 'Middle East',
    country: 'Bahrain',
    countryCode: 'BH'
  },
  {
    label: 'Middle East (Egypt)',
    zone: 'Middle East',
    country: 'Egypt',
    countryCode: 'EG'
  },
  {
    label: 'Middle East (Georgia)',
    zone: 'Middle East',
    country: 'Georgia',
    countryCode: 'GE'
  },
  {
    label: 'Middle East (Israel)',
    zone: 'Middle East',
    country: 'Israel',
    countryCode: 'IL'
  },
  {
    label: 'Middle East (Jordan)',
    zone: 'Middle East',
    country: 'Jordan',
    countryCode: 'JO'
  },
  {
    label: 'Middle East (Kazakhstan)',
    zone: 'Middle East',
    country: 'Kazakhstan',
    countryCode: 'KZ'
  },
  {
    label: 'Middle East (Kuwait)',
    zone: 'Middle East',
    country: 'Kuwait',
    countryCode: 'KW'
  },
  {
    label: 'Middle East (Libya)',
    zone: 'Middle East',
    country: 'Libya',
    countryCode: 'LY'
  },
  {
    label: 'Middle East (Oman)',
    zone: 'Middle East',
    country: 'Oman',
    countryCode: 'OM'
  },
  {
    label: 'Middle East (Qatar)',
    zone: 'Middle East',
    country: 'Qatar',
    countryCode: 'QA'
  },
  {
    label: 'Middle East (Saudi Arabia)',
    zone: 'Middle East',
    country: 'Saudi Arabia',
    countryCode: 'SA'
  },
  {
    label: 'Middle East (Turkmenistan)',
    zone: 'Middle East',
    country: 'Turkmenistan',
    countryCode: 'TM'
  },
  {
    label: 'Middle East (UAE)',
    zone: 'Middle East',
    country: 'UAE',
    countryCode: 'AE'
  },
  {
    label: 'Europe (1) (Belgium)',
    zone: 'Europe (1)',
    country: 'Belgium',
    countryCode: 'BE'
  },
  {
    label: 'Europe (1) (Bulgaria)',
    zone: 'Europe (1)',
    country: 'Bulgaria',
    countryCode: 'BG'
  },
  {
    label: 'Europe (1) (Croatia)',
    zone: 'Europe (1)',
    country: 'Croatia',
    countryCode: 'HR'
  },
  {
    label: 'Europe (1) (Cyprus)',
    zone: 'Europe (1)',
    country: 'Cyprus',
    countryCode: 'CY'
  },
  {
    label: 'Europe (1) (Czech Republic)',
    zone: 'Europe (1)',
    country: 'Czech Republic',
    countryCode: 'CZ'
  },
  {
    label: 'Europe (1) (Denmark)',
    zone: 'Europe (1)',
    country: 'Denmark',
    countryCode: 'DK'
  },
  {
    label: 'Europe (1) (Estonia)',
    zone: 'Europe (1)',
    country: 'Estonia',
    countryCode: 'EE'
  },
  {
    label: 'Europe (1) (Finland)',
    zone: 'Europe (1)',
    country: 'Finland',
    countryCode: 'FI'
  },
  {
    label: 'Europe (1) (France)',
    zone: 'Europe (1)',
    country: 'France',
    countryCode: 'FR'
  },
  {
    label: 'Europe (1) (Greece)',
    zone: 'Europe (1)',
    country: 'Greece',
    countryCode: 'GR'
  },
  {
    label: 'Europe (1) (Hungary)',
    zone: 'Europe (1)',
    country: 'Hungary',
    countryCode: 'HU'
  },
  {
    label: 'Europe (1) (Ireland)',
    zone: 'Europe (1)',
    country: 'Ireland',
    countryCode: 'IE'
  },
  {
    label: 'Europe (1) (Italy)',
    zone: 'Europe (1)',
    country: 'Italy',
    countryCode: 'IT'
  },
  {
    label: 'Europe (1) (Latvia)',
    zone: 'Europe (1)',
    country: 'Latvia',
    countryCode: 'LV'
  },
  {
    label: 'Europe (1) (Liechtenstein)',
    zone: 'Europe (1)',
    country: 'Liechtenstein',
    countryCode: 'LI'
  },
  {
    label: 'Europe (1) (Lithuania)',
    zone: 'Europe (1)',
    country: 'Lithuania',
    countryCode: 'LT'
  },
  {
    label: 'Europe (1) (Luxembourg)',
    zone: 'Europe (1)',
    country: 'Luxembourg',
    countryCode: 'LU'
  },
  {
    label: 'Europe (1) (Malta)',
    zone: 'Europe (1)',
    country: 'Malta',
    countryCode: 'MT'
  },
  {
    label: 'Europe (1) (Monaco)',
    zone: 'Europe (1)',
    country: 'Monaco',
    countryCode: 'MC'
  },
  {
    label: 'Europe (1) (Netherlands)',
    zone: 'Europe (1)',
    country: 'Netherlands',
    countryCode: 'NL'
  },
  {
    label: 'Europe (1) (Norway)',
    zone: 'Europe (1)',
    country: 'Norway',
    countryCode: 'NO'
  },
  {
    label: 'Europe (1) (Poland)',
    zone: 'Europe (1)',
    country: 'Poland',
    countryCode: 'PL'
  },
  {
    label: 'Europe (1) (Portugal)',
    zone: 'Europe (1)',
    country: 'Portugal',
    countryCode: 'PT'
  },
  {
    label: 'Europe (1) (Romania)',
    zone: 'Europe (1)',
    country: 'Romania',
    countryCode: 'RO'
  },
  {
    label: 'Europe (1) (Slovenia)',
    zone: 'Europe (1)',
    country: 'Slovenia',
    countryCode: 'SI'
  },
  {
    label: 'Europe (1) (Slovakia)',
    zone: 'Europe (1)',
    country: 'Slovakia',
    countryCode: 'SK'
  },
  {
    label: 'Europe (1) (Spain)',
    zone: 'Europe (1)',
    country: 'Spain',
    countryCode: 'ES'
  },
  {
    label: 'Europe (1) (Sweden)',
    zone: 'Europe (1)',
    country: 'Sweden',
    countryCode: 'SE'
  },
  {
    label: 'Europe (1) (Switzerland)',
    zone: 'Europe (1)',
    country: 'Switzerland',
    countryCode: 'CH'
  },
  {
    label: 'Europe (2) (Moldova)',
    zone: 'Europe (2)',
    country: 'Moldova',
    countryCode: 'MD'
  },
  {
    label: 'Europe (2) (Montenegro)',
    zone: 'Europe (2)',
    country: 'Montenegro',
    countryCode: 'ME'
  },
  {
    label: 'Europe (2) (Serbia)',
    zone: 'Europe (2)',
    country: 'Serbia',
    countryCode: 'RS'
  },
  {
    label: 'Europe (2) (Albania)',
    zone: 'Europe (2)',
    country: 'Albania',
    countryCode: 'AL'
  },
  {
    label: 'Europe (2) (Andorra)',
    zone: 'Europe (2)',
    country: 'Andorra',
    countryCode: 'AD'
  },
  {
    label: 'Europe (2) (Armenia)',
    zone: 'Europe (2)',
    country: 'Armenia',
    countryCode: 'AM'
  },
  {
    label: 'Europe (2) (Bosnia and Herzegovina)',
    zone: 'Europe (2)',
    country: 'Bosnia and Herzegovina',
    countryCode: 'BA'
  },
  {
    label: 'Europe (2) (Spain)',
    zone: 'Europe (2)',
    country: 'Spain',
    countryCode: 'ES'
  },
  {
    label: 'Europe (2) (Falkland Islands)',
    zone: 'Europe (2)',
    country: 'Falkland Islands',
    countryCode: 'FK'
  },
  {
    label: 'Europe (2) (Faroe Islands)',
    zone: 'Europe (2)',
    country: 'Faroe Islands',
    countryCode: 'FO'
  },
  {
    label: 'Europe (2) (United Kingdom)',
    zone: 'Europe (2)',
    country: 'United Kingdom',
    countryCode: 'GB'
  },
  {
    label: 'Europe (2) (Greenland)',
    zone: 'Europe (2)',
    country: 'Greenland',
    countryCode: 'GL'
  },
  {
    label: 'Europe (2) (Guernsey)',
    zone: 'Europe (2)',
    country: 'Guernsey',
    countryCode: 'GG'
  },
  {
    label: 'Europe (2) (Jersey)',
    zone: 'Europe (2)',
    country: 'Jersey',
    countryCode: 'JE'
  },
  {
    label: 'Europe (2) (Iceland)',
    zone: 'Europe (2)',
    country: 'Iceland',
    countryCode: 'IS'
  },
  {
    label: 'Europe (2) (Kosovo)',
    zone: 'Europe (2)',
    country: 'Kosovo',
    countryCode: 'XK'
  },
  {
    label: 'Europe (2) (Macedonia)',
    zone: 'Europe (2)',
    country: 'Macedonia',
    countryCode: 'MK'
  },
  {
    label: 'Australia (Australia)',
    zone: 'Australia',
    country: 'Australia',
    countryCode: 'AU'
  }
];

export default DESTINATION_ZONES;

import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Dropdown, Input, SearchInput } from '../../../components';
import { JIRA_STATUSES, PRIORITIES } from '../../../constants';
import { resetFilters, setFilters } from '../tickets.action';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  user-select: none;
  width: 1000px;
  height: 270px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 5px 8px !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdown = ({ label, loading: isLoading, onFilter = () => {} }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();

  const status = useSelector((state) => state?.tickets?.filters?.status);
  const issueKey = useSelector((state) => state?.tickets?.filters?.issueKey);
  const summary = useSelector((state) => state?.tickets?.filters?.summary);
  const priority = useSelector((state) => state?.tickets?.filters?.priority);
  const created = useSelector((state) =>
    state?.tickets?.filters?.created
      ? state?.tickets?.filters?.created?.map((d) => moment(d)) || []
      : []
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };

  const handleFilter = () => {
    onFilter({
      status,
      issueKey,
      summary,
      priority,
      created
    });
    setVisible(false);
  };

  const handleSearchInputChange = (field) => (_, option) => {
    dispatch(setFilters({ [field]: option?.props?.value }));
  };

  const handleOnChange = (field) => (e) => {
    dispatch(setFilters({ [field]: e.target.value.trim() }));
  };

  const handleRangeDatePickerChange = (field) => (value) => {
    dispatch(
      setFilters({
        [field]: value?.length
          ? [value[0]?.startOf('day').toISOString(), value[1]?.endOf('day').toISOString()]
          : []
      })
    );
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Filter'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]} className="field-row">
            <Col span={8} className="field-container">
              <label htmlFor="tracking-number">Ticket No.</label>
              <Input
                id="tracking-number"
                placeholder="Type here"
                value={issueKey}
                onChange={handleOnChange('issueKey')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="summary">Summary</label>
              <Input
                id="summary"
                placeholder="Type here"
                value={summary}
                onChange={handleOnChange('summary')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="status">Status</label>
              <SearchInput
                placeholder="Type or select..."
                id="status"
                items={JIRA_STATUSES}
                value={status}
                onChange={handleSearchInputChange('status')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="priority">Priority</label>
              <SearchInput
                allowClear
                placeholder="Type or select..."
                id="priority"
                items={[
                  { value: PRIORITIES.HIGHEST, key: 1, label: 'Highest' },
                  { value: PRIORITIES.HIGH, key: 2, label: 'High' },
                  { value: PRIORITIES.MEDIUM, key: 3, label: 'Medium' },
                  { value: PRIORITIES.LOW, key: 4, label: 'Low' },
                  { value: PRIORITIES.LOWEST, key: 5, label: 'Lowest' }
                ]}
                value={priority}
                onChange={handleSearchInputChange('priority')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="from-date">Created On</label>
              <RangePicker
                id="created"
                value={created}
                onChange={handleRangeDatePickerChange('created')}
                allowClear={true}
                contained
              />
            </Col>
          </Row>
          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              onClick={handleReset}
              disabled={isLoading}
            >
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleFilter} disabled={isLoading}>
              Filter
            </Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdown;

import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import ImgBackground from '../../assets/images/login-background.png';
import ImgLogo from '../../assets/images/logo.png';
import { isLoggedIn, login } from '../../utils/auth';
import { Button, Input, LoginForm, Message } from '../../components';
import {
  TEXT_MISS_EMAIL,
  TEXT_MISS_PASSWORD,
  TEXT_NOT_MATCH,
  TEXT_WRONG_FORMAT_EMAIL
} from '../../constants/messages';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => navigate(isLoggedIn() ? '/track-orders' : '/login'), [navigate]);

  const validateForm = () => {
    let isOk = true;
    if (validator.isEmpty(email)) {
      setEmailError(TEXT_MISS_EMAIL);
      isOk = false;
    } else if (!validator.isEmail(email)) {
      setEmailError(TEXT_WRONG_FORMAT_EMAIL);
      isOk = false;
    }
    if (validator.isEmpty(password)) {
      setPasswordError(TEXT_MISS_PASSWORD);
      isOk = false;
    }
    return isOk;
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      login(email, password)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setEmailError(TEXT_NOT_MATCH);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  return (
    <LoginForm>
      <img className="login-background" alt="backgound" src={ImgBackground} />
      <div className="login-overlay" />
      <form id="loginForm" onSubmit={handleSubmitLogin}>
        <img alt="logo" src={ImgLogo} />
        <div className="form">
          <Row>
            <h2>JANIO FINANCE LOGIN</h2>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row className="email-address-heading">
            <p>Email Address</p>
          </Row>
          <Row className={emailError ? 'input--error' : ''}>
            <Input type="text" onChange={(e) => onChangeEmail(e)} size="large" timeout={100} />
          </Row>
          <Message type="error">{emailError}</Message>
          <Row className="password-heading">
            <p>Password</p>
          </Row>
          <Row className={passwordError ? 'input--error' : ''}>
            <Input
              type="password"
              onChange={(e) => onChangePassword(e)}
              size="large"
              timeout={100}
            />
          </Row>
          <Message type="error">{passwordError}</Message>
          <Row>
            <span
              className="under-line forgot-password"
              aria-hidden
              onClick={() => navigate('/forget-password')}
            >
              Forgot password?
            </span>
          </Row>
          <Button
            size="large"
            loading={loading}
            type="primary"
            form="loginForm"
            key="submit"
            htmlType="submit"
            block
          >
            LOGIN
          </Button>
        </div>
      </form>
    </LoginForm>
  );
};

export default Login;

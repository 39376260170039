import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { getProfile, logout } from '../../utils/auth';
import HeaderDropdown from './HeaderDropdown';

const StyledHeaderDropdown = styled(HeaderDropdown)`
  .link {
    cursor: pointer;
  }
`;

const ProfileDropdown = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const profile = getProfile();
    setUser(profile);
  }, []);

  return (
    <StyledHeaderDropdown>
      <li className="name bold">{user?.fullname}</li>
      <li className="email spacer-25">{user?.email}</li>
      <li className="link" aria-hidden onClick={(e) => logout(e)}>
        <span>Logout</span>
      </li>
    </StyledHeaderDropdown>
  );
};

export default ProfileDropdown;

import { createAction } from '@reduxjs/toolkit';
import service from './pricing-group-manage.service';

export const fetchPricingGroups = createAction('pricingGroupManagement/FETCH_DATA');
export const fetchPricingGroupsLoading = createAction('pricingGroupManagement/FETCH_DATA_LOADING');

export const setFilters = createAction('pricingGroupManagementFilter/SET_FILTERS');
export const resetFilters = createAction('pricingGroupManagementFilter/RESET_FILTERS');

export const getPricingGroups =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchPricingGroupsLoading(true));
    service
      .getPricingGroups(params)
      .then((data) => {
        dispatch(fetchPricingGroups(data));
      })
      .catch(() => {
        dispatch(fetchPricingGroupsLoading(false));
      });
  };

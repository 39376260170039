import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getProfile, isLoggedIn } from '../utils/auth';
import Header from './Header';
import SideDrawer from './SideDrawer';

const Main = styled.div`
  padding-top: 60px;
  padding-bottom: 5px;
`;

const PrivateOutlet = () => {
  const location = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // here to filter which user able to access specific page
  const handleOutlet = () => {
    const { roles } = getProfile();
    const isAdmin = roles.length && true;

    if (!isAdmin) {
      switch (location.pathname) {
        case '/clients':
        case '/pricing-groups/manage':
          return <Navigate to="/not-found" state={{ from: location }} />;
        default:
          return <Outlet />;
      }
    }

    return <Outlet />;
  };

  return (
    <Main>
      <Header
        onToggle={() => {
          setShowDrawer(true);
        }}
      />
      <SideDrawer
        visible={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
      />
      {handleOutlet()}
    </Main>
  );
};

export default PrivateOutlet;

import { CloseOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, Modal, Row, Spin, Tooltip, Upload, notification } from 'antd';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, ErrorMessage, Select, TextArea } from '../../../components';
import { PRICING_GROUP_TYPES } from '../../../constants';
import MESSAGES from '../../../constants/messages';
import { getPricingGroups } from '../pricing-group-manage.action';
import service from '../pricing-group-manage.service';

const StyledDiv = styled.div`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  .info {
    font-family: AvenirLTStdBook;
    color: #707070;
  }
`;

const ItemDiv = styled.div`
  margin-bottom: 30px;
`;

const initialState = {
  name: '',
  type: PRICING_GROUP_TYPES.CUSTOM,
  startDate: undefined,
  endDate: undefined,
  file: null
};

const AddNewPricingGroupModal = ({ visible = false, setVisible = () => {} }) => {
  const dispatch = useDispatch();
  const [filename, setFilename] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);
  const [disabledDate, setDisabledDate] = useState(moment().subtract(1, 'days'));
  const [{ name, type, startDate, endDate, file }, setState] = useState(initialState);

  const handleDisabledDate = (date, allowPastDate) => (current) => {
    if (allowPastDate) {
      return current && current < moment().subtract(12, 'months').endOf('day');
    }

    if (current > date) {
      return current && current < moment().subtract(1, 'days').endOf('day');
    }

    return current && current < date.endOf('day');
  };

  const handleText = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleValue = (name) => (value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'startDate') {
      const startDate = value < moment() ? moment() : value;
      setDisabledDate(startDate);
    }
  };

  const handleFile = (newFile) => {
    setState((prevState) => ({ ...prevState, file: newFile }));
    setFilename(newFile.name);
    return false;
  };

  const handleDisabledSubmit = () => {
    if (!startDate) {
      return true;
    }

    if (!file) {
      return true;
    }

    if (type === PRICING_GROUP_TYPES.CUSTOM && !name.trim()) {
      return true;
    }

    return false;
  };

  const generatePublishedName = (startDate) => {
    return `${startDate.format('YYYY')}_${PRICING_GROUP_TYPES.PUBLISHED}_${startDate.format(
      'MMM'
    )}_Rates`;
  };

  const handleOk = () => {
    const key = `add-pricing-group-${Date.now()}`;
    setLoading(true);

    const body = {
      type,
      start_date: startDate.format('YYYY-MM-DDT00:00:00') + 'Z',
      end_date: endDate ? endDate.format('YYYY-MM-DDT23:59:59') + 'Z' : null,
      file
    };

    if (type === PRICING_GROUP_TYPES.CUSTOM) {
      body.name = name.trim();
    }

    service
      .addPricingGroup(body)
      .then(() => {
        setLoading(false);
        setVisible(false);
        dispatch(getPricingGroups());
        const name = body.name || generatePublishedName(startDate);
        notification.success({
          key,
          message: `You have added a new draft pricing group "${name}"`,
          description: `You may review it, edit, confirm or delete it.`,
          placement: 'bottomLeft'
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (err.result) {
          setErrorMessage(err.result);
          setErrorVisible(true);
        } else {
          notification.error({
            key,
            message: `Could not add the pricing group`,
            description: MESSAGES.M0001,
            placement: 'bottomLeft',
            duration: 0
          });
        }
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const afterClose = () => {
    setState({ ...initialState });
  };

  return (
    <Fragment>
      <ErrorMessage message={errorMessage} visible={errorVisible} setVisible={setErrorVisible} />
      <Modal
        title="Add new draft"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={820}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Tooltip key="back" title="Your changes won't be saved">
            <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
              Cancel
            </Button>
          </Tooltip>,
          <Button
            key="submit"
            loading={loading}
            disabled={handleDisabledSubmit()}
            onClick={handleOk}
          >
            Submit
          </Button>
        ]}
      >
        <Spin spinning={loading}>
          <StyledDiv>
            <ItemDiv>
              <label htmlFor="type">Type</label>
              <Select disabled name="type" value={type} onChange={handleValue('type')}>
                {/* <Select.Option value={PRICING_GROUP_TYPES.PUBLISHED}>Published</Select.Option> */}
                <Select.Option value={PRICING_GROUP_TYPES.CUSTOM}>Custom</Select.Option>
              </Select>
            </ItemDiv>

            {type === PRICING_GROUP_TYPES.CUSTOM && (
              <ItemDiv>
                <label htmlFor="name">Pricing Group Name</label>
                <TextArea
                  name="name"
                  value={name}
                  maxLength={255}
                  onChange={handleText}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </ItemDiv>
            )}

            <ItemDiv>
              <label htmlFor="start-date">Start Date</label>
              <DatePicker
                id="start-date"
                value={startDate}
                allowClear={false}
                onChange={handleValue('startDate')}
                disabledDate={handleDisabledDate(disabledDate, true)}
              />
            </ItemDiv>

            {/* <ItemDiv>
            <label htmlFor="end-date">
              End Date <span className="info">(Optional)</span>
            </label>
            <DatePicker
              id="end-date"
              value={endDate}
              allowClear
              onChange={handleValue('endDate')}
              disabledDate={handleDisabledDate(startDate, false)}
              disabled={!startDate ? true : false}
            />
          </ItemDiv> */}

            <ItemDiv>
              <Row gutter={16}>
                <Col>
                  Attachment{' '}
                  <Tooltip placement="right" title="Only accept .csv file">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
                <Col style={{ marginLeft: 'auto' }}>
                  <Upload
                    style={{ width: 250 }}
                    accept=".csv"
                    showUploadList={false}
                    beforeUpload={handleFile}
                  >
                    <Button
                      type="secondary"
                      style={{ width: 'auto' }}
                      icon={filename ? <CloseOutlined /> : <UploadOutlined />}
                    >
                      {filename || 'Upload'}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </ItemDiv>
          </StyledDiv>
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default AddNewPricingGroupModal;

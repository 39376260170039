/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Col, Modal, Row, Spin, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import SampleCsv from '../../assets/draft_pricing_group_sample.csv';
import { ActionLink, Button, Card, SelectDropdown, Table } from '../../components';
import { PRICING_GROUP_TYPES } from '../../constants';
import MESSAGES from '../../constants/messages';
import { getFullname } from '../../utils/formatters';
import { getPricingGroups } from './pricing-group-manage.action';
import service from './pricing-group-manage.service';
import { AddNewPricingGroupModal, EditPricingGroupModal, FilterDropdown } from './shared';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const PricingGroupManage = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [approveVisible, setApproveVisible] = useState(false);
  const [editPricingGroup, setEditPricingGroup] = useState({});
  const [selectedPricingGroup, setSelectedPricingGroup] = useState('');

  const { data, total, loading: tableLoading } = useSelector((state) => state.pricingGroupManage);

  const columns = [
    {
      title: 'Draft Pricing Group',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (text, record) => {
        if (record.type === PRICING_GROUP_TYPES.CUSTOM) {
          return <ActionLink to={`/pricing-groups/manage/${text}`}>{text}</ActionLink>;
        }

        return text;
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text) => moment.utc(text).format('DD MMM YYYY')
    },
    // {
    //   title: 'End Date',
    //   dataIndex: 'end_date',
    //   key: 'end_date',
    //   render: (text) => (text ? moment.utc(text).format('DD MMM YYYY') : '-')
    // },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (text) => getFullname(text)
    },
    {
      title: 'Actions',
      dataIndex: 'name',
      key: 'action',
      fixed: 'right',
      width: 220,
      render: (text, record) => {
        return (
          <>
            <Button
              size="small"
              type="secondary"
              onClick={() => handleEdit(record)}
              style={{ marginRight: '5px' }}
            >
              Edit
            </Button>
            <Button
              size="small"
              disabled={loading}
              style={{ marginRight: '5px' }}
              onClick={() => {
                setSelectedPricingGroup(text);
                setApproveVisible(true);
              }}
            >
              Confirm
            </Button>
            <Button
              size="small"
              className="ant-btn-dangerous"
              disabled={loading}
              onClick={() => {
                setSelectedPricingGroup(text);
                setDeleteVisible(true);
              }}
            >
              Delete
            </Button>
          </>
        );
      }
    }
  ];

  const handleCancel = () => {
    setApproveVisible(false);
    setDeleteVisible(false);
  };

  const afterClose = () => {
    setSelectedPricingGroup('');
  };

  const handleAdd = () => {
    setAddVisible(true);
  };

  const handleEdit = (pg) => {
    setEditPricingGroup(pg);
    setEditVisible(true);
  };

  const handleApprove = () => {
    const key = `approve-pricing-group-${new Date().getTime()}`;
    setLoading(true);

    service
      .approvePricingGroup(selectedPricingGroup)
      .then(() => {
        setLoading(false);
        setApproveVisible(false);
        handleFilter(filters, page);
        const uriEncodedPath = encodeURIComponent(selectedPricingGroup);

        const btn = (
          <Button
            type="link"
            href={`/pricing-groups/${uriEncodedPath}`}
            target="_blank"
            onClick={() => notification.close(key)}
          >
            View pricing group
          </Button>
        );

        notification.success({
          key,
          btn,
          message: `You have confirmed "${selectedPricingGroup}"`,
          description: `Click the link to view the pricing group.`,
          placement: 'bottomLeft',
          duration: 0
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        notification.error({
          key,
          message: `Could not confirm the pricing group`,
          description: err.result || MESSAGES.M0001,
          placement: 'bottomLeft',
          duration: 0
        });
      });
  };

  const handleDelete = () => {
    const key = `reject-pricing-group-${new Date().getTime()}`;
    setLoading(true);

    service
      .rejectPricingGroup(selectedPricingGroup)
      .then(() => {
        setLoading(false);
        setDeleteVisible(false);
        handleFilter(filters, page);
        notification.success({
          key,
          message: `You have deleted "${selectedPricingGroup}"`,
          description: `You can add new one or review other.`,
          placement: 'bottomLeft'
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        notification.error({
          key,
          message: `Could not delete the pricing group`,
          description: err.result || MESSAGES.M0001,
          placement: 'bottomLeft',
          duration: 0
        });
      });
  };

  const handleFilter = (filters, page = 1, size = 50) => {
    setPage(page);
    setSize(size);
    setFilters(filters);

    const recordFilters = { page, size, ...filters };
    dispatch(getPricingGroups(recordFilters));
  };

  useEffect(() => {
    const recordFilters = {
      page,
      size
    };

    dispatch(getPricingGroups(recordFilters));
  }, [dispatch, page, size]);

  return (
    <div>
      <AddNewPricingGroupModal
        key={new Date().getTime()}
        visible={addVisible}
        setVisible={setAddVisible}
      />
      <EditPricingGroupModal
        key={new Date().getTime() + 1}
        data={editPricingGroup}
        visible={editVisible}
        setVisible={setEditVisible}
      />
      <StyledCard>
        <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
          <Col>
            <FilterDropdown
              onFilter={(filters) => handleFilter(filters, page, size)}
              loading={loading}
            />
          </Col>
          <Col>
            <SelectDropdown
              value={size}
              disabled={tableLoading}
              items={[
                { label: '50 items/page', value: 50 },
                { label: '100 items/page', value: 100 },
                { label: '500 items/page', value: 500 }
              ]}
              onChange={(size) => handleFilter(filters, 1, size)}
            />
          </Col>
          <Col sx={{ ml: 'auto' }}>
            <Col style={{ marginLeft: 'auto' }}>
              <Button
                href={SampleCsv}
                type="secondary"
                download="draft_pricing_group_sample"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: '5px' }}
              >
                Download Sample
              </Button>
              <Button onClick={handleAdd}>Add New Draft</Button>
            </Col>
          </Col>
        </Row>
        <Row>
          <Table
            loading={tableLoading}
            rowKey={(row) => `${row.key}-${row.name}`}
            columns={columns}
            renderEmptyTable
            dataSource={data}
            pageSize={size}
            currentPage={page}
            totalCount={total}
            onChange={(page) => handleFilter(filters, page)}
            scroll={{ x: 1300 }}
            expandable={{
              rowExpandable: (row) => row.type === PRICING_GROUP_TYPES.PUBLISHED,
              expandedRowRender: (row) =>
                row.records.map((name, i) => (
                  <div key={`${name}_${i}`}>
                    <ActionLink key={i} to={`/pricing-groups/manage/${name}`}>
                      {name}
                    </ActionLink>
                  </div>
                ))
            }}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No data found.</span>&nbsp;
                  </div>
                )}
                {loading && (
                  <AntSkeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
          />
        </Row>
      </StyledCard>

      <Modal
        title="Confirm?"
        visible={approveVisible}
        onOk={handleApprove}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
            No
          </Button>,
          <Button key="submit" loading={loading} onClick={handleApprove}>
            Yes
          </Button>
        ]}
      >
        <Spin spinning={loading}>
          <p>
            This pricing group will be added to our database and you will be able to map it to a
            client. Are you sure you want to confirm this <strong>{selectedPricingGroup}</strong>?
          </p>
        </Spin>
      </Modal>
      <Modal
        title="Delete"
        visible={deleteVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
            No
          </Button>,
          <Button key="submit" loading={loading} onClick={handleDelete}>
            Yes
          </Button>
        ]}
      >
        <Spin spinning={loading}>
          <p>
            Are you sure want to delete <strong>{selectedPricingGroup}</strong>? This action is
            irreversible and you may need to add this pricing group again.
          </p>
        </Spin>
      </Modal>
    </div>
  );
};
export default PricingGroupManage;

import styled from '@emotion/styled';
import { DatePicker as AntDatePicker } from 'antd';
import React, { useRef } from 'react';

const { RangePicker: AntRangePicker } = AntDatePicker;

const StyledCalendar = styled.div`
  // General CSS settings
  .ant-picker {
    font-family: AvenirLTStdBook;
    display: flex;

    &.ant-picker-error {
      border-color: #ff4d4f !important;
    }
  }

  .ant-picker:hover:not(.ant-picker-disabled),
  .ant-picker-focused {
    border-color: #050593;
  }
  .ant-picker-focused {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);

    &.ant-picker-error {
      box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.13) !important;
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #050593;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #050593;
  }

  // DatePicker CSS settings
  .ant-picker-today-btn {
    color: #050593;
  }
  .ant-picker-header-view button:hover {
    color: #050593;
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: #c0c0f0;
  }

  // RangePicker CSS settings
  .ant-picker-range .ant-picker-active-bar {
    background: #050593;
  }
  .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    opacity: 0.5;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #050593;
    border-bottom: 1px dashed #050593;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #c0c0f0;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: #c0c0f0;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #f0f0ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #f0f0ff;
  }
`;

const DatePicker = (props) => {
  const node = useRef();

  return (
    <StyledCalendar ref={node}>
      <AntDatePicker {...props} getPopupContainer={() => node.current} />
    </StyledCalendar>
  );
};

const RangePicker = (props) => {
  const node = useRef();

  return (
    <StyledCalendar ref={node}>
      <AntRangePicker {...props} getPopupContainer={() => node.current} />
    </StyledCalendar>
  );
};

DatePicker.RangePicker = RangePicker;

export default DatePicker;

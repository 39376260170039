import { Result } from 'antd';
import React from 'react';
import { Button } from '../../components';
import { withRouter } from '../../utils/wrappers';

const NotFound = ({ navigate }) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button onClick={() => navigate(-2)}>Back</Button>}
    />
  );
};

export default withRouter(NotFound);

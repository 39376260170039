import { getProfile } from '../../utils/auth';
import request from '../../utils/request';

export const createTicket = async ({
  summary,
  description,
  ticketType,
  priority,
  labels,
  files
}) => {
  const { fullname } = getProfile();

  const createUrl = `/pricing/jira/issue`;
  const data = {
    update: {},
    fields: {
      summary,
      description: description.concat(`\n\nReported by ${fullname}`),
      labels,
      issuetype: {
        id: ticketType
      },
      priority: {
        id: priority
      },
      project: {
        id: process.env.REACT_APP_JIRA_PROJECT_ID
      }
    }
  };

  const response = await request().post(createUrl, data);
  const ticket = response.data.data;

  if (!files.length) {
    return ticket;
  }

  const formData = new FormData();
  for (const file of files) {
    formData.append('file', file);
  }

  const attachmentUrl = `/pricing/jira/attachment/issue/${ticket.id}/attachments`;
  await request().post(attachmentUrl, formData);

  return ticket;
};

import styled from '@emotion/styled';
import { Drawer } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ImgLogoReverse from '../../assets/images/logo-sider.png';
import { getProfile } from '../../utils/auth';

const StyledDrawer = styled(Drawer)`
  img {
    width: 100px;
    margin: 5px auto 40px auto;
    display: block;
  }
  .ant-drawer-body {
    padding: 0 22px;
  }
  .section {
    h2 {
      font-size: 20px;
      font-family: AvenirLTStdHeavy;
      margin-bottom: 15px;
      line-height: 1;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 50px;
    }
    ul li a {
      font-size: 16px;
      font-family: AvenirLTStdBook;
      color: #050593;
    }
    a.disabled {
      font-size: 16px;
      font-family: AvenirLTStdBook;
      color: grey;
    }
  }
`;

const SideDrawer = ({ visible, onClose }) => {
  const { roles } = getProfile();
  const isAdmin = roles.length ? true : false;

  return (
    <StyledDrawer
      title=""
      placement="left"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={300}
    >
      <img alt="logo" src={ImgLogoReverse} />
      <div className="section">
        <h2>GENERAL</h2>
        <ul>
          <li>
            <Link to="/invoices" onClick={onClose}>
              View Invoices
            </Link>
          </li>
          <li>
            <Link to="/billing-records" onClick={onClose}>
              View Billing Records
            </Link>
          </li>
          <li>
            <Link to="/orders" onClick={onClose}>
              View Orders
            </Link>
          </li>
          <li>
            <Link to="/cost-estimation" onClick={onClose}>
              Cost Estimation
            </Link>
          </li>
          <li>
            <Link to="/surcharges" onClick={onClose}>
              Surcharges
            </Link>
          </li>
          <li>
            <Link to="/track-orders" onClick={onClose}>
              Orders
            </Link>
          </li>
          <li>
            <Link to="/pricing-groups" onClick={onClose}>
              Pricing Groups
            </Link>
          </li>
          <li>
            <Link to="/tickets" onClick={onClose}>
              Tickets
            </Link>
          </li>
          <li>
            <Link to="/reports" onClick={onClose}>
              View Reports
            </Link>
          </li>
        </ul>
      </div>

      {isAdmin && (
        <div className="section">
          <h2>ADMIN</h2>
          <ul>
            <li>
              <Link to="/clients" onClick={onClose}>
                View Clients
              </Link>
            </li>
            <li>
              <Link to="/pricing-groups/manage" onClick={onClose}>
                Create Draft Pricing Group
              </Link>
            </li>
          </ul>
        </div>
      )}
    </StyledDrawer>
  );
};

export default SideDrawer;

import { Col, message, Row } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Button, Message, TextArea } from '../../../components';
import { ROLES } from '../../../constants';
import { getProfile } from '../../../utils/auth';
import request from '../../../utils/request';
import WaiveOffModal from '../../invoice-details/shared/WaiveOffModal';
import { fetchSyncOrders, fetchSyncOrdersLoading } from '../../orders/orders.action';
import ChangeBillingCycleModal from './ChangeBillingCycleModal';

const initialValues = {
  tracking_nos: undefined
};

const validationSchema = yup.object().shape({
  tracking_nos: yup.string().nullable().required('Please enter tracking numbers.')
});

const OrdersSync = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { roles } = getProfile();
  const isAdmin = roles.includes(ROLES.FINANCE) || roles.includes(ROLES.DEV);
  const [waiveOffModalVisible, setWaiveOffModalVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [actionType, setActionType] = useState('');

  const getTrackingNumbers = (data = '') => {
    let tns = data.replace(/\n/g, ',').replace(/\s/g, '');
    tns = tns.split(',').filter((tn) => !!tn);
    if (!tns) {
      return ['', 0];
    }

    return [tns.join(), tns.length];
  };

  const onSubmitHandler = async (values, { setErrors }) => {
    const [tns, totalTns] = getTrackingNumbers(values.tracking_nos);

    if (totalTns > 100) {
      setErrors({ tracking_nos: 'You can enter max 100 tracking numbers' });
      return;
    }
    if (totalTns < 1) {
      setErrors({ tracking_nos: 'Please enter tracking numbers' });
      return;
    }
    setSubmitting(true);
    if (actionType === 'orders_status') dispatch(fetchSyncOrdersLoading(true));

    await request()
      .post(`${process.env.REACT_APP_API_URL}/pricing/${actionType}`, { tns })
      .then(({ data }) => {
        if (actionType === 'resync') {
          message.success('Orders synced successfully');
        } else if (actionType === 'recalculate') {
          message.success('Orders computed successfully');
        } else {
          let orders = [];
          if (data?.data?.length) {
            orders = data?.data;
          } else {
            setErrors({
              tracking_nos: 'No orders found corresponding to entered tracking numbers'
            });
          }
          dispatch(fetchSyncOrders(orders));
        }
      })
      .catch((error) => {
        const errorMessage =
          typeof error?.response?.data?.data === 'string'
            ? error?.response?.data?.data
            : 'An error occured while syncing tracking numbers';
        message.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
        dispatch(fetchSyncOrdersLoading(false));
      });
  };

  return (
    <div>
      <h1>Orders</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ handleSubmit, touched, errors, values, setFieldValue }) => (
          <>
            <ChangeBillingCycleModal
              data={getTrackingNumbers(values.tracking_nos)}
              visible={visible}
              setVisible={setVisible}
            />
            <Row type="flex" justify="end" style={{ marginRight: 20 }}>
              <Button
                type="primary"
                style={{ width: 200 }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Change Billing Cycle
              </Button>
              <Button
                type="primary"
                style={{ width: 150, marginLeft: 25 }}
                loading={actionType === 'resync' && isSubmitting}
                disabled={actionType === 'recalculate' && isSubmitting}
                onClick={() => {
                  setActionType('resync');
                  handleSubmit();
                }}
              >
                Resync
              </Button>
              <Button
                type="primary"
                style={{ width: 150, marginLeft: 25 }}
                loading={actionType === 'recalculate' && isSubmitting}
                disabled={actionType === 'resync' && isSubmitting}
                onClick={() => {
                  setActionType('recalculate');
                  handleSubmit();
                }}
              >
                Re Compute
              </Button>
              {isAdmin && (
                <>
                  <Button
                    style={{ width: 150, marginLeft: 25 }}
                    disabled={!values?.tracking_nos}
                    onClick={() => {
                      setWaiveOffModalVisible(true);
                    }}
                    danger
                  >
                    Waive-Off
                  </Button>
                  <WaiveOffModal
                    data={{ tns: getTrackingNumbers(values?.tracking_nos)[0] || '' }}
                    visible={waiveOffModalVisible}
                    setVisible={setWaiveOffModalVisible}
                  />
                </>
              )}
            </Row>
            <div style={{ padding: 20 }}>
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <label htmlFor="tracking_nos">
                    Tracking Numbers (you may separate the tracking numbers by commas or by directly
                    copying them from a spreadsheet column)
                  </label>
                  <TextArea
                    id="tracking_nos"
                    placeholder="Type here"
                    autoComplete="off"
                    rows={5}
                    value={values.tracking_nos}
                    onChange={(e) => {
                      setFieldValue('tracking_nos', e.target.value);
                    }}
                  />
                  {errors?.tracking_nos && touched?.tracking_nos && (
                    <Message type="error">{errors?.tracking_nos}</Message>
                  )}
                </Col>
              </Row>
              <Row>
                <Button
                  type="primary"
                  style={{ width: 150, marginTop: 10 }}
                  loading={actionType === 'orders_status' && isSubmitting}
                  onClick={() => {
                    setActionType('orders_status');
                    handleSubmit();
                  }}
                >
                  Fetch Orders
                </Button>
              </Row>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default OrdersSync;

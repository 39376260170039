import { createReducer } from '@reduxjs/toolkit';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchChoices,
  fetchChoicesLoading,
  fetchPricingGroupRules,
  fetchPricingGroupRulesLoading,
  fetchPricingGroups,
  fetchPricingGroupsLoading,
  resetFilters,
  setFilters,
  setRangeFilters
} from './pricing-group-rules.action';

const initialState = {
  data: null,
  rules: {
    data: [],
    total: 0,
    loading: false
  },
  filters: {
    currency: null,
    originZone: null,
    destinationZone: null,
    pricingType: null,
    orderType: null,
    orderDirection: null,
    weightUpperBound: { min: '', max: '' }
  },
  choices: {
    loading: false,
    currencies: [],
    originZones: [],
    destinationZones: [],
    orderTypes: []
  }
};

const pricingGroupRules = createReducer(initialState, {
  [fetchPricingGroups](state, action) {
    const { pricing_groups, total } = action.payload;
    state.data = pricing_groups;
    state.total = total;
    state.loading = false;
  },
  [fetchPricingGroupsLoading](state, action) {
    state.loading = action.payload;
  },
  [fetchPricingGroupRules](state, action) {
    const { pricing_groups, total } = action.payload;
    state.rules = {
      data: pricing_groups,
      total,
      loading: false
    };
  },
  [fetchPricingGroupRulesLoading](state, action) {
    state.rules.loading = action.payload;
  },
  [fetchChoices](state, action) {
    const { currencies, origin_zones, destination_zones, order_types } = action.payload;
    state.choices = {
      loading: false,
      currencies,
      originZones: origin_zones,
      destinationZones: destination_zones,
      orderTypes: order_types
    };
  },
  [fetchChoicesLoading](state, action) {
    state.choices.loading = action.payload;
  },
  [setRangeFilters](state, action) {
    state.filters[action.payload.field][action.payload.key] = action.payload.value;
  },
  [setFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
  }
});

const transformFilters = createTransform(
  (inboundState) => {
    // persist
    return { records: inboundState.records };
  },
  (outboundState) => {
    // hydrate
    return { ...initialState.filters, records: outboundState.records };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['filters'] }
);

export default persistReducer(
  {
    key: 'pricingGroupRules',
    storage,
    whitelist: ['filters'],
    transforms: [transformFilters]
  },
  pricingGroupRules
);

import { CreditCardTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionLink, Table } from '../../../components';
import { formatDateDisplay } from '../../../utils/formatters';
import { getInvoices } from '../../invoices/invoices.action';

const StyledInfo = styled.div`
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
  }
`;

const PendingInvoices = ({ clientID }) => {
  const size = 500;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { data, total, loading } = useSelector((state) => state.invoices.invoices);

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 170,
      render: (text, record) => (
        <React.Fragment>
          <ActionLink to={`/invoices/${record.invoiceDisplayId}`} status={record.status}>
            {record.invoiceDisplayId}
          </ActionLink>
          {record.payment_mode === 'pre-paid' && (
            <Tooltip title="Pre Paid">
              &nbsp;
              <CreditCardTwoTone />
            </Tooltip>
          )}
        </React.Fragment>
      )
    },
    {
      title: 'Created On',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text) => (text ? formatDateDisplay(text).format('DD MMM YYYY') : null)
    },
    {
      title: 'Number of Shipments',
      dataIndex: 'numberOfShipments',
      key: 'numberOfShipments'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (text ? Math.round((Number(text) + Number.EPSILON) * 100) / 100 : null)
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
    },
    {
      title: 'Billing Cycle',
      dataIndex: 'billingCycle',
      key: 'billingCycle',
      render: (text) => (text ? formatDateDisplay(text).format('DD MMM YYYY') : null)
    },
    {
      title: 'Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: () => {
        return 'Draft';
      }
    }
  ];

  useEffect(() => {
    const recordFilters = {
      clientID,
      billingStatus: 1,
      size,
      page
    };

    dispatch(getInvoices(recordFilters));
  }, [dispatch, clientID, page]);

  return (
    <StyledInfo>
      <h5>Pending Invoices</h5>
      <Table
        loading={loading}
        renderEmptyTable
        rowKey={(row) => `${row.invoiceNumber}`}
        columns={columns}
        dataSource={data}
        pageSize={size}
        currentPage={page}
        totalCount={total}
        onChange={(page) => setPage(page)}
        empty={
          <React.Fragment>
            {!loading && (
              <div>
                <span>No Data found.</span>&nbsp;
              </div>
            )}
            {loading && (
              <AntSkeleton
                active
                title={{ width: '100%' }}
                paragraph={{
                  rows: 5,
                  width: ['100%', '100%', '100%', '100%', '100%']
                }}
              />
            )}
          </React.Fragment>
        }
      />
    </StyledInfo>
  );
};

export default PendingInvoices;

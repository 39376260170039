import React from 'react';
import styled from '@emotion/styled';
import Input from '../FloatInput';

const StyledVolumnInput = styled.div`
  .ant-input-group .ant-input {
    width: 33.33%;
  }
`;

const VolumnInput = ({
  value = {
    length: null,
    width: null,
    height: null
  },
  onChange: handleChange = () => {}
}) => {
  return (
    <StyledVolumnInput>
      <span className="ant-input-group">
        <Input
          value={value.length}
          placeholder="L"
          onChange={(e) => {
            const length = {
              ...value,
              length: e.target.value
            };
            handleChange({ ...length });
          }}
        />
        <Input
          value={value.width}
          placeholder="W"
          onChange={(e) => {
            const width = {
              ...value,
              width: e.target.value
            };
            handleChange({ ...width });
          }}
        />
        <Input
          value={value.height}
          placeholder="H"
          onChange={(e) => {
            const height = {
              ...value,
              height: e.target.value
            };
            handleChange({ ...height });
          }}
        />
      </span>
    </StyledVolumnInput>
  );
};

export default VolumnInput;

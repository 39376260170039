import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Col, Row } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionLink, Button, CreateTicketModal, Table } from '../../../components';
import { convertSingaporeTimeToLocal, formatDateDisplay } from '../../../utils/formatters';

const StyledInfo = styled.div`
  padding: 20px;
`;

const OrderList = () => {
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data, loading } = useSelector((state) => state?.orders.syncOrders);

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys);
      setSelectedRows(rows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  };

  const columns = [
    {
      title: 'Tracking No.',
      dataIndex: 'tracking_no',
      key: 'tracking_no',
      fixed: 'left',
      width: 200
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: 200,
      render: (text, record) => <ActionLink to={`/clients/${record.client_id}`}>{text}</ActionLink>
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoice_ref_display_id',
      key: 'invoice_ref_display_id',
      width: 160,
      render: (text, record) => (
        <>
          {record.invoice_ref_display_id ? (
            <ActionLink to={`/invoices/${record.invoice_ref_display_id}`} status={record.status}>
              {record.invoice_ref_display_id}
            </ActionLink>
          ) : (
            '-'
          )}
        </>
      )
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      render: (text) => text || '-'
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      render: (text) => text || '-'
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      render: (text) => text || '-'
    },
    {
      title: 'Order Direction',
      dataIndex: 'order_direction',
      key: 'order_direction',
      render: (text) => text || '-'
    },
    {
      title: 'Billing Cycle',
      dataIndex: 'billing_cycle',
      key: 'billing_cycle',
      render: (text) => (text ? formatDateDisplay(text).format('DD MMM YYYY') : '-')
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
      render: (text) => (text ? convertSingaporeTimeToLocal(text).format('DD MMM YYYY') : '-')
    },
    {
      title: 'First Touch Date',
      dataIndex: 'first_touch_date',
      key: 'first_touch_date',
      render: (text) => (text ? convertSingaporeTimeToLocal(text).format('DD MMM YYYY') : '-')
    },
    {
      title: 'Pricing Group',
      dataIndex: 'pricing_group',
      key: 'pricing_group',
      render: (text) => text || '-'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ];

  const handleCreateTicket = () => {
    setVisible(true);
  };

  return (
    <div>
      <CreateTicketModal
        key={new Date().getTime()}
        data={selectedRows}
        visible={visible}
        setVisible={setVisible}
      />
      <StyledInfo>
        <Row type="flex" justify="end" style={{ marginBottom: 20 }}>
          <Col>
            <Button danger onClick={() => handleCreateTicket()}>
              Create Ticket
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            renderEmptyTable
            rowKey={(row) => `${row.tracking_no}`}
            columns={columns}
            dataSource={data}
            pageSize={500}
            showPagination={false}
            currentPage={1}
            totalCount={data.length}
            scroll={{ x: 2200 }}
            empty={
              <>
                {!loading && (
                  <div>
                    <span>No Data found.</span>&nbsp;
                  </div>
                )}
                {loading && (
                  <AntSkeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 1,
                      width: ['100%']
                    }}
                  />
                )}
              </>
            }
          />
        </Row>
      </StyledInfo>
    </div>
  );
};

export default OrderList;

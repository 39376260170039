/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { Col, Dropdown, Menu, message, Modal, Row, Skeleton as AntSkeleton } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import { ActionLink, Anchor, Button, Card, SelectDropdown, Table } from '../../components';
import { formatDateDisplay } from '../../utils/formatters';
import request from '../../utils/request';
import { withRouter } from '../../utils/wrappers';
import {
  fetchBilling,
  getChoices,
  setInvoiceFilters,
  setPageFilter
} from './billing-records.action';
import { DimWeightModal, FilterDropdownBilling } from './shared';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const statusEnum = (text) => {
  text = Number(text);
  if (text === 1) {
    return 'To Invoice';
  } else if (text === 2) {
    return 'Billed';
  } else if (text === 3) {
    return 'On Hold';
  } else if (text === 4) {
    return 'Cancelled';
  } else if (text === 5) {
    return 'Lost';
  } else if (text === 6) {
    return 'Others';
  } else if (text === 7) {
    return 'Waived';
  } else if (text === 8) {
    return 'Test';
  }
};

const BillingRecords = (props) => {
  const key = 'change-status';
  const prePayload = {
    invoiceNumber: props?.params?.invoiceId,
    originCountry: props?.params?.originCountry,
    destinationCountry: props?.params?.destinationCountry,
    billingType: props?.params?.billingType,
    unitPrice: props?.params?.unitPrice,
    weightLimit: props?.params?.weightLimit
  };
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);
  const [transitionState, setTransitionState] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [orderEditModalVisible, setOrderEditModalVisible] = useState(false);
  const [changedHeight, setChangedHeight] = useState();
  const [changedWidth, setChangedWidth] = useState();
  const [changedLength, setChangedLength] = useState();
  const [changedWeight, setChangedWeight] = useState();
  const [changePrice, setChangePrice] = useState();
  const [autoCW, setAutoCW] = useState();
  // const [notes, setNotes] = useState();

  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Tracking Number',
      dataIndex: 'trackingId',
      key: 'trackingId',
      fixed: 'left',
      width: 200,
      render: (text, record) => (
        <Anchor
          onClick={() => {
            setSelectedRecord(record);
            setOrderEditModalVisible(true);
          }}
        >
          {text}
        </Anchor>
      )
    },
    {
      title: 'Shipment Date',
      dataIndex: 'shipmentDate',
      render: (text) => formatDateDisplay(text).utc().format('DD MMM YYYY'),
      key: 'shipmentDate'
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
      width: 200,
      render: (text, record) => <ActionLink to={`/clients/${record.client_id}`}>{text}</ActionLink>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (text, record) => (
        <Dropdown overlay={statusOptions(text, record)}>
          <Anchor onClick={(e) => e.preventDefault()}>{statusEnum(text)}</Anchor>
        </Dropdown>
      )
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      width: 120
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 120
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 160,
      render: (text, record) => {
        if (!_.get(record, ['record', 'invoice_ref_id'])) {
          return <b>-</b>;
        }
        return (
          <ActionLink to={`/invoices/${text}`} status={record.status}>
            {text}
          </ActionLink>
        );
      }
    },
    {
      title: 'Origin Zone',
      dataIndex: 'originZone',
      key: 'originZone'
    },
    {
      title: 'Destination Zone',
      dataIndex: 'destinationZone',
      key: 'destinationZone'
    },
    // {
    //   title: 'Last Update',
    //   dataIndex: 'createdOn',
    //   key: 'createdOn',
    //   render: (text) => formatDateDisplay(text).format('DD MMM YYYY'),
    //   width: 190
    // },
    // {
    //   title: 'Billing Cycle',
    //   dataIndex: 'billingCycle',
    //   key: 'billingCycle',
    //   render: (text) => formatDateDisplay(text).format('DD MMM YYYY'),
    //   width: 190
    // },
    {
      title: 'Service Type',
      dataIndex: 'service',
      render: (text, record) => {
        let serviceText = text;
        if (record.record.billing_type === 2) {
          serviceText = 'SURCHARGE';
        } else if (record.record.billing_type === 3) {
          serviceText = 'D&Ts';
        }
        return <span>{serviceText}</span>;
      },
      key: 'service'
    },
    {
      title: 'Order Direction',
      dataIndex: 'orderDirection',
      key: 'orderDirection',
      render: (text) => text || '-'
    },
    {
      title: 'Pricing Group',
      dataIndex: 'pricingGroup',
      key: 'pricingGroup',
      width: 280,
      render: (text, record) => record.record.rate_card
    }
  ];

  // const setEditorNotes = (e) => {
  //   setNotes(e.target.value);
  // };

  // const filterRef = useRef();

  const [tableColumns, setTableColumns] = useState([
    'trackingId',
    'shipmentDate',
    'client',
    'status',
    'currency',
    'totalAmount',
    'billingCycle',
    'invoiceNumber',
    'originZone',
    'destinationZone',
    'service',
    'orderDirection',
    'pricingGroup'
  ]);

  const currentPage = useSelector((state) => state.billingRecords.filters.page);
  const pageSize = useSelector((state) => state.billingRecords.filters.records);
  const choices = useSelector((state) => state.billingRecords.choices);

  const [filters, setFilters] = useState({});

  const {
    data,
    total: totalCount,
    loading
  } = useSelector((state) => state.billingRecords.billing_records);
  let originZones = choices.originZones;
  let destinationZones = choices.destinationZones;
  let originCountries = choices.originCountries;
  let destinationCountries = choices.destinationCountries;

  const showModal = ({ key }, status, record) => {
    setCurrentState(status);
    setTransitionState(key);
    setSelectedRecord(record);
    setConfirmModalVisible(true);
  };

  const handleOk = () => {
    setStatusLoading(true);
    const url = `/billing-records/change-status`;

    request()
      .post(url, {
        invoice_record_id: selectedRecord.record.invoice_record_id,
        status: Number(transitionState)
      })
      .then(() => {
        setStatusLoading(false);
        setConfirmModalVisible(false);

        message.success({
          content: 'Successfully updated the status',
          key,
          duration: 2
        });

        const recordFilters = {
          page: currentPage,
          pageSize,
          ...prePayload,
          ...filters
        };

        dispatch(fetchBilling(recordFilters));
      })
      .catch(() => {
        setStatusLoading(false);

        message.error({
          content: 'Failed to update status. Please try again later or contact the technical team.',
          key,
          duration: 4
        });
      });
  };

  const handleCancel = () => {
    setConfirmModalVisible(false);
    setOrderEditModalVisible(false);
    setAutoCW(null);
    setChangedHeight(null);
    setChangedWidth(null);
    setChangedLength(null);
    setChangedWeight(null);
    setChangePrice(null);
  };

  const statusOptions = (status, record) => (
    <Menu onClick={(e) => showModal(e, status, record)}>
      {status !== 1 && status !== 2 && (
        <Menu.Item key="1">
          <a>To Invoice</a>
        </Menu.Item>
      )}

      {status !== 3 && status !== 2 && (
        <Menu.Item key="3">
          <a>On Hold</a>
        </Menu.Item>
      )}

      {status !== 4 && status !== 2 && (
        <Menu.Item key="4">
          <a>Cancelled</a>
        </Menu.Item>
      )}

      {status !== 5 && status !== 2 && (
        <Menu.Item key="5">
          <a>Lost</a>
        </Menu.Item>
      )}

      {status !== 7 && status !== 2 && (
        <Menu.Item key="7">
          <a>Waived</a>
        </Menu.Item>
      )}

      {status !== 8 && status !== 2 && (
        <Menu.Item key="8">
          <a>Test</a>
        </Menu.Item>
      )}

      {status !== 6 && status !== 2 && (
        <Menu.Item key="6">
          <a>Others</a>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleFilter = (filters) => {
    dispatch(setInvoiceFilters({ page: 1 }));
    setFilters(filters);

    const recordFilters = {
      page: currentPage,
      pageSize,
      ...prePayload,
      ...filters
    };

    if (Object.keys(filters).length !== 0) {
      dispatch(fetchBilling(recordFilters));
    }
  };

  useEffect(() => {
    dispatch(getChoices());

    if (props?.params?.invoiceId) {
      const recordFilters = {
        page: currentPage,
        pageSize,
        ...prePayload,
        ...filters
      };
      dispatch(fetchBilling(recordFilters));
    }
  }, []);

  return (
    <div>
      <DimWeightModal
        visible={orderEditModalVisible}
        setVisible={setOrderEditModalVisible}
        selectedRecord={selectedRecord}
      />
      <StyledCard>
        <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
          <Col>
            <FilterDropdownBilling
              onFilter={handleFilter}
              originZones={originZones}
              destinationZones={destinationZones}
              originCountries={originCountries}
              destinationCountries={destinationCountries}
            />
          </Col>
          <Col>
            <SelectDropdown
              placeholder="Hide/Show Columns"
              multiple
              items={columns
                .map((column) => ({
                  label: column.title,
                  value: column.key
                }))
                .filter((column) => !['trackingNumber'].includes(column.value))}
              value={tableColumns}
              onChange={(value) => {
                setTableColumns(['trackingNumber', ...value]);
              }}
            />
          </Col>
          <Col sx={{ ml: 'auto' }}>
            {/* <SelectDropdown
              value={`${DEFAULT_PAGE_SIZE}`}
              items={[
                { label: '20 items/page', value: '20' },
                { label: '50 items/page', value: '50' },
                { label: '100 items/page', value: '100' }
              ]}
              onChange={(value) => {
                dispatch(setPageSizeFilter(value));
              }}
            /> */}
          </Col>
        </Row>
        <Row>
          <Table
            loading={loading}
            rowKey={(row) => `${row.index}`}
            columns={columns.filter((column) => tableColumns.includes(column.key))}
            dataSource={data}
            // pageSize={parseInt(pageSize || DEFAULT_PAGE_SIZE, 10)}
            pageSize={500}
            currentPage={currentPage}
            totalCount={totalCount}
            onChange={(page) => dispatch(setPageFilter(page))}
            scroll={{ x: 2000 }}
            renderEmptyTable
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No Data found.</span>&nbsp;
                  </div>
                )}
                {loading && (
                  <AntSkeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
          />
        </Row>

        <Modal
          title="Confirm"
          visible={confirmModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="submit" loading={statusLoading} onClick={handleOk}>
              Submit
            </Button>
          ]}
        >
          <p>
            Change status of billing record <strong>{selectedRecord.trackingId}</strong> from{' '}
            <strong>{statusEnum(currentState)}</strong> to <b>{statusEnum(transitionState)}</b>.{' '}
          </p>
        </Modal>
      </StyledCard>
    </div>
  );
};

export default withRouter(BillingRecords);

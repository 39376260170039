import styled from '@emotion/styled';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
  );
};

const StyledBreadcrumb = styled(AntBreadcrumb)`
  &.ant-breadcrumb {
    background: transparent;
    padding: 12px 40px;
    line-height: 14.3px;
    max-height: 40px;
    height: 40px;
    & span .ant-breadcrumb-link {
      font-family: AvenirLTStdBook;
      font-size: 14px;
      a {
        color: #050593;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      color: #000000;
    }

    & > span:last-child .ant-breadcrumb-link {
      text-decoration: none;
      span {
        font-family: AvenirLTStdBook;
        color: #000000;
      }
    }
  }
`;

const Breadcrumb = () => {
  const location = useLocation();
  const { pathname } = location;

  const [routes, setRoutes] = useState([
    {
      path: '/',
      breadcrumbName: 'Overview'
    }
  ]);

  useEffect(() => {
    const routesConfig = {
      '': 'Overview',
      invoices: 'View Invoices',
      'billing-records': 'View Billing Records',
      'cost-estimation': 'Cost Estimation',
      surcharges: 'Surcharges',
      orders: 'View Orders',
      'track-orders': 'Orders',
      'non-computed-orders': 'Non Computed Orders',
      tickets: 'View Tickets',
      clients: 'View Clients',
      reports: 'View Reports',
      manage: 'Create Draft Pricing Group'
    };
    const Current = [];
    const arrRoute = pathname.split('/');
    arrRoute.shift();
    arrRoute.forEach((item, index) => {
      if (index < 2) {
        if (Object.keys(routesConfig).includes(item)) {
          Current.push({
            path: `../${item}`,
            breadcrumbName: routesConfig[item]
          });
        } else if (routesConfig[item]) {
          Current.push({
            breadcrumbName: routesConfig[item]
          });
        }
      }
    });
    setRoutes(Current);
  }, [pathname]);

  return <StyledBreadcrumb itemRender={itemRender} separator=">" routes={routes} />;
};

export default Breadcrumb;

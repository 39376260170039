import styled from '@emotion/styled';
import { Row, Skeleton as AntSkeleton } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table } from '../../components';
import { getReports } from './reports.action';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const StyledLink = styled.a`
  color: #050593;
  &:hover {
    color: #050593;
  }
`;

const columns = [
  {
    title: 'File Name',
    dataIndex: 'file_name',
    key: 'key',
    fixed: 'left',
    render: (text, record) => (
      <StyledLink href={record.file_url} download>
        {text}
      </StyledLink>
    )
  },
  {
    title: 'Report Type',
    dataIndex: 'report_type',
    key: 'report_type',
    width: 150
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 150,
    render: (text) => moment.utc(text).format('DD MMM YYYY')
  }
];

const Reports = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state?.reports);

  useEffect(() => {
    dispatch(getReports());
  }, []);

  return (
    <StyledCard>
      <Row>
        <Table
          loading={loading}
          rowKey={(row) => `${row.key}`}
          renderEmptyTable
          dataSource={data}
          showPagination={false}
          columns={columns}
          empty={
            <React.Fragment>
              {!loading && (
                <div>
                  <span>No reports found.</span>&nbsp;
                </div>
              )}
              {loading && (
                <AntSkeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 5,
                    width: ['100%', '100%', '100%', '100%', '100%']
                  }}
                />
              )}
            </React.Fragment>
          }
        />
      </Row>
    </StyledCard>
  );
};

export default Reports;

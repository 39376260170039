import { createAction } from '@reduxjs/toolkit';
import { clientService } from './client-details.service';

export const fetchClientDetailsLoading = createAction('invoices/FETCH_CLIENT_DETAILS_LOADING');
export const fetchClientDetail = createAction('invoices/FETCH_CLIENT_DATA');

export const fetchPricingGroupsWithEffectiveDateLoading = createAction(
  'pricing_groups/FETCH_PRICING_GROUPS_WITH_EFFECTIVE_DATE_LOADING'
);
export const fetchPricingGroupsWithEffectiveDate = createAction(
  'pricing_groups/FETCH_PRICING_GROUPS_WITH_EFFECTIVE_DATE'
);

export const fetchPricingGroupZonesLoading = createAction(
  'pricing_groups/FETCH_PRICING_GROUP_ZONES_LOADING'
);
export const fetchPricingGroupZones = createAction('pricing_groups/FETCH_PRICING_GROUP_ZONES');

export const getClientDetail = (name) => async (dispatch) => {
  dispatch(fetchClientDetailsLoading(true));
  clientService
    .getClientDetails(name)
    .then((data) => {
      dispatch(fetchClientDetail(data));
    })
    .catch(() => {
      dispatch(fetchClientDetailsLoading(false));
    });
};

export const getPricingGroupsWithEffectiveDate = (txt) => async (dispatch) => {
  dispatch(fetchPricingGroupsWithEffectiveDate([]));
  if (!txt) return;
  dispatch(fetchPricingGroupsWithEffectiveDateLoading(true));
  clientService
    .getPricingGroupsWithEffectiveDate(txt)
    .then((data) => {
      dispatch(fetchPricingGroupsWithEffectiveDate(data));
    })
    .catch(() => {
      dispatch(fetchPricingGroupsWithEffectiveDateLoading(false));
    });
};

export const getPricingGroupZones = (name) => async (dispatch) => {
  dispatch(fetchPricingGroupZonesLoading(true));
  clientService
    .getPricingGroupZones(name)
    .then((data) => {
      dispatch(fetchPricingGroupZones(data));
    })
    .catch(() => {
      dispatch(fetchPricingGroupZonesLoading(false));
    });
};

import React, { useState, useEffect } from 'react';
import Select from '../Select';
import { TEXT_NO_RESULT } from '../../constants/messages';

const { Option } = Select;

const MultiSelect = ({ items = [], placeholder, onChange = () => {}, ...restProps }) => {
  const [data, setData] = useState(items);

  useEffect(() => {
    if (items.length > 0) {
      setData(items);
    }
  }, [items]);
  return (
    <Select
      mode="multiple"
      placeholder={placeholder}
      onChange={(_value) => onChange(_value)}
      notFoundContent={TEXT_NO_RESULT}
      {...restProps}
    >
      {data.map((item) => (
        <Option key={(item && item.value) || item} value={(item && item.value) || item}>
          {(item && item.label) || item}
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelect;

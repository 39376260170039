import React from 'react';
import ShipmentDetailsView from './ShipmentDetailsView';

const ShipmentDetails = ({ invoiceId, invoice }) => {
  const invoiceDetail = invoice;

  return (
    <>
      <ShipmentDetailsView invoiceId={invoiceId} invoice={JSON.stringify(invoiceDetail)} />
    </>
  );
};

export default ShipmentDetails;

import { createReducer } from '@reduxjs/toolkit';
import {
  fetchClientDetail,
  fetchClientDetailsLoading,
  fetchPricingGroupsWithEffectiveDate,
  fetchPricingGroupsWithEffectiveDateLoading,
  fetchPricingGroupZones,
  fetchPricingGroupZonesLoading
} from './client-details.action';

const initialState = {
  data: null,
  loading: false,
  pricingGroups: {
    data: [],
    loading: false
  },
  pricingGroupZones: {
    origins: [],
    destinations: [],
    loading: false
  }
};

export default createReducer(initialState, {
  [fetchClientDetailsLoading](state, action) {
    state.data = initialState.data;
    state.loading = action.payload;
  },
  [fetchClientDetail](state, action) {
    state.data = action.payload;
    state.loading = false;
  },
  [fetchPricingGroupsWithEffectiveDateLoading](state, action) {
    state.pricingGroups.data = initialState.pricingGroups.data;
    state.pricingGroups.loading = action.payload;
  },
  [fetchPricingGroupsWithEffectiveDate](state, action) {
    const { pricing_groups } = action.payload;
    state.pricingGroups.data = pricing_groups;
    state.pricingGroups.loading = false;
  },
  [fetchPricingGroupZonesLoading](state, action) {
    state.pricingGroupZones.origins = initialState.pricingGroupZones.origins;
    state.pricingGroupZones.destinations = initialState.pricingGroupZones.destinations;
    state.pricingGroupZones.loading = action.payload;
  },
  [fetchPricingGroupZones](state, action) {
    state.pricingGroupZones.origins = action.payload.origins;
    state.pricingGroupZones.destinations = action.payload.destinations;
    state.pricingGroupZones.loading = false;
  }
});

import React from 'react';
import { Divider } from 'antd';
import OrdersSync from './OrdersSync';
import OrderList from './OrderList';

const Orders = (props) => {
  return (
    <div>
      <OrdersSync {...props} />
      <Divider />
      <OrderList {...props} />
    </div>
  );
};

export default Orders;

import styled from '@emotion/styled';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionLink } from '../../../components';
import { INVOICE_REMARK, PAYMENT_MODE } from '../../../constants';

const StyledShipmentDetails = styled.div`
  .shipment-body {
    /* padding: 0px 20px; */
  }
  .shipment-body div:last-child {
    border-bottom: transparent;
  }
  button {
    margin-top: 15px;
    padding: 0px 41px;
  }
`;

const StyledShipmentDetailRow = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
  }
  li {
    margin-bottom: 20px;
  }
  li em {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  li span {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
  ul {
    line-height: 1;
    list-style-type: none;
    display: grid;
    grid-template-columns: ${(prop) =>
      prop.templateColumns || 'repeat(auto-fit, minmax(105px, 1fr))'};
    padding: 0;
    grid-gap: 15px;
  }
`;

const ShipmentDetailRow = ({ type, schema, title, children }) => {
  const templateColumnMap = {
    location: 'repeat(auto-fit,minmax(calc(100% - 415px),1fr)) 187px 197px',
    package: '112px repeat(auto-fit,minmax(calc((100% - 255px)/3),1fr)) 143px',
    others: '200px 200px 200px 200px 200px'
  };

  const [data] = useState(() => {
    if (!schema) return [];
    if (schema.formatData) {
      return schema.formatData(schema.data);
    }
    return schema.data;
  });

  return (
    <StyledShipmentDetailRow templateColumns={templateColumnMap[type]}>
      <h5>{schema?.title || title}</h5>
      {data && (
        <ul>
          {data.map(({ label, value, formatLabel, formatValue }) => (
            <li key={`${label}${value}`}>
              <em className="label">{formatLabel ? formatLabel(label) : label}</em>
              <span className="value">{formatValue ? formatValue(value) : value}</span>
            </li>
          ))}
        </ul>
      )}
      {children && <p>{children}</p>}
    </StyledShipmentDetailRow>
  );
};

const ShipmentDetailsView = () => {
  const details = useSelector((state) => state.invoiceDetails.invoice_detail);
  const day = parseInt(moment(details.billing_cycle).format('DD'));
  const updatedDate =
    day > 15
      ? moment(details.billing_cycle).startOf('month').add(14, 'day')
      : moment(details.billing_cycle).subtract(1, 'month').endOf('month');
  const origin = {
    title: 'Invoice Information',
    data: [
      {
        label: 'To',
        value: details.company_name,
        formatValue: (value) => (
          <ActionLink to={`/clients/${details.order_client_id}`}>{value}</ActionLink>
        )
      },
      {
        label: 'Billing Cycle',
        value: details.billing_cycle,
        formatValue: (value) => moment(value).format('DD MMM YYYY')
      },
      {
        label: 'Date',
        value: updatedDate,
        formatValue: (value) => moment(value).format('DD MMM YYYY')
      },
      {
        label: 'Due',
        value: updatedDate,
        formatValue: (value) =>
          moment(value).add(details.payment_terms, 'days').format('DD MMM YYYY')
      },
      {
        label: 'Service Reference',
        value: details.service
      }
    ]
  };

  if (!details.consolidated_invoice === 1) {
    origin.data.push(
      {
        label: 'Origin',
        value: details.origin_address
      },
      {
        label: 'Destination',
        value: details.destination_address
      }
    );
  }
  const invoiceSummary = {
    title: 'Invoice Summary',
    data: [
      {
        label: `Sub-Total (${details.currency})`,
        value: details.sub_total,
        formatValue: (value) =>
          value.toLocaleString(undefined, {
            maximumFractionDigits: 2
          })
      },
      {
        label: `Tax (${details.currency})`,
        value: Math.round((Number(details.tax_amount) + Number.EPSILON) * 100) / 100,
        formatValue: (value) =>
          value.toLocaleString(undefined, {
            maximumFractionDigits: 2
          })
      },
      {
        label: `Total (${details.currency})`,
        value: Math.round((Number(details.total_amount) + Number.EPSILON) * 100) / 100,
        formatValue: (value) =>
          value.toLocaleString(undefined, {
            maximumFractionDigits: 2
          })
      },
      {
        label: `Payment Mode`,
        value: details.payment_mode,
        formatValue: (value) => PAYMENT_MODE[value] || '-'
      },
      {
        label: 'Remark',
        value: (details.remark && INVOICE_REMARK[details.remark]) || '-',
        formatValue: (value) => {
          if (value === INVOICE_REMARK.NON_COMPUTED_ORDERS) {
            return (
              <ActionLink to={`/non-computed-orders/${details.invoice_display_id}`}>
                {value}
              </ActionLink>
            );
          }

          return value;
        }
      }
    ]
  };

  return (
    <StyledShipmentDetails id="shipment-details">
      <div className="shipment-body">
        <ShipmentDetailRow type="others" schema={origin} />
        <ShipmentDetailRow type="others" schema={invoiceSummary} />
      </div>
    </StyledShipmentDetails>
  );
};

export default ShipmentDetailsView;

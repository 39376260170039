import { createReducer } from '@reduxjs/toolkit';
import { FETCH_METADATA, FETCH_ZONES } from './surcharges.action';

const initialState = {
  surchargeTemplate: undefined
};

export default createReducer(initialState, {
  [FETCH_ZONES](state, action) {
    state.zones = action.payload;
  },
  [FETCH_METADATA](state, action) {
    state.metadata = action.payload;
  }
});

import styled from '@emotion/styled';
import { Col, Divider, message, Row } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Message from '../../../components/Message';
import SearchInput from '../../../components/SearchInput';
import { surchargeService } from '../surcharges.service';

const StyledContainer = styled.div`
  label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: rgb(112, 112, 112);
    margin-bottom: 5px;
    display: block;
  }
  .error {
    margin-top: 5px;
  }
`;

const validationSchema = yup.object().shape({
  surcharge: yup.string().nullable().required('Please select Surcharge.'),
  start_date: yup.string().nullable().required('Please select Start Date.'),
  end_date: yup.string().nullable().required('Please select End Date.'),
  client_id: yup.string().nullable().required('Please select Client.')
});

const SurchargeMapping = (props) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const zones = useSelector((state) => state?.surcharges?.zones);
  const metadata = useSelector((state) => state?.surcharges?.metadata);

  const initialValues = {
    surcharge: undefined,
    start_date: undefined,
    end_date: undefined,
    client_id: props.clientID ? props.clientID : undefined,
    origin_zone: undefined,
    destination_zone: undefined,
    currency: undefined,
    pricing_group: undefined
  };

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      let requestPayload = { ...values };
      requestPayload.start_date = requestPayload.start_date
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ');
      requestPayload.end_date = requestPayload.end_date
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ');
      surchargeService
        .surchargesClientMapping([requestPayload])
        .then(() => {
          message.success('Surcharge mapped successfully');
        })
        .catch((err) => {
          let error = 'Surcharge mapping failed.';
          if (err?.response?.data) {
            error = err?.response?.data?.data;
          }
          message.error(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (err) {
      message.error('An error occured while mapping surcharge');
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, touched, errors, values, setFieldValue }) => (
          <StyledContainer>
            <Row gutter={[24, 16]}>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="surcharge">Surcharge</label>
                <SearchInput
                  id="surcharge"
                  allowClear
                  items={
                    metadata?.surcharges?.map((surcharge) => ({
                      label: surcharge,
                      value: surcharge
                    })) || []
                  }
                  value={values.surcharge}
                  onChange={(value) => {
                    setFieldValue('surcharge', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.surcharge && touched?.surcharge && (
                  <Message type="error">{errors?.surcharge}</Message>
                )}
              </Col>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="start_date">Start Date</label>
                <DatePicker
                  id="start_date"
                  value={values.start_date}
                  onChange={(value) => {
                    setFieldValue('start_date', value);
                  }}
                  disabledDate={(date) => {
                    if (!values.end_date) {
                      return false;
                    }
                    return date.isAfter(values.end_date, 'day');
                  }}
                />
                {errors?.start_date && touched?.start_date && (
                  <Message type="error">{errors?.start_date}</Message>
                )}
              </Col>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="end_date">End Date</label>
                <DatePicker
                  id="end_date"
                  value={values.end_date}
                  onChange={(value) => {
                    setFieldValue('end_date', value);
                  }}
                  disabledDate={(date) => {
                    if (!values.start_date) {
                      return false;
                    }
                    return date.isBefore(values.start_date, 'day');
                  }}
                />
                {errors?.end_date && touched?.end_date && (
                  <Message type="error">{errors?.end_date}</Message>
                )}
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="client_id">Client</label>
                <SearchInput
                  id="client_id"
                  allowClear
                  disabled={props.clientID ? true : false}
                  items={
                    metadata?.clients?.map((client) => ({
                      label: client.name,
                      value: client.id
                    })) || []
                  }
                  value={values.client_id}
                  onChange={(value) => {
                    setFieldValue('client_id', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.client_id && touched?.client_id && (
                  <Message type="error">{errors?.client_id}</Message>
                )}
              </Col>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="origin_zone">Origin Zone</label>
                <SearchInput
                  id="origin_zone"
                  allowClear
                  items={zones?.origin_zones?.map((zone) => ({ label: zone, value: zone })) || []}
                  value={values.origin_zone}
                  onChange={(value) => {
                    setFieldValue('origin_zone', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.origin_zone && touched?.origin_zone && (
                  <Message type="error">{errors?.origin_zone}</Message>
                )}
              </Col>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="destination_zone">Destination Zone</label>
                <SearchInput
                  id="destination_zone"
                  allowClear
                  items={
                    zones?.destination_zones?.map((zone) => ({ label: zone, value: zone })) || []
                  }
                  value={values.destination_zone}
                  onChange={(value) => {
                    setFieldValue('destination_zone', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.destination_zone && touched?.destination_zone && (
                  <Message type="error">{errors?.destination_zone}</Message>
                )}
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="currency">Currency</label>
                <SearchInput
                  id="currency"
                  allowClear
                  items={metadata?.currencies || []}
                  value={values.currency}
                  onChange={(value) => {
                    setFieldValue('currency', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.currency && touched?.currency && (
                  <Message type="error">{errors?.currency}</Message>
                )}
              </Col>
              <Col span={8} xxl={{ span: 6 }}>
                <label htmlFor="pricing_group">Pricing Group</label>
                <SearchInput
                  id="pricing_group"
                  allowClear
                  items={
                    metadata?.pricing_groups?.map((pricing_group) => ({
                      label: pricing_group,
                      value: pricing_group
                    })) || []
                  }
                  value={values.pricing_group}
                  onChange={(value) => {
                    setFieldValue('pricing_group', value);
                  }}
                  placeholder="Select..."
                />
                {errors?.pricing_group && touched?.pricing_group && (
                  <Message type="error">{errors?.pricing_group}</Message>
                )}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Button
                type="primary"
                style={{ width: 150 }}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Row>
          </StyledContainer>
        )}
      </Formik>
    </>
  );
};

export default SurchargeMapping;

/** @jsxRuntime classic */
/** @jsx jsx */
import { SelectOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import { ActionLink, Button, Card, SelectDropdown, Table } from '../../components';
import { convertSingaporeTimeToLocal } from '../../utils/formatters';
import { getChoices, getDimWeights, getOrderStatuses, getOrders } from './orders.action';
import DimWeightModal from './shared/DimWeightModal';
import FilterDropdownOrder from './shared/FilterDropdownOrder';
import StatusModal from './shared/StatusModal';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const Orders = () => {
  const dispatch = useDispatch();

  const {
    data: orders,
    total,
    loading: loadingOrders
  } = useSelector((state) => state.orders.orders);

  const { data: weights, loading: loadingWeights } = useSelector((state) => state.orders.weights);
  const { data: statuses, loading: loadingStatuses } = useSelector(
    (state) => state.orders.statuses
  );

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [filters, setFilters] = useState({});
  const [visibleOrderStatus, setVisibleOrderStatus] = useState(false);
  const [visibleDimWeight, setVisibleDimWeight] = useState(false);
  const [onLoadingOrderStatus, setOnLoadingOrderStatus] = useState('');
  const [onLoadingDimWeight, setOnLoadingDimWeight] = useState('');
  const [tableColumns, setTableColumns] = useState([
    'trackingNumber',
    'clientName',
    'invoiceNumber',
    'origin',
    'destination',
    'billingCycle',
    'orderDate',
    'firstTouchDate',
    'serviceType',
    'orderDirection',
    'status',
    'payment_mode',
    'financeStatus',
    'orderStatus',
    'dimWeight'
  ]);

  const handleFilter = (filters, page = 1, size = 50) => {
    setPage(page);
    setSize(size);
    setFilters(filters);

    const recordFilters = { page, size, ...filters };
    if (Object.keys(filters).length !== 0) {
      dispatch(getOrders(recordFilters));
    }
  };

  const handleOrderStatus = (id) => {
    setOnLoadingOrderStatus(id);
    dispatch(getOrderStatuses(id));
  };

  const handleDimWeight = (id) => {
    setOnLoadingDimWeight(id);
    dispatch(getDimWeights(id));
  };

  const handleFinanceStatus = (text) => {
    switch (text) {
      case 1:
        return 'Draft';
      case 2:
        return 'Billed';
      case 3:
        return 'On Hold';
      case 4:
        return 'Cancelled';
      case 5:
        return 'Lost';
      case 6:
        return 'Others';
      default:
        return '-';
    }
  };

  const handlePaymentMode = (text) => {
    switch (text) {
      case '1':
        return 'Pre Paid';
      case '2':
        return 'Post Paid';
      default:
        return '-';
    }
  };

  const handleDate = (text) =>
    text ? convertSingaporeTimeToLocal(text).format('DD MMM YYYY') : '-';

  useEffect(() => {
    if (!loadingStatuses && statuses.length !== 0) {
      setVisibleOrderStatus(true);
      setOnLoadingOrderStatus('');
    }
  }, [loadingStatuses, statuses]);

  useEffect(() => {
    if (!loadingWeights && weights.length !== 0) {
      setVisibleDimWeight(true);
      setOnLoadingDimWeight('');
    }
  }, [loadingWeights, weights]);

  useEffect(() => {
    dispatch(getChoices());
  }, []);

  const columns = [
    {
      title: 'Tracking No.',
      dataIndex: 'tracking_number',
      key: 'trackingNumber',
      fixed: 'left',
      width: 200
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'clientName',
      width: 200,
      render: (text, record) => <ActionLink to={`/clients/${record.client_id}`}>{text}</ActionLink>
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      key: 'invoiceNumber',
      width: 160,
      render: (text, record) => (
        <React.Fragment>
          {record.invoice_number ? (
            <ActionLink to={`/invoices/${record.invoice_number}`} status={record.status}>
              {record.invoice_number}
            </ActionLink>
          ) : (
            '-'
          )}
        </React.Fragment>
      )
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      render: (text) => text || '-'
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      render: (text) => text || '-'
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'orderDate',
      render: (text) => handleDate(text)
    },
    {
      title: 'First Touch Date',
      dataIndex: 'first_touch_date',
      key: 'firstTouchDate',
      render: (text) => handleDate(text)
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'serviceType',
      render: (text) => text || '-'
    },
    {
      title: 'Order Direction',
      dataIndex: 'order_direction',
      key: 'orderDirection',
      render: (text) => text || '-'
    },
    {
      title: 'Payment Mode',
      dataIndex: 'payment_mode',
      key: 'payment_mode',
      render: (text) => handlePaymentMode(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => text || '-'
    },
    {
      title: 'Finance Status',
      dataIndex: 'finance_status',
      key: 'financeStatus',
      render: (text) => handleFinanceStatus(text)
    },
    {
      title: 'Order Status',
      dataIndex: 'order_id',
      key: 'orderStatus',
      width: 112,
      fixed: 'right',
      render: (text) => (
        <Button
          icon={<SelectOutlined />}
          loading={onLoadingOrderStatus === text}
          onClick={() => handleOrderStatus(text)}
        >
          Track
        </Button>
      )
    },
    {
      title: 'Dim Weight',
      dataIndex: 'order_id',
      key: 'dimWeight',
      width: 110,
      fixed: 'right',
      render: (text) => (
        <Button
          icon={<SelectOutlined />}
          loading={onLoadingDimWeight === text}
          onClick={() => handleDimWeight(text)}
        >
          Show
        </Button>
      )
    }
  ];

  return (
    <StyledCard>
      <StatusModal visible={visibleOrderStatus} setVisible={setVisibleOrderStatus} />
      <DimWeightModal visible={visibleDimWeight} setVisible={setVisibleDimWeight} />
      <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
        <Col>
          <FilterDropdownOrder
            onFilter={(filters) => handleFilter(filters, page, size)}
            loading={loadingOrders}
          />
        </Col>
        <Col>
          <SelectDropdown
            placeholder="Hide/Show Columns"
            multiple
            items={columns
              .map((column) => ({
                label: column.title,
                value: column.key
              }))
              .filter((column) => !['trackingNumber'].includes(column.value))}
            value={tableColumns}
            onChange={(value) => {
              setTableColumns(['trackingNumber', ...value]);
            }}
          />
        </Col>
        <Col sx={{ ml: 'auto' }}>
          <SelectDropdown
            value={size}
            disabled={loadingOrders}
            items={[
              { label: '50 items/page', value: 50 },
              { label: '100 items/page', value: 100 },
              { label: '500 items/page', value: 500 }
            ]}
            onChange={(size) => handleFilter(filters, 1, size)}
          />
        </Col>
      </Row>
      <Row>
        <Table
          renderEmptyTable
          loading={loadingOrders}
          rowKey={(row) => row.order_id}
          dataSource={orders}
          pageSize={size}
          currentPage={page}
          totalCount={total}
          onChange={(page) => handleFilter(filters, page, size)}
          columns={columns.filter((column) => tableColumns.includes(column.key))}
          scroll={{ x: 2100 }}
          empty={
            <React.Fragment>
              {!loadingOrders && (
                <div>
                  <span>No order found.</span>&nbsp;
                </div>
              )}
              {loadingOrders && (
                <Skeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 5,
                    width: ['100%', '100%', '100%', '100%', '100%']
                  }}
                />
              )}
            </React.Fragment>
          }
        />
      </Row>
    </StyledCard>
  );
};

export default Orders;

import { Col, message, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { Button, Select } from '../../../components';
import request from '../../../utils/request';
import { history } from '../../../utils/store';

const { Option } = Select;

const reasons = [
  'Billed from B2B',
  'Billed from Fulfilment',
  'Lost Claim',
  'Request from Commercial',
  'Trial Shipment',
  'Others'
];

const WaiveOffModal = ({
  data: { tns = '', invoice_id = '' },
  visible = false,
  setVisible = () => {}
}) => {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const source = invoice_id ? 'Invoice' : 'Tracking numbers';
  const handleCancel = () => {
    setReason('');
    setVisible(false);
  };

  const handleWaiveOff = async () => {
    setLoading(true);
    request()
      .post(`/invoices/waive-off`, {
        tns,
        invoice_id,
        reason
      })
      .then(() => {
        message.success(`${source} waived-off successfully!`);
        invoice_id && history.replace('/invoices');
      })
      .catch((error) => {
        message.error({ content: `Failed to waive-off ${source}` });
        console.log(error);
      })
      .finally(() => {
        setReason('');
        setLoading(false);
        setVisible(false);
      });
  };

  return (
    <Modal
      title={`Are you sure you want to waive-off ${source}?`}
      visible={visible}
      closable={false}
      footer={[
        <Button key="cancel" type="secondary" disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" onClick={handleWaiveOff} disabled={!reason} loading={loading}>
          Submit
        </Button>
      ]}
    >
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <span className="label">Reason *</span>
          <Select
            placeholder="Select"
            allowClear
            value={reason || null}
            onChange={(_value) => {
              setReason(_value);
            }}
          >
            {reasons.map((item) => (
              <Option key={(item && item.value) || item} value={(item && item.value) || item}>
                {(item && item.label) || item}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

export default WaiveOffModal;

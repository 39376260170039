import styled from '@emotion/styled';
import { ConfigProvider, Skeleton as AntSkeleton, Table as AntTable } from 'antd';
import React from 'react';

const StyledTable = styled(AntTable)`
  width: 100%;
  div.ant-table {
    border: none;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    .ant-table-row td {
      border: none;
      color: #000;
    }
    .ant-table-row:hover td {
      background-color: #fff;
    }
  }
  &.primary div.ant-table {
    .ant-table-row:nth-last-of-type(1) td {
      border-bottom: 1px solid #050593;
    }
    .ant-table-row:nth-of-type(1) td {
      border-top: 1px solid #050593;
    }
    .ant-table-row:nth-of-type(even) td {
      background-color: #f2f2f9;
    }
  }
  &.secondary div.ant-table {
    .ant-table-row:nth-last-of-type(1) td {
      border-bottom: 1px solid #d9c8a9;
    }
    .ant-table-row:nth-of-type(1) td {
      border-top: 1px solid #d9c8a9;
    }
    .ant-table-row:nth-of-type(even) td {
      background-color: #f7f4ee;
    }
  }
  &.tertiary div.ant-table {
    .ant-table-row td {
      border-bottom: 1px solid #f5f5f5;
    }
    .ant-table-row:nth-of-type(1) td {
      border-top: 1px solid #1fceca;
    }
  }
  li.ant-pagination-item-active {
    a {
      color: #050593;
    }
    border-color: #050593;
  }
  thead.ant-table-thead th {
    background-color: #ffffff;
    border-bottom: 1px solid #050593;
    font-family: AvenirLTStdHeavy;
    color: #707070;
  }
  .ant-pagination.mini .ant-pagination-item {
    height: 32px;
    line-height: 30px;
    min-width: 32px;
  }
  .ant-pagination-item {
    font-size: 16px;
    font-family: AvenirLTStdBook;
    padding: 2px 0 5px 0;
  }
  .ant-pagination-item a {
    color: #050593;
  }
  .ant-pagination-item-active {
    background: #050593;
    color: #fff;
  }
  li.ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination-item-link svg {
    width: 12px;
    height: 12px;
    fill: #050593;
  }
  .ant-pagination-item-ellipsis {
    font-size: 7px;
    color: #050593 !important;
    padding-top: 5px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    height: 32px;
    width: 32px;
    border-color: #050593;
    margin-top: -3px;
    text-align: center;
  }
  .ant-pagination-options-quick-jumper {
    font-size: 16px;
    font-family: AvenirLTStdBook;
    height: 32px;
    line-height: 32px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000;
    border-color: #000;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #000;
  }
  .ant-table-placeholder {
    background-color: #f2f2f9;
    border-top: 1px solid #050593;
    border-bottom: 1px solid #05059 !important;
    color: #000000;
    padding: 10px;
    z-index: 2;
    height: 40px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d9d9d9;
  }
  .ant-checkbox-checked::after {
    border-color: #000;
  }
  .ant-spin-nested-loading {
    text-align: center;
  }
  .ant-table-pagination {
    float: none;
    display: inline-block;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: #050593;
  }
  .ant-table.ant-table-small .ant-table-expanded-row-fixed {
    padding-left: 55px;
  }
`;

const TableSkeleton = ({ active = true }) => {
  return (
    <AntSkeleton
      active={active}
      title={{ width: '100%' }}
      paragraph={{
        rows: 5,
        width: ['100%', '100%', '100%', '100%', '100%']
      }}
    />
  );
};

const Table = ({
  type = 'primary',
  totalCount,
  currentPage,
  pageSize = 10,
  renderEmptyTable = false,
  showPagination = true,
  onChange,
  empty,
  scroll = { x: 1000 },
  ...props
}) => {
  return (
    <>
      {(totalCount > 0 || renderEmptyTable) && (
        <ConfigProvider renderEmpty={() => empty}>
          <StyledTable
            className={type}
            scroll={scroll}
            size="small"
            pagination={
              showPagination
                ? {
                    showSizeChanger: false,
                    current: currentPage,
                    defaultCurrent: 1,
                    pageSize,
                    total: totalCount,
                    onChange
                  }
                : false
            }
            {...props}
          />
        </ConfigProvider>
      )}
      {totalCount <= 0 && totalCount !== null && !renderEmptyTable && empty}
      {totalCount === null && !renderEmptyTable && <TableSkeleton />}
    </>
  );
};

export default Table;

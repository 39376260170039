import { getProfile } from '../auth';
import { formatToISOString, queryString, removeEmpty } from '../formatters';
import request from '../request';

const getEmail = () => {
  const { email } = getProfile();
  return email;
};

const getOrders = async ({
  trackingNumber = '',
  clientId = '',
  status = '',
  firstTouchDate = []
}) => {
  const params = removeEmpty({
    trackingNumber,
    clientId,
    status,
    firstTouchDate: firstTouchDate.length > 0 ? firstTouchDate.map(formatToISOString) : null
  });

  params.email = getEmail();
  const queryParams = queryString(params);
  const url = `/pricing/generate-csv/orders?${queryParams}`;
  const response = await request().get(url);
  return response.data.code;
};

const getInvoices = async ({
  status = '',
  trackingNumber = '',
  invoiceNumber = '',
  billingStatus = '',
  paymentMode = '',
  clientID = '',
  action = '',
  createdDate = []
}) => {
  let defaultDateRange = [];
  let createdDateRange = createdDate.length ? createdDate : defaultDateRange;
  const params = removeEmpty({
    status: billingStatus,
    invoiceId: invoiceNumber,
    clientName: trackingNumber,
    clientID,
    action,
    paymentMode,
    created_date: createdDateRange?.map(formatToISOString) || [],
    billing_cycle: status
  });
  params.email = getEmail();
  const queryParams = queryString(params);
  const url = `/pricing/generate-csv/invoices?${queryParams}`;
  const response = await request().get(url);
  return response.data.code;
};

export const generateCsvServices = {
  getOrders,
  getInvoices
};

import styled from '@emotion/styled';
import { AutoComplete as AntAutoComplete } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

const StyledAutoComplete = styled.div`
  position: relative;
  .ant-select-auto-complete {
    border-radius: 4px;
    width: 100% !important;
  }

  .ant-input {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000;
    padding: 6px 11px 3px 11px;
  }

  .ant-input:hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #050593;
  }
  .ant-input:focus,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
`;

const AutoComplete = ({ onSearch, ...props }) => {
  return (
    <StyledAutoComplete>
      <AntAutoComplete
        {...props}
        onSearch={debounce(onSearch, 500)} // 300 is your required delay
      />
    </StyledAutoComplete>
  );
};

export default AutoComplete;

import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledActionLink = styled(Link)`
  color: #050593;
  &:hover {
    color: #050593;
  }
`;

const ActionLink = ({ children, ...props }) => {
  return (
    <StyledActionLink target="_blank" {...props}>
      {children}
    </StyledActionLink>
  );
};

export default ActionLink;

import { Modal, Table, Tag } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components';
import { formatDateDisplay } from '../../../utils/formatters';
import { clearDimWeights } from '../orders.action';

const DimWeightModal = ({ visible = false, setVisible = () => {} }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.orders.weights);

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      dispatch(clearDimWeights());
    }, 1000);
  };

  const columns = [
    {
      title: 'Dim Type',
      dataIndex: 'type',
      key: 'dimType',
      render: (text) => {
        switch (text) {
          case 1:
            return 'Janio';
          case 2:
            return 'Partner';
          case 3:
            return 'Client';
          default:
            return 'Default';
        }
      }
    },
    {
      title: 'Height (cm)',
      dataIndex: 'height',
      key: 'height',
      render: (text) => text || '-'
    },
    {
      title: 'Width (cm)',
      dataIndex: 'width',
      key: 'width',
      render: (text) => text || '-'
    },
    {
      title: 'Length (cm)',
      dataIndex: 'length',
      key: 'length',
      render: (text) => text || '-'
    },
    {
      title: 'Actual Weight (kg)',
      dataIndex: 'actual_weight',
      key: 'actualWeight',
      render: (text) => text || '-'
    },
    {
      title: 'Volumetric Weight (kg)',
      dataIndex: 'volumetric_weight',
      key: 'volumetricWeight',
      render: (text) => text || '-'
    },
    {
      title: 'Chargeable Weight (kg)',
      dataIndex: 'chargeable_weight',
      key: 'chargeableWeight',
      render: (text) => text || '-'
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'active',
      render: (text) => (text ? <Tag color="green">Active</Tag> : <Tag color="red">Unactive</Tag>)
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'createdAt',
      render: (text) => (text ? formatDateDisplay(text).format('DD MMM YYYY') : '-')
    }
  ];

  return (
    <Modal
      title="Dim Weight"
      width={1050}
      visible={visible}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="back" onClick={() => handleCancel()}>
          Return
        </Button>
      ]}
    >
      <Table size="small" rowKey={(row) => row.type} dataSource={data} columns={columns} />
    </Modal>
  );
};

export default DimWeightModal;

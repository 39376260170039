import React, { useEffect, useRef, useState } from 'react';
import { TEXT_NO_RESULT } from '../../constants/messages';
import Select from '../Select';

const { Option } = Select;
const _ = require('lodash');

const SearchInput = ({
  items = [],
  placeholder,
  value: outValue,
  allowClear = false,
  showSearch = true,
  disabled = false,
  onChange = () => {},
  ...restProps
}) => {
  const [value, setValue] = useState(() => {
    if (items.length > 0) {
      return outValue;
    }
    return null;
  });
  const [data, setData] = useState(items);
  const node = useRef();

  const handleSearch = (_value = '') => {
    if (value && _value) {
      setData(
        items.filter((item) =>
          _.get(item, 'label', '').toLowerCase().includes(_value.toLowerCase())
        )
      );
    } else {
      setData(items);
    }
  };

  useEffect(() => {
    if (items.length > 0) {
      setData(items);
      setValue(outValue);
    }
  }, [items, outValue]);

  return (
    <div ref={node}>
      <Select
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        disabled={disabled}
        loading={items.length < 1}
        value={value}
        optionFilterProp="children"
        onSearch={handleSearch}
        onChange={(_value, option) => onChange(_value, option)}
        notFoundContent={TEXT_NO_RESULT}
        getPopupContainer={() => node.current}
        {...restProps}
      >
        {data.map((item) => (
          <Option key={(item && item.value) || item} value={(item && item.value) || item}>
            {(item && item.label) || item}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SearchInput;

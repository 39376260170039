/** @jsxRuntime classic */
/** @jsx jsx */
import { CreditCardTwoTone, ExportOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Col, Modal, Row, Tooltip, message, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import {
  ActionLink,
  Button,
  Card,
  CreateTicketModal,
  SelectDropdown,
  Table
} from '../../components';
import { INVOICE_REMARK } from '../../constants';
import { formatDateDisplay } from '../../utils/formatters';
import { generateCsvFn } from '../../utils/generate-csv/generate-csv.action';
import request from '../../utils/request';
import { getChoices, getInvoices } from './invoices.action';
import FilterDropdown from './shared/FilterDropdown';

const { confirm } = Modal;

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const statusEnum = (text) => {
  if (text === 1) {
    return 'Draft';
  } else if (text === 2) {
    return 'Billed';
  } else if (text === 3) {
    return 'In Progress';
  } else if (text === 4) {
    return 'Error';
  }
};

const columns = [
  {
    title: 'Invoice Number',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    fixed: 'left',
    width: 160,
    render: (text, record) => (
      <React.Fragment>
        <ActionLink to={`/invoices/${record.invoiceDisplayId}`} status={record.status}>
          {record.invoiceDisplayId}
        </ActionLink>
        {record.payment_mode === 'pre-paid' && (
          <Tooltip title="Pre Paid">
            &nbsp;
            <CreditCardTwoTone />
          </Tooltip>
        )}
      </React.Fragment>
    )
  },
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName',
    render: (text, record) => <ActionLink to={`/clients/${record.clientID}`}>{text}</ActionLink>
  },
  {
    title: 'Created on',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (text) => (text ? formatDateDisplay(text).format('DD MMM YYYY') : null)
  },
  {
    title: 'Number of Shipments',
    dataIndex: 'numberOfShipments',
    key: 'numberOfShipments'
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => (text ? Math.round((Number(text) + Number.EPSILON) * 100) / 100 : null)
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency'
  },
  {
    title: 'Billing Cycle',
    dataIndex: 'billingCycle',
    key: 'billingCycle',
    render: (text) => (text ? formatDateDisplay(text).format('DD MMM YYYY') : null)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      return statusEnum(text);
    }
  },
  {
    title: 'Remark',
    dataIndex: 'remark',
    key: 'remark',
    width: 250,
    render: (text, record) => {
      const message = (text && INVOICE_REMARK[text]) || '-';
      if (message === INVOICE_REMARK.NON_COMPUTED_ORDERS) {
        return (
          <ActionLink to={`/non-computed-orders/${record.invoiceDisplayId}`}>{message}</ActionLink>
        );
      }

      return message;
    }
  }
];

const Invoices = () => {
  const key = 'generate-csv';
  const dispatch = useDispatch();
  const [tableColumns, setTableColumns] = useState([
    'invoiceNumber',
    'clientName',
    'createdOn',
    'service',
    'numberOfShipments',
    'amount',
    'currency',
    'billingCycle',
    'status',
    'remark'
  ]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [nonComputedOrderInvoices, setNonComputedOrderInvoices] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { code, loading: loadingCsv } = useSelector((state) => state.generateCsv);
  const { data, total: totalCount, loading } = useSelector((state) => state.invoices.invoices);

  const handleFilter = (filters, page = 1, size = 20) => {
    setPage(page);
    setSize(size);
    setFilters(filters);

    const recordFilters = {
      page,
      billingStatus: 1,
      createdDate: [moment().subtract(1, 'month').startOf('month'), moment().endOf('day')],
      size,
      ...filters
    };

    if (Object.keys(filters).length !== 0) {
      dispatch(getInvoices(recordFilters));
      setSelectedRowKeys([]);
      setSelectedRecords([]);
      setSelectedRows([]);
      setNonComputedOrderInvoices([]);
    }
  };

  const createInvoicesHandler = async () => {
    const key = `create-invoices-${Date.now()}`;

    try {
      await request().post('/invoices/bulk-export-invoices', {
        invoices: selectedRecords
      });

      const recordFilters = {
        page,
        billingStatus: 1,
        createdDate: [moment().subtract(1, 'month').startOf('month'), moment().endOf('day')],
        size,
        ...filters
      };
      setSelectedRowKeys([]);
      setSelectedRecords([]);
      notification.success({
        key,
        message: 'Invoices have now been sent for creation in Xero!',
        description:
          'This process may take up to 5 mins to complete depending on the number of invoices selected. Check back again later to verify that there were no errors during this process.',
        placement: 'bottomLeft',
        duration: 0
      });
      dispatch(getInvoices(recordFilters));
    } catch (err) {
      console.error(err);
      notification.error({
        key,
        message: `Create invoices in xero failed`,
        description: `We're having some trouble creating the invoices. Refresh the page or try again.`,
        placement: 'bottomLeft',
        duration: 0
      });
    }
  };

  const createInvoicesInXero = async () => {
    confirm({
      title: nonComputedOrderInvoices.length ? (
        <span>
          <b>Attention:</b>
          {` Following Invoices include non-computed orders. Confirm if you still want to create the selected invoices in Xero.`}
        </span>
      ) : (
        'Are you sure you want to create the selected invoices in Xero?'
      ),
      content: nonComputedOrderInvoices.length ? (
        <span>{`${nonComputedOrderInvoices.join(', ')}`}</span>
      ) : (
        ''
      ),
      okText: 'Confirm',
      onOk: async () => {
        return await createInvoicesHandler(1);
      }
    });
  };

  useEffect(() => {
    dispatch(getChoices());
  }, []);

  const handleExportCsv = () => {
    const recordFilters = {
      billingStatus: 1,
      createdDate: [moment().subtract(1, 'month').startOf('month'), moment().endOf('day')],
      ...filters
    };
    message.loading({ content: 'Sending request...', key, duration: 0 });
    dispatch(generateCsvFn('invoices', recordFilters));
  };

  const handleCreateTicket = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (code === 200) {
      message.success({
        content:
          'Your request was successfully captured and being process. It will send an email once done. Please wait.',
        key,
        duration: 5
      });
    } else if (code === 500) {
      message.error({
        content:
          'Failed to request CSV file. Please try again later or contact the technical team.',
        key,
        duration: 4
      });
    }
  }, [code]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selKey, selectedRows) => {
      const findnonComputedOrderInvoices = selectedRows
        .filter((a) => a.remark === 'NON_COMPUTED_ORDERS')
        ?.map((a) => a.invoiceDisplayId);
      const displayIds = selectedRows.map((a) => a.invoiceDisplayId);
      setSelectedRowKeys(selKey);
      setSelectedRecords(displayIds);
      setSelectedRows(selectedRows);
      setNonComputedOrderInvoices(findnonComputedOrderInvoices);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User' || record.status === 2 || record.status === 3,
      // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <div>
      <CreateTicketModal
        key={new Date().getTime()}
        data={selectedRows}
        visible={visible}
        setVisible={setVisible}
      />
      <StyledCard>
        <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
          <Col>
            <FilterDropdown
              onFilter={(filters) => handleFilter(filters, page, size)}
              loading={loading}
            />
          </Col>
          <Col>
            <SelectDropdown
              placeholder="Hide/Show Columns"
              multiple
              items={columns
                .map((column) => ({
                  label: column.title,
                  value: column.key
                }))
                .filter((column) => !['invoiceNumber'].includes(column.value))}
              value={[
                'clientName',
                'createdOn',
                'service',
                'numberOfShipments',
                'amount',
                'currency',
                'billingCycle',
                'invoiceNumber',
                'status',
                'remark'
              ]}
              onChange={(value) => {
                setTableColumns(['invoiceNumber', ...value]);
              }}
            />
          </Col>
          <Col sx={{ mr: 'auto' }}>
            <SelectDropdown
              value={size}
              items={[
                { label: '20 items/page', value: 20 },
                { label: '50 items/page', value: 50 },
                { label: '100 items/page', value: 100 }
              ]}
              onChange={(size) => handleFilter(filters, 1, size)}
            />
          </Col>
          <Col sx={{ ml: 'auto' }}>
            <Button danger onClick={() => handleCreateTicket()}>
              Create Ticket
            </Button>
          </Col>
          <Col>
            <Button disabled={selectedRecords.length == 0} onClick={() => createInvoicesInXero()}>
              Create Invoice in Xero
            </Button>
          </Col>
          <Col>
            <Button
              icon={<ExportOutlined />}
              disabled={loadingCsv}
              onClick={() => handleExportCsv()}
            >
              Generate CSV
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            rowKey={(row) => `${row.invoiceNumber}`}
            columns={columns.filter((column) => tableColumns.includes(column.key))}
            renderEmptyTable
            dataSource={data}
            pageSize={size}
            currentPage={page}
            totalCount={totalCount}
            onChange={(page) => handleFilter(filters, page, size)}
            scroll={{ x: 1500 }}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No Data found.</span>&nbsp;
                  </div>
                )}
                {loading && (
                  <AntSkeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
          />
        </Row>
      </StyledCard>
    </div>
  );
};
export default Invoices;

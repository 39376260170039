/** @jsxRuntime classic */
/** @jsx jsx */
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { Checkbox, Menu } from 'antd';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { jsx } from 'theme-ui';
import { useDeepCompareMemoize } from '../../utils/hooks';
import Dropdown from '../Dropdown';

const StyledSelectDropdown = styled.div`
  font-family: AvenirLTStdBook;
  .ant-dropdown-menu-item {
    font-size: 16px;
    user-select: none;
    color: #000;
  }
  .ant-checkbox-inner {
    border-color: #050593;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #050593;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #050593;
  }
  .ant-dropdown-menu-item:hover {
    background-color: #f2f2f9;
  }
  .active {
    background-color: #f2f2f9;
    color: #000;
  }
`;

const SelectDropdownItem = ({ checkbox, item, onChange = () => {} }) => {
  const [dropdownItem, setDropdownItem] = useState({});

  const setSelected = () => {
    const drodownItemUpdate = {
      ...dropdownItem,
      selected: !dropdownItem.selected
    };
    setDropdownItem(drodownItemUpdate);
    onChange(drodownItemUpdate);
  };

  const handleCheckboxChange = (e) => {
    const drodownItemUpdate = {
      ...dropdownItem,
      selected: e.target.checked
    };
    setDropdownItem(drodownItemUpdate);
    onChange(drodownItemUpdate);
  };

  useEffect(() => {
    setDropdownItem(item);
  }, useDeepCompareMemoize([item]));

  return (
    <Menu.Item
      onClick={setSelected}
      className={cx('ant-dropdown-menu-item', {
        active: !checkbox && dropdownItem.selected
      })}
    >
      {checkbox && (
        <Checkbox
          checked={dropdownItem.selected}
          onChange={handleCheckboxChange}
          sx={{
            pr: 2
          }}
        />
      )}
      {dropdownItem.label}
    </Menu.Item>
  );
};

const SelectDropdown = ({
  placeholder,
  multiple,
  items = [],
  value,
  disabled = false,
  onChange = () => {}
}) => {
  const node = useRef();
  const selectItemsRef = useRef();
  const selectValueRef = useRef();
  const [selectItems, setSelectItems] = useState(() => {
    return items.map((item) => {
      const dropdownItem = { ...item };
      if (multiple && Array.isArray(value)) {
        dropdownItem.selected = value.includes(item.value);
      } else {
        dropdownItem.selected = value === item.value;
      }
      return dropdownItem;
    });
  });
  const [selectValue, setSelectValue] = useState(() => {
    if (multiple) {
      return selectItems.reduce((dItems, dItem) => {
        if (dItem.selected) {
          dItems.push(dItem.value);
        }
        return dItems;
      }, []);
    }
    const dropdownSelected = selectItems.find((dropdownItem) => dropdownItem.selected);
    return dropdownSelected.value;
  });
  const [visible, setVisible] = useState(false);
  const [label, setLabel] = useState();

  // Handles collapse of dropdown container logic
  useEffect(() => {
    const handleMenuClick = (e) => {
      if (!node.current.contains(e.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
    };
  }, [multiple]);

  // Handles label change logic and values selected
  useEffect(() => {
    let dropdownLabel = placeholder;
    if (!placeholder && !multiple && !Array.isArray(selectValue)) {
      if (selectValue) {
        const itemLabel = items.find((item) => item.value === selectValue);
        dropdownLabel = itemLabel?.label;
      } else if (items.length > 0) {
        dropdownLabel = items[0].label;
      }
    }
    setLabel(dropdownLabel || 'Select');
    if (!isEqual(selectValueRef.current, selectValue)) {
      onChange(selectValue);
      selectValueRef.current = selectValue;
    }
  }, [items, multiple, onChange, placeholder, selectValue]);

  // Handles logic when local value state changes
  useEffect(() => {
    let updatedSelectedItems = [];
    if (multiple) {
      updatedSelectedItems = selectItems.map((selectItem) => {
        const sItem = { ...selectItem };
        if (selectValue.includes(selectItem.value)) {
          sItem.selected = true;
        } else {
          sItem.selected = false;
        }
        return sItem;
      });
      setSelectItems(updatedSelectedItems);
    } else {
      updatedSelectedItems = selectItems.map((selectItem) => {
        const sItem = { ...selectItem };
        if (selectItem.value === selectValue) {
          sItem.selected = true;
        } else sItem.selected = false;
        return sItem;
      });
      setSelectItems(updatedSelectedItems);
    }
    selectItemsRef.current = updatedSelectedItems;
  }, useDeepCompareMemoize([multiple, selectItems, selectValue]));

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleDropdownSelect = (item) => {
    if (multiple) {
      if (item.selected) {
        setSelectValue([...new Set([...selectValue, item.value])]);
      } else {
        setSelectValue(selectValue.filter((itemValue) => itemValue !== item.value));
      }
    } else {
      setSelectValue(item.value);
      setVisible(false);
    }
  };

  return (
    <StyledSelectDropdown ref={node} className="select-dropdown">
      <Dropdown
        label={label}
        type="secondary"
        multiple={multiple}
        visible={visible}
        disabled={disabled}
        onClick={handleDropdownClick}
        getPopupContainer={() => node.current}
      >
        <Menu>
          {selectItems.map((item) => (
            <SelectDropdownItem
              checkbox={multiple}
              item={item}
              key={item.value}
              onChange={handleDropdownSelect}
            />
          ))}
        </Menu>
      </Dropdown>
    </StyledSelectDropdown>
  );
};

export default SelectDropdown;

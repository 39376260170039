import { Result, Tooltip, Typography } from 'antd';
import React from 'react';
import Button from '../Button';
import Modal from '../Modal';

const { Paragraph, Text } = Typography;

const ErrorMessage = ({ message = '', visible = false, setVisible = () => {} }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(message);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={820}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      zIndex={1001}
    >
      <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
        extra={
          <Tooltip placement="top" title="Copied!" trigger="click" zIndex={1002}>
            <Button type="secondary" onClick={copyToClipboard}>
              Copy to clipboard
            </Button>
          </Tooltip>
        }
      >
        <div className="desc">
          <Paragraph>
            <Text strong style={{ fontSize: 16 }}>
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph style={{ whiteSpace: 'pre-wrap' }}>{message}</Paragraph>
        </div>
      </Result>
    </Modal>
  );
};

export default ErrorMessage;

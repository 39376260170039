// import { CloseOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Modal, Spin, Tooltip, message, notification } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, ErrorMessage, TextArea } from '../../../components';
import { PRICING_GROUP_TYPES } from '../../../constants';
import MESSAGES from '../../../constants/messages';
import { getPricingGroups, resetFilters } from '../pricing-group-manage.action';
import service from '../pricing-group-manage.service';

const StyledDiv = styled.div`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  .info {
    font-family: AvenirLTStdBook;
    color: #707070;
  }
`;

const ItemDiv = styled.div`
  margin-bottom: 30px;
`;

const initialState = {
  name: '',
  startDate: undefined,
  endDate: undefined,
  file: null
};

const disabledDate = moment().subtract(1, 'days');

const handleDateFormat = (text) => moment.utc(text).format('DD MMM YYYY');

const EditPricingGroupModal = ({ data = {}, visible = false, setVisible = () => {} }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  // const [filename, setFilename] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);
  const [{ name, startDate, endDate, file }, setState] = useState(initialState);

  const handleDisabledDate = (date, allowPastDate) => (current) => {
    if (allowPastDate) {
      return current && current < moment().subtract(12, 'months').endOf('day');
    }

    if (current > date) {
      return current && current < moment().subtract(1, 'days').endOf('day');
    }

    return current && current < date.endOf('day');
  };

  const handleText = (e) => {
    const { value } = e.target;
    setState((prevState) => ({ ...prevState, name: value }));
  };

  const handleDate = (name) => (value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'startDate' && value > endDate) {
      setError(true);
    } else {
      setError(false);
    }
  };

  // const handleFile = (newFile) => {
  //   setState((prevState) => ({ ...prevState, file: newFile }));
  //   setFilename(newFile.name);
  //   return false;
  // };

  // const handleError = () => {
  //   if (endDate && startDate > endDate) {
  //     return 'ant-picker-error';
  //   }
  // };

  const handleOk = () => {
    const key = `edit-pricing-group-${new Date().getTime()}`;
    setLoading(true);

    const body = {};

    if (data.name !== name.trim()) {
      body.name = name.trim();
    }

    if (handleDateFormat(data.start_date) !== handleDateFormat(startDate.toISOString())) {
      body.start_date = moment.utc(startDate).format('YYYY-MM-DDT00:00:00') + 'Z';
    }

    if (!endDate || handleDateFormat(data.end_date) !== handleDateFormat(endDate.toISOString())) {
      body.end_date = endDate ? moment.utc(endDate).format('YYYY-MM-DDT23:59:59') + 'Z' : '';
    }

    if (file) {
      body.file = file;
    }

    if (!Object.keys(body).length) {
      message.error({ content: 'There is no changes made', key });
      setLoading(false);
      return;
    }

    service
      .editPricingGroup(data.name, body)
      .then(() => {
        setLoading(false);
        setVisible(false);
        dispatch(resetFilters());
        dispatch(getPricingGroups());
        notification.success({
          key,
          message: `You have edited the pricing group`,
          description: `You may continue reviewing it.`,
          placement: 'bottomLeft'
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (err.result) {
          setErrorMessage(err.result);
          setErrorVisible(true);
        } else {
          notification.error({
            key,
            message: `Could not edit the pricing group`,
            description: MESSAGES.M0001,
            placement: 'bottomLeft',
            duration: 0
          });
        }
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const afterClose = () => {
    setState({ ...initialState });
  };

  useEffect(() => {
    setState({
      name: data.name,
      startDate: moment.utc(data.start_date),
      endDate: data.end_date ? moment.utc(data.end_date) : undefined
    });
  }, [data]);

  return (
    <Fragment>
      <ErrorMessage message={errorMessage} visible={errorVisible} setVisible={setErrorVisible} />
      <Modal
        title="Edit pricing group"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={820}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Tooltip key="back" title="Your changes won't be saved">
            <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
              Cancel
            </Button>
          </Tooltip>,
          <Button key="submit" loading={loading} disabled={error} onClick={handleOk}>
            Submit
          </Button>
        ]}
      >
        <Spin spinning={loading}>
          <StyledDiv>
            <ItemDiv>
              <label htmlFor="name">Name</label>
              <TextArea
                name="name"
                value={name}
                maxLength={255}
                onChange={handleText}
                disabled={data.type === PRICING_GROUP_TYPES.PUBLISHED || false}
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            </ItemDiv>

            <ItemDiv>
              <label htmlFor="start-date">Start Date</label>
              <DatePicker
                id="start-date"
                value={startDate}
                allowClear={false}
                onChange={handleDate('startDate')}
                disabledDate={handleDisabledDate(disabledDate, true)}
              />
            </ItemDiv>

            {/* <ItemDiv>
            <label htmlFor="end-date">
              End Date <span className="info">(Optional)</span>
            </label>
            <DatePicker
              id="end-date"
              className={handleError()}
              value={endDate}
              allowClear
              onChange={handleDate('endDate')}
              disabledDate={handleDisabledDate(startDate)}
            />
          </ItemDiv> */}

            {/* <ItemDiv>
              <Row gutter={16}>
                <Col>
                  Add new rules{' '}
                  <Tooltip placement="right" title="Only accept .csv file">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
                <Col style={{ marginLeft: 'auto' }}>
                  <Upload
                    style={{ width: 250 }}
                    accept=".csv"
                    showUploadList={false}
                    beforeUpload={handleFile}
                  >
                    <Button
                      type="secondary"
                      style={{ width: 'auto' }}
                      icon={filename ? <CloseOutlined /> : <UploadOutlined />}
                    >
                      {filename || 'Upload'}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </ItemDiv> */}
          </StyledDiv>
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default EditPricingGroupModal;

import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPricingGroups,
  fetchPricingGroupsLoading,
  resetFilters,
  setFilters
} from './pricing-group-manage.action';

const initialState = {
  data: null,
  total: 0,
  loading: false,
  filters: {
    page: 1,
    name: null,
    startDate: []
  }
};

export default createReducer(initialState, {
  [fetchPricingGroupsLoading](state, action) {
    state.loading = action.payload;
  },
  [fetchPricingGroups](state, action) {
    const { pricing_groups, total } = action.payload;
    state.data = pricing_groups;
    state.total = total;
    state.loading = false;
  },
  [setFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
    state.data = initialState.data;
  }
});

import request from '../../utils/request';

const getNonComputedOrders = async (id) => {
  const url = `/billing-records/non-computed-orders/${id}`;
  const response = await request().get(url);
  return response.data.data;
};

export const nonComputedOrdersService = {
  getNonComputedOrders
};

/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { Skeleton as AntSkeleton, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from 'theme-ui';
import { ActionLink, Card, SelectDropdown, Table } from '../../components';
import { formatDateDisplay, getFullname } from '../../utils/formatters';
import { getPricingGroups } from './pricing-groups.action';
import FilterDropdownPricingGroups from './shared/FilterDropdownPricingGroups';

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const PricingGroups = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [filters, setFilters] = useState({});
  const { data, total, loading } = useSelector((state) => state.pricingGroups);

  const columns = [
    {
      title: 'Pricing Group',
      dataIndex: 'name',
      key: 'name',
      width: 550,
      render: (text) => (
        <ActionLink to={`/pricing-groups/${encodeURIComponent(text)}`}>{text}</ActionLink>
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150
    },
    {
      title: 'Effective Date',
      dataIndex: 'effective_date',
      key: 'effective_date',
      width: 200,
      render: (text) => formatDateDisplay(text).utc().format('DD MMM YYYY')
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      width: 200,
      render: (text) => formatDateDisplay(text).utc().format('DD MMM YYYY')
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      width: 200,
      render: (text) => (text ? getFullname(text) : 'System')
    }
  ];

  const handleFilter = (filters, page = 1, size = 50) => {
    setPage(page);
    setSize(size);
    setFilters(filters);

    const recordFilters = { page, size, ...filters };
    if (Object.keys(filters).length !== 0) {
      dispatch(getPricingGroups(recordFilters));
    }
  };

  useEffect(() => {
    const recordFilters = {
      page,
      size,
      ...filters
    };

    dispatch(getPricingGroups(recordFilters));
  }, [dispatch, page, size, filters]);

  return (
    <StyledCard>
      <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
        <Col>
          <FilterDropdownPricingGroups onFilter={handleFilter} />
        </Col>
        <Col sx={{ ml: 'auto' }}>
          <SelectDropdown
            value={size}
            items={[
              { label: '20 items/page', value: 20 },
              { label: '50 items/page', value: 50 },
              { label: '100 items/page', value: 100 }
            ]}
            onChange={(size) => handleFilter(filters, 1, size)}
          />
        </Col>
      </Row>
      <Row>
        <Table
          loading={loading}
          rowKey={(row) => `${row.name}`}
          columns={columns}
          renderEmptyTable
          dataSource={data}
          pageSize={size}
          currentPage={page}
          totalCount={total}
          onChange={(page) => handleFilter(filters, page, size)}
          // scroll={{ x: 1700 }}
          empty={
            <React.Fragment>
              {!loading && (
                <div>
                  <span>No data found.</span>&nbsp;
                </div>
              )}
              {loading && (
                <AntSkeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 5,
                    width: ['100%', '100%', '100%', '100%', '100%']
                  }}
                />
              )}
            </React.Fragment>
          }
        />
      </Row>
    </StyledCard>
  );
};
export default PricingGroups;

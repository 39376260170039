import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledActionButton = styled(Link)`
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  background-color: transparent;
  border-color: transparent;

  color: #050593;
  text-decoration: underline;

  &:hover {
    color: #050593;
  }

  &.none,
  &.none:hover,
  &.awaiting-merchant,
  &.awaiting-merchant:hover {
    color: #000;
    text-decoration: none;
    pointer-events: none;
  }
`;

const actionClassName = {
  'Awaiting Merchant': 'awaiting-merchant',
  None: 'none'
};

const ActionButton = ({ children, ...props }) => {
  return (
    <StyledActionButton className={actionClassName[children]} {...props}>
      {children}
    </StyledActionButton>
  );
};

export default ActionButton;

import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';

export const formatAddress = (address) =>
  get(address, 'location_name')
    ? `${address.location_name}, ${address.street_address}, ${address.city}, ${address.state}, ${address.postal_code}`
    : `${address.street_address}, ${address.city}, ${address.state}, ${address.postal_code}`;

export const removeEmpty = (obj) => {
  const cleanObj = { ...obj };
  Object.keys(cleanObj).forEach((key) => !cleanObj[key] && delete cleanObj[key]);
  return cleanObj;
};

export const queryString = (obj) => {
  const params = removeEmpty(obj);
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const formatToISOString = (dateMoment) => {
  return dateMoment === null ? null : moment(dateMoment).toISOString();
};

export const formatDateDisplay = (dateString) => {
  return dateString === null ? null : moment(dateString);
};

export const convertSingaporeTimeToLocal = (dateString) => {
  if (!dateString) return dateString;
  let singaporeTime = (dateString = dateString.replace('Z', ''));
  singaporeTime = momentTZ.tz(singaporeTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Asia/Singapore');
  const localTime = singaporeTime.local();
  return localTime;
};

export const formatToMoment = (dateString) => {
  return dateString ? moment(dateString, 'YYYY-MM-DD') : null;
};

export const handleAbbrevCase = (text) => {
  const titleCaseAbbrv = text.replace(/\(.*\)/g, (str) => {
    return str.toUpperCase();
  });
  return titleCaseAbbrv;
};

export const titleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatBytesToMegabytes = (bytes) => (bytes / (1000 * 1000)).toFixed(2);

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat(bytes / Math.pow(k, i)).toFixed(dm) + ' ' + sizes[i];
};

export const formatLabels = (str = '') => str.replaceAll('_', ' ');

export const getFullname = (email = 'systems.finance@janio.asia') => {
  const nickname = email.slice(0, email.indexOf('@')).split('.');

  if (nickname.length !== 2) {
    return '-';
  }

  const firstname = nickname[0].charAt(0).toUpperCase() + nickname[0].slice(1);
  const lastname = nickname[1].charAt(0).toUpperCase() + nickname[1].slice(1);
  return `${firstname} ${lastname}`;
};

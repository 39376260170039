import styled from '@emotion/styled';
import React from 'react';

const StyledAnchor = styled.a`
  color: #050593;
  &:hover {
    color: #050593;
  }
`;

const Anchor = ({ children, ...props }) => {
  return <StyledAnchor {...props}>{children}</StyledAnchor>;
};

export default Anchor;

import { createAction } from '@reduxjs/toolkit';
import service from '../billing-records/billing-records.service';

export const fetchBillingRecords = createAction('invoices/FETCH_DATA');
export const fetchBillingLoading = createAction('invoices/FETCH_DATA_LOADING');
export const fetchInvoiceDetail = createAction('invoices/FETCH_INVOICE_DATA');
export const resetDetails = createAction('invoices/RESET_DETAIL');

export const getBillingRecordsPerInvoice =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchBillingLoading());
    service
      .getBillingRecords(params)
      .then((data) => {
        dispatch(fetchBillingRecords(data));
      })
      .catch(() => {
        dispatch(fetchBillingRecords({ invoice: [], total: 0 }));
      });
  };

export const getInvoiceDetail =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchBillingLoading());
    service.getInvoiceDetails(params).then((data) => {
      dispatch(fetchInvoiceDetail(data));
    });
  };

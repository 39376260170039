import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPricingGroups,
  fetchPricingGroupsLoading,
  resetFilters,
  setFilters
} from './pricing-groups.action';

const initialState = {
  data: [],
  total: 0,
  loading: false,
  filters: {
    pricingGroup: null,
    type: 'All'
  }
};

export default createReducer(initialState, {
  [fetchPricingGroups](state, action) {
    const { pricing_groups, total } = action.payload;
    state.data = pricing_groups;
    state.total = total;
    state.loading = false;
  },
  [fetchPricingGroupsLoading](state, action) {
    state.loading = action.payload;
  },
  [setFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
    state.data = initialState.data;
  }
});

import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input } from '../../../components';
import { resetFilters, setFilters } from '../clients.action';

const FilterContainer = styled.div`
  user-select: none;
  width: 1000px;
  height: 170px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 5px 8px !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdown = ({ label, loading: isLoading, onFilter = () => {} }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();

  const id = useSelector((state) => state?.clients?.filters?.id);
  const name = useSelector((state) => state?.clients?.filters?.name);
  const email = useSelector((state) => state?.clients?.filters?.email);

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };

  const handleFilter = () => {
    onFilter({
      id,
      name,
      email
    });
    setVisible(false);
  };

  const handleOnChange = (field) => (e) => {
    dispatch(setFilters({ [field]: e.target.value.trim() }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Filter'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]} className="field-row">
            <Col span={8} className="field-container">
              <label htmlFor="client-id">Client ID</label>
              <Input
                id="client-id"
                placeholder="Type here"
                value={id}
                onChange={handleOnChange('id')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="name">Name</label>
              <Input
                id="name"
                placeholder="Type here"
                value={name}
                onChange={handleOnChange('name')}
              />
            </Col>
            <Col span={8} className="field-container">
              <label htmlFor="name">Email</label>
              <Input
                id="email"
                placeholder="Type here"
                value={email}
                onChange={handleOnChange('email')}
              />
            </Col>
          </Row>
          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              onClick={handleReset}
              disabled={isLoading}
            >
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleFilter} disabled={isLoading}>
              Filter
            </Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdown;

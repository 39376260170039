import request from '../../utils/request';

const getClientDetails = async (id) => {
  const url = `/clients/${id}`;
  const response = await request().get(url);
  const client = response.data.data;

  return {
    name: client.name,
    email: client.email,
    contact: client.phone,
    billing_entity: client.billing_entity || '',
    consolidate_invoice: client.consolidate_invoice || false,
    daily_billing: client.daily_billing || false,
    day_of_month: client.day_of_month,
    dim_weight_preference: client.dim_weight_preference || undefined,
    days_before_billing_created:
      client.days_before_billing_created || client.days_before_billing_created === 0
        ? client.days_before_billing_created.toString()
        : undefined,
    weight_policy: client.weight_policy || undefined,
    volumetric_weight_factors: client.volumetric_weight_factors,
    first_touch_statuses: client.first_touch_statuses,
    tiers: client.tiers,
    surcharges: client.surcharges,
    global_pricing_groups: client.global_pricing_groups,
    custom_pricing_groups: client.custom_pricing_groups,
    has_postpaid_only: client.allowedPastEndDateSelect
  };
};

const editClientConfig = async (id, body) => {
  const url = `/clients/${id}`;
  const response = await request().put(url, body);
  return response.data.data;
};

const mappingClientPricingGroup = async (body) => {
  const url = `/clients/pricing-groups/mapping`;
  return await request().post(url, body);
};

const expiringClientPricingGroup = async (id, body) => {
  const url = `/clients/pricing-groups/${id}/expiring`;
  return await request().patch(url, body);
};

const getPricingGroupsWithEffectiveDate = async (txt) => {
  const url = `/pricing/pricing-groups/effective-date/?pricing_group=${txt}&pricing_group_type=CUSTOM`;
  const response = await request().get(url);
  return response.data.data;
};

const getPricingGroupZones = async (name) => {
  const url = `/pricing/pricing-groups/${name}/zones`;
  const response = await request().get(url);
  return response.data.data;
};

export const clientService = {
  getClientDetails,
  editClientConfig,
  mappingClientPricingGroup,
  expiringClientPricingGroup,
  getPricingGroupsWithEffectiveDate,
  getPricingGroupZones
};

import styled from '@emotion/styled';
import { Badge, Modal, Timeline } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components';
import { formatDateDisplay } from '../../../utils/formatters';
import { clearOrderStatuses } from '../orders.action';

const { Item } = Timeline;

const BadgeContainer = styled.div`
  position: absolute;
  top: 73px;
  right: 20px;
`;

const StatusModal = ({ visible = false, setVisible = () => {} }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.orders.statuses);

  const handleSubtitle = (user) => {
    return `Updated by ${user}`;
  };

  const handleDescriptions = (status) => {
    const timestamp = formatDateDisplay(status.updated_on);
    const date = timestamp.format('DD MMM YYYY');
    const time = timestamp.format('hh:mm a');
    return `${date} at ${time}`;
  };

  const handleTitle = (title) => {
    const str = title.toLowerCase();
    const arr = str.split('_');

    for (let i = 0; i < arr.length; i += 1) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(' ');
  };

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      dispatch(clearOrderStatuses());
    }, 1000);
  };

  return (
    <Modal
      title="Order Status"
      visible={visible}
      onCancel={() => handleCancel()}
      footer={[
        <Button key="back" onClick={() => handleCancel()}>
          Return
        </Button>
      ]}
    >
      <BadgeContainer>
        <Badge status="green" text="Public Status" />
        <br />
        <Badge status="yellow" text="Private Status" />
      </BadgeContainer>
      <Timeline>
        {data.map((status, i) => (
          <Item key={`${status.updated_on + i}`} color={status.is_private ? 'green' : 'yellow'}>
            <h6>{handleTitle(status.status)}</h6>
            <p>{handleDescriptions(status)}</p>
            <p style={{ color: 'gray' }}>{handleSubtitle(status.partner_name)}</p>
          </Item>
        ))}
      </Timeline>
    </Modal>
  );
};

export default StatusModal;

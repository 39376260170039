import { queryString } from '../../utils/formatters';
import request from '../../utils/request';

const getPricingGroupDetails = async (name) => {
  const url = `/pricing-groups/manage/${name}`;
  const response = await request().get(url);
  return response.data.data;
};

const getPricingGroupRules = async (name, { page = 1, size = 50 }) => {
  const queryParams = queryString({ page, size });
  const url = `/pricing-groups/manage/${name}/rules?${queryParams}`;
  const response = await request().get(url);
  return response.data.data;
};

export default {
  getPricingGroupDetails,
  getPricingGroupRules
};

import styled from '@emotion/styled';
import { Tabs } from 'antd';
import React from 'react';

const StyledTabContainers = styled.div`
  .ant-tabs {
    overflow: visible !important;
  }
  .ant-tabs-tab {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    @media (min-width: 1600px) {
      padding: 10px 32px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #707070;
  }
  .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    font-family: AvenirLTStdHeavy;
    color: #050593;
  }
  .ant-tabs-ink-bar {
    background-color: #050593;
  }
  .ant-tabs-bar {
    border-bottom-color: #1fceca;
  }
`;

const { TabPane } = Tabs;

const StyledTabs = (props) => {
  const { handleTabChange, activeTab, tabs } = props;
  return (
    <StyledTabContainers>
      <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
        {Object.entries(tabs).map((obj) => {
          const [key, tab] = obj;
          const { title } = tab;
          return <TabPane tab={title} key={key}></TabPane>;
        })}
      </Tabs>
    </StyledTabContainers>
  );
};
export default StyledTabs;

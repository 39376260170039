import styled from '@emotion/styled';
import React from 'react';
import { Card } from '../../components';
import { withRouter } from '../../utils/wrappers';
import { TicketDetails as TicketInfo } from './shared';

const StyledDetails = styled.div`
  max-width: 1200px;
  width: auto;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  padding: 30px 40px;
`;

const TicketDetails = (props) => {
  const { ticketId } = props.params;

  return (
    <StyledDetails>
      <StyledCard>
        <TicketInfo ticketId={ticketId} />
      </StyledCard>
    </StyledDetails>
  );
};

export default withRouter(TicketDetails);

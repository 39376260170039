import { createReducer } from '@reduxjs/toolkit';
import {
  editTicketDetails,
  editTicketDetailsLoading,
  fetchComments,
  fetchCommentsLoading,
  fetchTicketDetail,
  fetchTicketDetailsLoading
} from './ticket-details.action';

const initialState = {
  data: {},
  loading: false,
  edit: {
    name: '',
    loading: false
  },
  comments: {
    data: [],
    total: 0,
    loading: false
  }
};

export default createReducer(initialState, {
  [fetchTicketDetailsLoading](state, action) {
    state.loading = action.payload;
  },
  [fetchTicketDetail](state, action) {
    state.data = action.payload;
    state.loading = false;
  },
  [editTicketDetailsLoading](state, action) {
    state.edit.loading = action.payload;
  },
  [editTicketDetails](state, action) {
    state.edit.name = action.payload;
    state.edit.loading = false;
  },
  [fetchCommentsLoading](state, action) {
    state.comments.loading = action.payload;
  },
  [fetchComments](state, action) {
    const { results, total } = action.payload;
    state.comments.data = results;
    state.comments.total = total;
    state.comments.loading = false;
  }
});

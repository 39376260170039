import { createReducer } from '@reduxjs/toolkit';
import { fetchReportLoading, fetchReports } from './reports.action';

const initialState = {
  data: [],
  loading: false
};

const reports = createReducer(initialState, {
  [fetchReports](state, action) {
    const data = action.payload;
    state.data = data;
    state.loading = false;
  },
  [fetchReportLoading](state, action) {
    state.loading = action.payload;
  }
});

export default reports;

import { Col, List, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

const MappedGlobalPricingGroups = ({ mappedGlobalPricings = [] }) => {
  const [globalPricings, setGlobalPricings] = useState([]);
  const [viewAllGlobalPricings, setViewAllGlobalPricings] = useState(false);

  useEffect(() => {
    if (mappedGlobalPricings.length) {
      setGlobalPricings(mappedGlobalPricings.slice(0, 5));
    }
  }, [mappedGlobalPricings]);

  useEffect(() => {
    if (viewAllGlobalPricings) {
      setGlobalPricings(mappedGlobalPricings);
    } else {
      setGlobalPricings(mappedGlobalPricings.slice(0, 5));
    }
  }, [viewAllGlobalPricings]);

  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        <h5>Mapped Global Pricing Group</h5>
        <div>
          <List
            header={
              <Row gutter={[24, 16]}>
                <Col span={12}>
                  <span className="header">Pricing Group</span>
                </Col>
                <Col span={6}>
                  <span className="header">Start Date</span>
                </Col>
                <Col span={6}>
                  <span className="header">End Date</span>
                </Col>
              </Row>
            }
            footer={
              (globalPricings.length !== mappedGlobalPricings.length ||
                mappedGlobalPricings.length > 5) && (
                <div style={{ textAlign: 'center' }}>
                  <>
                    {!viewAllGlobalPricings && (
                      <span className="view-link" onClick={() => setViewAllGlobalPricings(true)}>
                        View All
                      </span>
                    )}
                    {viewAllGlobalPricings && (
                      <span className="view-link" onClick={() => setViewAllGlobalPricings(false)}>
                        View Less
                      </span>
                    )}
                  </>
                </div>
              )
            }
            bordered
            size="small"
            dataSource={globalPricings}
            renderItem={(item) => (
              <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                <Col span={12}>
                  <span className="value">{item.pricing_group}</span>
                </Col>
                <Col span={6}>
                  <span className="value">
                    {item.start_date ? moment.utc(item.start_date).format('DD MMM YYYY') : '-'}
                  </span>
                </Col>
                <Col span={6}>
                  <span className="value">
                    {item.end_date ? moment.utc(item.end_date).format('DD MMM YYYY') : '-'}
                  </span>
                </Col>
              </Row>
            )}
          />
        </div>
      </Col>
    </Row>
  );
};

export default MappedGlobalPricingGroups;

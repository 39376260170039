import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/Dropdown';
import Input from '../../../components/Input';
import Message from '../../../components/Message';
import SearchInput from '../../../components/SearchInput';
import { resetFilters, setInvoiceFilters } from '../invoices.action';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  user-select: none;
  width: 1000px;
  height: 270px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 5px 8px !important;
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdown = ({ label, loading: isLoading, onFilter = () => {} }) => {
  const node = useRef();
  const [visible, setVisible] = useState();
  const [createdDates, setCreatedDates] = useState([]);
  const dispatch = useDispatch();

  const { billingCycles, loading } = useSelector((state) => state.invoices.choices);
  const status = useSelector((state) => state.invoices.filters.status);
  const trackingNumber = useSelector((state) => state.invoices.filters.trackingNumber);
  const billingStatus = useSelector((state) => state.invoices.filters.billingStatus);
  const paymentMode = useSelector((state) => state.invoices.filters.paymentMode);
  const action = useSelector((state) => state.invoices.filters.action);
  const invoiceNumber = useSelector((state) => state.invoices.filters.invoiceNumber);
  const createdDate = useSelector((state) =>
    state.invoices.filters.createdDate
      ? state.invoices.filters.createdDate?.map((d) => moment(d)) || []
      : []
  );

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };
  const handleFilter = () => {
    onFilter({
      status: status === 'All' ? null : status,
      trackingNumber,
      invoiceNumber,
      billingStatus,
      paymentMode,
      action: action === 'All' ? null : action,
      createdDate
    });
    setVisible(false);
  };

  const disabledCreatedDates = (current) => {
    if (!createdDates || createdDates.length === 0) {
      return false;
    }
    const startLimit = moment(createdDates[0]).subtract(3, 'months');
    const endLimit = moment(createdDates[0]).add(3, 'months');
    return current.valueOf() < startLimit.valueOf() || current.valueOf() > endLimit.valueOf();
  };

  const onOpenChange = (open) => {
    if (open) {
      setCreatedDates([]);
    }
  };
  const handleRangeDatePickerChange = (field) => (value) => {
    dispatch(
      setInvoiceFilters({
        [field]: value?.length
          ? [value[0]?.startOf('day').toISOString(), value[1]?.endOf('day').toISOString()]
          : []
      })
    );
  };
  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setInvoiceFilters({ [field]: option?.props?.value }));
  };

  const handleTrackerChange = (e) => {
    dispatch(setInvoiceFilters({ trackingNumber: e.target.value.trim() }));
  };
  const handleInvoiceChange = (e) => {
    dispatch(setInvoiceFilters({ invoiceNumber: e.target.value.trim() }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  const isCreatedDateRequired = () => {
    if ((status && status !== 'All') || !!invoiceNumber || createdDate.length) {
      return false;
    }
    return true;
  };

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label={label || 'Filter'}
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <header>By Invoice</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="tracking-number">Invoice Number</label>
                  <Input
                    id="tracking-number"
                    placeholder="Type or select..."
                    value={invoiceNumber}
                    onChange={handleInvoiceChange}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="tracking-number">Client Name</label>
                  <Input
                    id="tracking-number"
                    placeholder="Type or select..."
                    value={trackingNumber}
                    onChange={handleTrackerChange}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="billing-status">Billing Status</label>
                  <SearchInput
                    allowClear
                    placeholder="Type or select..."
                    id="billing-status"
                    items={[
                      { value: 1, key: 1, label: 'Draft' },
                      { value: 2, key: 2, label: 'Billed' },
                      { value: 3, key: 3, label: 'In Progress' },
                      { value: 4, key: 4, label: 'Error' }
                    ]}
                    // loading={loading}
                    value={billingStatus}
                    onChange={handleSearchInputChange('billingStatus')}
                  />
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="payment-mode">Payment Mode</label>
                  <SearchInput
                    allowClear
                    placeholder="Type or select..."
                    id="payment-mode"
                    items={[
                      { value: 'pre-paid', key: 1, label: 'Pre Paid' },
                      { value: 'post-paid', key: 2, label: 'Post Paid' }
                    ]}
                    value={paymentMode}
                    onChange={handleSearchInputChange('paymentMode')}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <header>By Date</header>
              <Row gutter={[16, 16]} className="field-row">
                <Col span={12} className="field-container">
                  <label htmlFor="from-date">Created Date*</label>
                  <RangePicker
                    id="createdDate"
                    onCalendarChange={(val) => setCreatedDates(val)}
                    disabledDate={disabledCreatedDates}
                    value={createdDate}
                    onChange={handleRangeDatePickerChange('createdDate')}
                    onOpenChange={onOpenChange}
                    allowClear={true}
                    contained
                  />
                  {isCreatedDateRequired() && (
                    <Message type="error">Please select Created Date</Message>
                  )}
                </Col>
                <Col span={12} className="field-container">
                  <label htmlFor="quote-public-status">Billing Cycle</label>
                  <SearchInput
                    id="quote-public-status"
                    items={billingCycles}
                    loading={loading}
                    value={status}
                    allowClear
                    onChange={handleSearchInputChange('status')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="actions">
            <Button
              type="link"
              size="small"
              className="reset-filter"
              onClick={handleReset}
              disabled={isLoading}
            >
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleFilter} disabled={isLoading || isCreatedDateRequired()}>
              Filter
            </Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdown;

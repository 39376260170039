import { formatLabels, queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';
import { getReporter } from '../../utils/serializers';

const getTickets = async ({
  page = 1,
  per_page: maxResults,
  status,
  issueKey,
  summary,
  priority,
  created = []
}) => {
  let startAt = (page - 1) * maxResults;
  const jqlParams = {
    project: process.env.REACT_APP_JIRA_PROJECT_ID,
    issueKey,
    priority
  };
  let jqlQuery = Object.keys(removeEmpty(jqlParams))
    .map((key) => `${key}=${jqlParams[key]}`)
    .join(' AND ');

  if (summary) {
    jqlQuery += ` AND summary ~ '${summary}'`;
  }

  if (status) {
    if (status !== 'All') {
      jqlQuery += ` AND status = ${status}`;
    }
  } else {
    var statusMaster = process.env.REACT_APP_JIRA_STATUS;
    if (statusMaster) {
      const statusArray = statusMaster.split(',');
      jqlQuery += ` AND status IN ('${statusArray[0]}', '${statusArray[1]}')`;
    }
  }

  if (created && created.length) {
    jqlQuery += ` AND created > ${created[0].format('YYYY-MM-DD')} AND created < ${created[1]
      .add(1, 'day')
      .format('YYYY-MM-DD')}`;
  }

  const params = { startAt, maxResults };
  const queryParams = queryString(removeEmpty(params));
  const url = `/pricing/jira/search?jql=${jqlQuery} ORDER BY updated DESC &${queryParams}&fields=*navigable,+comment`;
  const response = await request().get(url);
  const { issues, total } = response.data.data;

  let results = issues?.map((issue) => {
    const reporter = getReporter(issue?.fields?.description);

    return {
      reporter,
      key: issue?.key,
      summary: issue?.fields?.summary,
      assignee: issue?.fields?.assignee?.displayName,
      status: issue?.fields?.status?.name,
      priority: issue?.fields?.priority?.name,
      labels: formatLabels(issue?.fields?.labels.join(', ')),
      comments: issue?.fields?.comment?.total || '',
      createdAt: issue?.fields?.created,
      updatedAt: issue?.fields?.updated
    };
  });

  return { results, total };
};

export default {
  getTickets
};

import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, message, Row, Upload } from 'antd';
import React, { useState } from 'react';
import Button from '../../../components/Button';
import { surchargeService } from '../surcharges.service';

const Surcharge = () => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    surchargeService
      .uploadSurcharges(file)
      .then(() => {
        message.success('Surcharges uploaded successfully');
      })
      .catch((err) => {
        let error = 'Surcharges uploading failed.';
        if (err?.response?.data) {
          error = err?.response?.data?.data;
        }
        message.error(error);
      })
      .finally(() => {
        setFileName('');
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ width: '180px', marginLeft: 'auto' }}>
        <a href="/SurchargesTemplate.xlsx" download>
          <Button>Download Template</Button>
        </a>
      </div>
      <h6 style={{ marginBottom: '20px' }}>Upload Surcharges</h6>
      <Row>
        <Col span={8}>
          <Upload
            className="wrapper-upload"
            style={{ width: 250 }}
            accept=".xls, .xlsx"
            showUploadList={false}
            beforeUpload={(file) => {
              setFileName(file.name);
              setFile(file);
              return false;
            }}
          >
            <Button
              type="secondary"
              style={{ width: 250, overflow: 'hidden' }}
              icon={fileName ? <CloseOutlined /> : <UploadOutlined />}
            >
              {fileName || 'Upload'}
            </Button>
          </Upload>
        </Col>
        <Col span={8}>
          <Button style={{ width: 150 }} onClick={onSubmit} loading={loading} disabled={!fileName}>
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Surcharge;

import moment from 'moment';
import { formatToISOString, queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const getAllBillingRecords = async ({
  status = '',
  page = 1,
  pageSize = 10,
  trackingNumber = '',
  invoiceNumber = '',
  originCountry = '',
  destinationCountry = '',
  billingType = '',
  weightLimit = '',
  weightLimitStart = '',
  weightLimitEnd = '',
  merchantName = '',
  shippingType = '',
  action = '',
  fromDate = '',
  toDate = '',
  origin = '',
  destination = '',
  unitPrice = '',
  originZone = '',
  destinationZone = ''
}) => {
  const params = removeEmpty({
    billing_cycle: status,
    page,
    records: pageSize,
    invoiceId: invoiceNumber,
    tracking_no: trackingNumber,
    status: merchantName,
    originCountry,
    destinationCountry,
    billingType,
    weightLimitStart,
    weightLimitEnd,
    weightLimit,
    action,
    shipment_date: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
    toDate: formatToISOString(toDate),
    origin,
    destination,
    shippingType,
    unitPrice,
    originZone,
    destinationZone
  });

  const queryParams = queryString(params);
  const url = `/billing-records?${queryParams}`;
  const response = await request().get(url);
  const { billing_records, total } = response.data.data;
  const resultSet = billing_records.map((a) => {
    return {
      trackingId: a.tracking_no,
      shipmentDate: a.shipment_date,
      service: a.service,
      client: a.order_client_name,
      client_id: a.order_client_id,
      status: a.status,
      currency: a.currency,
      totalAmount: a.total_amount,
      billingCycle: a.billing_cycle,
      createdOn: a.created_on,
      invoiceNumber: `${a.invoice_ref_id}`,
      record: a,
      originZone: a.origin_zone,
      destinationZone: a.destination_zone,
      orderDirection: a.order_direction
    };
  });

  return {
    billing_records: resultSet.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

const descriptionFormatter = (item) => {
  if (item.billing_type === 1) {
    return `${item.origin_country} to ${item.destination_country} - ${item.weight_upper_bound} KG`;
  }
  if (item.billing_type === 3) {
    return `Duties and Taxes`;
  }
  if (item.billing_type === 100) {
    return `Pickup Fee charges`;
  }
  return `Surcharge (${item.origin_country} to ${item.destination_country})`;
};

const getBillingRecords = async (id) => {
  const url = `/invoices/${id}/billing-records`;
  const response = await request().get(url);
  let billingRecords = [];
  response.data.data.forEach((item) => {
    if (item.invoice_record_id) {
      billingRecords.push({
        record: item,
        description: descriptionFormatter(item),
        quantity: item.quantity,
        unitPrice: item.unit_price,
        tax: item.tax_amount,
        amount: `${item.total_amount} ${item.currency}`,
        service_type: item.service,
        order_direction: item.order_direction,
        origin_zone: item.origin_zone,
        destination_zone: item.destination_zone,
        pricingGroup: item.pricing_group
      });
    }
  });

  billingRecords = billingRecords.sort((a, b) => {
    return a.type - b.type;
  });

  return {
    invoice: billingRecords.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total: billingRecords.length
  };
};

const getInvoiceDetails = async (id) => {
  const url = `/invoices/${id}/details`;
  const response = await request().get(url);
  return response.data.data;
};

const getBillingFilters = async () => {
  const url = '/billing-records/filters';
  const response = await request().get(url);
  return response.data.data;
};

export default {
  getInvoiceDetails,
  getBillingFilters,
  getBillingRecords,
  getAllBillingRecords
};

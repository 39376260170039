import { PlusCircleTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Col, List, message, Modal, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, SearchInput } from '../../../components';
import { ROLES, VOLUMETRIC_WEIGHT_FACTORS } from '../../../constants';
import { getProfile } from '../../../utils/auth';
import { withRouter } from '../../../utils/wrappers';
import { getClientDetail } from '../client-details.action';
import { clientService } from '../client-details.service';

const StyledModal = styled.div`
  em.label {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
`;

const VolumetricWeightFactors = ({ params, mappedFactors = [] }) => {
  const dispatch = useDispatch();
  const { clientID } = params;
  const { roles } = getProfile();
  const isAdmin = roles.includes(ROLES.FINANCE);
  const [factor, setFactor] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [factors, setFactors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [viewAllFactors, setVeiwAllFactors] = useState(false);
  const [latestStartDate, setLatestStartDate] = useState(moment().subtract(1, 'days'));

  const disabledDate = (current) => {
    if (current > latestStartDate) {
      return current && current < moment().subtract(1, 'days').endOf('day');
    }

    return current && current < latestStartDate.endOf('day');
  };

  const handleFactor = (value) => {
    setFactor(value);
  };

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleAdd = () => {
    const key = `volumetric-weight-factor-${new Date().getTime()}`;
    message.loading({ content: 'Processing...', key });
    setLoading(true);

    if (!factor || !startDate) {
      setLoading(false);
      message.warn({ content: 'Please add volumetric weight factor and start date', key });
      return;
    }

    const body = {
      volumetric_weight_factor: {
        factor,
        start_date: startDate.format('YYYY-MM-DDT00:00:00') + 'Z'
      }
    };

    clientService
      .editClientConfig(clientID, body)
      .then(() => {
        setLoading(false);
        setVisible(false);
        message.success({ content: 'Added!', key });
        dispatch(getClientDetail(clientID));
      })
      .catch((err) => {
        setLoading(false);
        message.error({ content: 'Failed to process', key });
        console.error(err);
      });
  };

  const afterClose = () => {
    setFactor(undefined);
    setStartDate(undefined);
  };

  useEffect(() => {
    if (mappedFactors.length) {
      setFactors(mappedFactors.slice(0, 5));
      setLatestStartDate(moment.utc(mappedFactors[0].start_date));
    }
  }, [mappedFactors]);

  useEffect(() => {
    if (viewAllFactors) {
      setFactors(mappedFactors);
    } else {
      setFactors(mappedFactors.slice(0, 5));
    }
  }, [viewAllFactors]);

  return (
    <>
      <Modal
        title="Add Volumetric Weight Factor"
        visible={visible}
        onOk={handleAdd}
        onCancel={handleCancel}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" loading={loading} onClick={handleAdd}>
            Add
          </Button>
        ]}
      >
        <StyledModal>
          <em className="label">Volumetric Weight Factor</em>
          <SearchInput
            placeholder="Type or select..."
            id="volumetric-weight-factor"
            items={VOLUMETRIC_WEIGHT_FACTORS}
            value={factor}
            onChange={handleFactor}
          />
          <br />
          <em className="label">Start Date</em>
          <DatePicker
            id="start_date"
            value={startDate}
            onChange={handleStartDate}
            disabledDate={disabledDate}
          />
        </StyledModal>
      </Modal>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <h5>
            Volumetric Weight Factor &nbsp;
            {isAdmin ? (
              <Button
                type="link"
                icon={<PlusCircleTwoTone twoToneColor="#5050f9" />}
                onClick={() => setVisible(true)}
              />
            ) : (
              <div />
            )}
          </h5>
          <div>
            <List
              header={
                <Row gutter={[24, 16]}>
                  <Col span={12}>
                    <span className="header">Weight Factor</span>
                  </Col>
                  <Col span={6}>
                    <span className="header">Start Date</span>
                  </Col>
                  <Col span={6}>
                    <span className="header">End Date</span>
                  </Col>
                </Row>
              }
              footer={
                (factors.length !== mappedFactors.length || mappedFactors.length > 5) && (
                  <div style={{ textAlign: 'center' }}>
                    <>
                      {!viewAllFactors && (
                        <span className="view-link" onClick={() => setVeiwAllFactors(true)}>
                          View All
                        </span>
                      )}
                      {viewAllFactors && (
                        <span className="view-link" onClick={() => setVeiwAllFactors(false)}>
                          View Less
                        </span>
                      )}
                    </>
                  </div>
                )
              }
              bordered
              size="small"
              dataSource={factors}
              renderItem={(item) => (
                <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                  <Col span={12}>
                    <span className="value">{item.weight_factor}</span>
                  </Col>
                  <Col span={6}>
                    <span className="value">
                      {item.start_date ? moment.utc(item.start_date).format('DD MMM YYYY') : '-'}
                    </span>
                  </Col>
                  <Col span={6}>
                    <span className="value">
                      {item.end_date ? moment.utc(item.end_date).format('DD MMM YYYY') : '-'}
                    </span>
                  </Col>
                </Row>
              )}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(VolumetricWeightFactors);

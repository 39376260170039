import styled from '@emotion/styled';
import {
  Skeleton as AntSkeleton,
  Col,
  Divider,
  Modal,
  Row,
  Tooltip,
  message,
  notification
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionLink, Button, Card, Table } from '../../components';
import { ROLES } from '../../constants';
import { getProfile } from '../../utils/auth';
import request from '../../utils/request';
import { withRouter } from '../../utils/wrappers';
import { setInvoiceStatus } from '../billing-records/billing-records.action';
import {
  getBillingRecordsPerInvoice,
  getInvoiceDetail,
  resetDetails
} from './invoice-details.action';
import { ShipmentDetails } from './shared';
import WaiveOffModal from './shared/WaiveOffModal';

const { confirm } = Modal;

const StyledCard = styled(Card)`
  margin: 0px 30px 30px 30px;
  padding: 30px 40px;
`;

const StyledQuoteHeaderTitle = styled.div`
  a,
  a:hover {
    font-family: AvenirLTStdBookOblique;
    font-size: 16px;
    color: #050593;
    text-decoration: underline;
  }
`;

const DashedContainer = styled.div`
  border: 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  text-align: center;
  padding: 70px 0;
`;

const StyledNoContent = styled.p`
  font-size: 24px;
  font-family: AvenirLTStdBook;
  margin-bottom: 20px;
`;

const InvoiceDetails = (props) => {
  const { quoteId } = props.params;
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      fixed: 'left',
      width: 250
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 110,
      render: (text, record) => {
        if (record.record.billing_type === 100) {
          return record.quantity;
        }
        let route = '/billing-records';
        if (record.record.billing_type === 3) {
          route = `/billing-records/${quoteId}/${record.record.billing_type}`;
        } else if (record.record.weight_upper_bound) {
          route = `/billing-records/${quoteId}/${record.record.origin_country}/${record.record.destination_country}/${record.record.billing_type}/${record.record.unit_price}/${record.record.weight_upper_bound}`;
        } else {
          route = `/billing-records/${quoteId}/${record.record.origin_country}/${record.record.destination_country}/${record.record.billing_type}/${record.record.unit_price}`;
        }

        return <ActionLink to={route}>{record.quantity}</ActionLink>;
      }
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 110
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      width: 110,
      render: (text) =>
        Math.round((Number(text) + Number.EPSILON) * 100) / 100 == 0
          ? '-'
          : Math.round((Number(text) + Number.EPSILON) * 100) / 100
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 110
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      width: 130
    },
    {
      title: 'Order Direction',
      dataIndex: 'order_direction',
      key: 'order_direction',
      width: 140
    },
    {
      title: 'Origin Zone',
      dataIndex: 'origin_zone',
      key: 'origin_zone',
      width: 200
    },
    {
      title: 'Destination Zone',
      dataIndex: 'destination_zone',
      key: 'destination_zone',
      width: 200
    },
    {
      title: 'Pricing Group',
      dataIndex: 'pricingGroup',
      key: 'pricingGroup',
      width: 320,
      render: (text) => text || '-'
    }
  ];
  const { roles } = getProfile();
  const isAdmin = roles.includes(ROLES.FINANCE) || roles.includes(ROLES.DEV);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [waiveOffModalVisible, setWaiveOffModalVisible] = useState(false);
  const { data, loading: dataLoading } = useSelector((state) => state.invoiceDetails.invoice);
  const details = useSelector((state) =>
    state.invoiceDetails.invoice_detail ? state.invoiceDetails.invoice_detail : null
  );
  const [status, setStatus] = useState();

  useEffect(() => {
    if (details) {
      setStatus(details.status);
    }
  }, [details]);

  let updatedDataObj = null;

  useEffect(() => {
    if (data && data.length > 0 && columns) {
      columns[4].title = `Amount (${data[0].amount.split(' ')[1]})`;
      updatedDataObj = JSON.parse(JSON.stringify(data));
      updatedDataObj.forEach((entry) => {
        entry.amount = entry.amount.split(' ')[0];
        entry.key = entry.index;
      });
      setFilteredData(updatedDataObj);
    }
  }, [data]);

  useEffect(() => {
    dispatch(resetDetails());
    dispatch(getInvoiceDetail(quoteId));
    dispatch(getBillingRecordsPerInvoice(quoteId));
  }, [dispatch, quoteId]);

  const changePage = (page) => {
    const obj = [];
    const dataClone = JSON.parse(JSON.stringify(data));
    dataClone.forEach((entry, index) => {
      if (index >= 500 * (page - 1) && index < 500 * page) {
        entry.amount = entry.amount.split(' ')[0];
        entry.key = entry.index;
        obj.push(entry);
      }
    });
    setFilteredData(obj);
    setCurrentPage(page);
  };

  const createInvoicesHandler = async () => {
    const key = `create-invoice-${Date.now()}`;

    try {
      await request().post('/invoices/bulk-export-invoices', {
        invoices: [details.invoice_display_id]
      });

      notification.success({
        key,
        message: 'Invoice have now been sent for creation in Xero!',
        description:
          'This process may take up to 5 mins to complete. Check back again later to verify that there were no errors during this process.',
        placement: 'bottomLeft',
        duration: 0
      });
      setStatus(3);
    } catch (err) {
      console.error(err);
      notification.error({
        key,
        message: `Create invoice in xero failed`,
        description: `We're having some trouble creating the invoice. Refresh the page or try again.`,
        placement: 'bottomLeft',
        duration: 0
      });
    }
  };

  const actOnInvoice = () => {
    if (status === 2) {
      confirm({
        title: 'Are you sure you want to revert this invoice from Xero?',
        okText: 'Confirm',
        onOk: async () => {
          const url = `/pricing/revertinvoice`;
          const response = await request().post(url, {
            invoice_display_id: details.invoice_display_id
          });
          message.success('Invoices successfully reverted!');
          dispatch(setInvoiceStatus({ invoice_ids: [details.invoice_id] }));
          setStatus(1);
          return response;
        }
      });
    } else if (status === 1 || status === 4) {
      confirm({
        title:
          details?.remark === 'NON_COMPUTED_ORDERS' ? (
            <span>
              <b>Attention:</b>
              {` This Invoices include non-computed orders. Confirm if you still want to create this invoice in Xero.`}
            </span>
          ) : (
            'Are you sure you want to create this invoice in Xero?'
          ),
        okText: 'Confirm',
        onOk: async () => {
          await createInvoicesHandler(1);
        }
      });
    }
  };

  return (
    <StyledCard>
      {details ? (
        <div>
          <StyledQuoteHeaderTitle id="quote-header-title">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <h3>{details.invoice_display_id}</h3>
              <div>
                {status && (
                  <Row type="flex" sx={{ mb: '25px' }} gutter={16}>
                    {status === 1 && isAdmin && (
                      <Col sx={{ ml: 'auto' }}>
                        <Button
                          onClick={() => {
                            setWaiveOffModalVisible(true);
                          }}
                          danger
                        >
                          Waive-Off
                        </Button>
                      </Col>
                    )}
                    <Col sx={{ ml: 'auto' }}>
                      <Tooltip title={status === 3 ? 'In Progress' : ''}>
                        <span style={{ cursor: status === 3 ? 'not-allowed' : 'pointer' }}>
                          <Button onClick={actOnInvoice} disabled={status === 3}>
                            {status === 2 ? `Revert Invoice From Xero` : `Create Invoice In Xero`}
                          </Button>
                        </span>
                      </Tooltip>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </StyledQuoteHeaderTitle>
          {details.company_name && <ShipmentDetails invoice={details} invoiceId={quoteId} />}
        </div>
      ) : (
        <DashedContainer>
          <StyledNoContent>No invoice detail found for the invoice - {quoteId}</StyledNoContent>
        </DashedContainer>
      )}

      <Divider />

      <Row>
        <Table
          loading={dataLoading}
          totalCount={data.length}
          columns={columns}
          pageSize={500}
          renderEmptyTable
          onChange={(page) => changePage(page)}
          dataSource={filteredData}
          currentPage={currentPage}
          scroll={{ x: 2000 }}
          empty={
            <>
              {!dataLoading && (
                <div>
                  <span>No data found.</span>&nbsp;
                </div>
              )}
              {dataLoading && (
                <AntSkeleton
                  active
                  title={{ width: '100%' }}
                  paragraph={{
                    rows: 5,
                    width: ['100%', '100%', '100%', '100%', '100%']
                  }}
                />
              )}
            </>
          }
        />
        {details?.invoice_id && (
          <WaiveOffModal
            data={{ invoice_id: details?.invoice_id }}
            visible={waiveOffModalVisible}
            setVisible={setWaiveOffModalVisible}
          />
        )}
      </Row>
    </StyledCard>
  );
};

export default withRouter(InvoiceDetails);

import styled from '@emotion/styled';
import { Button as AntButton } from 'antd';
import React, { forwardRef } from 'react';

const StyledButton = styled(AntButton)`
  &.ant-btn-lg {
    /* Font alignment adjustment for AvenirLTStdBook */
    padding: 8px 30px 6px 30px;
    height: auto;
    span {
      padding-top: 0;
    }
  }

  &.ant-btn-sm {
    font-size: 14px;
  }

  &.ant-btn-secondary {
    border: 1px solid #050593;
    color: #050593;
    font-family: AvenirLTStdBook;
    font-size: 16px;
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      border: 1px solid #9b9bd4;
      color: #9b9bd4;
      background-color: #f5f5f5;
    }
  }

  &.ant-btn-link {
    color: #050593;
    &.reset-filter span {
      text-decoration: underline;
    }
  }

  &.ant-btn-primary {
    background: #050593 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 1px solid #050593;
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #ffffff;
    &:hover,
    &:focus,
    &:active {
      background-color: #050593;
      color: #fff;
      border: 1px solid #050593;
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background-color: #9b9bd4;
      color: #fff;
      border: 1px solid #9b9bd4;
    }
    &.ant-btn-dangerous[disabled],
    &.ant-btn-dangerous[disabled]:hover,
    &.ant-btn-dangerous[disabled]:focus,
    &.ant-btn-dangerous[disabled]:active {
      color: #00000040;
      border-color: #d9d9d9;
      background: #f5f5f5;
    }
    &.ant-btn-dangerous {
      border: 1px solid #ff4d4f;
      color: #ff4d4f;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      background-color: #ffffff;
    }
    &.success-btn {
      background-color: #52c41a;
      border: 1px solid #52c41a;
      color: #262626;
    }
    &.warning-btn {
      background-color: #ffa940;
      border: 1px solid #ffa940;
      color: #262626;
    }
    &.grey-btn {
      background-color: #091e420a;
      border: 1px solid #091e420a;
      color: #42526e;
    }
  }
`;

const Button = (props, ref) => {
  const { children, ...rest } = props;
  return (
    <StyledButton ref={ref} {...{ type: 'primary', ...rest }}>
      {children}
    </StyledButton>
  );
};

export default forwardRef(Button);

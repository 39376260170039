import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input, SearchInput } from '../../../components';
import { PRICING_GROUP_TYPES } from '../../../constants';
import { resetFilters, setFilters } from '../pricing-groups.action';

const FilterContainer = styled.div`
  user-select: none;
  width: 800px;
  height: 150px;
  padding: 20px 25px;
  &.filter-container {
    background: #fff;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    header {
      font-size: 16px;
      font-family: AvenirLTStdHeavy;
    }
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      .anticon {
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 120px;
      text-align: center;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      min-width: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 -8px 12px -8px !important;
  }
  .field-container {
    padding: 0 8px !important;
  }
  .inputRange {
    .rangeStart {
      border-right: 0px;
      width: 42%;
      text-align: center;
    }
    .rangeEnd {
      width: 42%;
      text-align: center;
      border-left: 0;
    }
    .rangeInputSplit {
      width: 16%;
      border-left: 0;
      border-right: 0;
      background-color: #fff;
      pointer-events: none;
    }
  }
`;

const DropdownContainer = styled.div`
  .ant-dropdown {
    z-index: 5;
  }
  .tooltip {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    .ant-popover-title {
      font-family: AvenirLTStdHeavy;
      border-bottom: 0;
      padding-top: 10px;
    }
    .ant-popover-inner {
      border-radius: 0;
      box-shadow: 0px 3px 6px #00000080;
    }
    .tooltip-content {
      width: 320px;
    }
  }
`;

const FilterDropdownPricingGroups = ({ onFilter = () => {} }) => {
  const node = useRef();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState();
  const pricingGroup = useSelector((state) => state.pricingGroups.filters.pricingGroup);
  const type = useSelector((state) => state.pricingGroups.filters.type);

  const handleDropdownClick = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    onFilter({});
    setVisible(false);
  };

  const handleFilter = () => {
    onFilter({
      pricingGroup,
      type: type === 'All' ? null : type
    });
    setVisible(false);
  };

  const handleInputChange = (field) => (e) => {
    dispatch(setFilters({ [field]: e.target.value }));
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setFilters({ [field]: option?.props?.value }));
  };

  useEffect(() => {
    const handleMenuClick = (e) => {
      if (
        !node.current.contains(e.target) &&
        !(e.target.tagName === 'path' || e.target.tagName === 'svg') // Hacky method to prevent filter dropdown close when clear select
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleMenuClick);
    return () => {
      document.removeEventListener('mousedown', handleMenuClick);
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <DropdownContainer ref={node}>
      <Dropdown
        visible={visible}
        label="Filter"
        onClick={handleDropdownClick}
        type={visible ? 'primary' : 'secondary'}
        getPopupContainer={() => node.current}
      >
        <FilterContainer className="filter-container">
          <Row gutter={[24, 16]} className="field-row">
            <Col span={18} className="field-container">
              <label htmlFor="pricing-group">Pricing Group</label>
              <Input
                id="pricing-group"
                placeholder="Type pricing group name ..."
                value={pricingGroup}
                onChange={handleInputChange('pricingGroup')}
              />
            </Col>
            <Col span={6} className="field-container">
              <label htmlFor="type">Type</label>
              <SearchInput
                id="type"
                placeholder="Type or select..."
                items={[
                  { key: 'All', value: 'All', label: 'All' },
                  ...Object.entries(PRICING_GROUP_TYPES).map((value, index) => ({
                    key: index,
                    value: value[0],
                    label: value[1]
                  }))
                ]}
                value={type}
                onChange={handleSearchInputChange('type')}
              />
            </Col>
          </Row>
          <div className="actions">
            <Button type="link" size="small" className="reset-filter" onClick={handleReset}>
              Reset filter
            </Button>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </FilterContainer>
      </Dropdown>
    </DropdownContainer>
  );
};

export default FilterDropdownPricingGroups;

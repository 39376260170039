import { queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const getPricingGroup = async (name) => {
  const params = removeEmpty({ size: 1, page: 1, pricing_group: name });
  const queryParams = queryString(params);
  const url = `/pricing/pricing-groups/effective-date?${queryParams}`;
  const response = await request().get(url);
  return response.data.data;
};

const getPricingGroupRules = async ({
  page = 1,
  size = 50,
  pricingGroup = '',
  currency = '',
  orderType = '',
  originZone = '',
  destinationZone = '',
  orderDirection = '',
  pricingType = '',
  weightUpperBound = ''
}) => {
  const params = removeEmpty({
    page,
    records: size,
    pricingGroup,
    currency,
    orderType,
    orderDirection,
    pricingType,
    originZone,
    destinationZone,
    weightUpperBound
  });
  params.weightUpperBound = JSON.stringify(params.weightUpperBound);
  const queryParams = queryString(params);
  const url = `/pricing/pricing-groups?${queryParams}`;
  const response = await request().get(url);
  const { pricing_groups, total } = response.data.data;

  const resultSet = pricing_groups.map((p) => {
    return {
      pricingGroup: p.pricing_group,
      currency: p.currency,
      originZone: p.origin_zone,
      destinationZone: p.destination_zone,
      weightUpperBound: p.weight_upper_bound,
      price: p.price,
      pricingType: p.pricing_type,
      orderType: p.order_type,
      effectiveDate: p.effective_date,
      createdOn: p.created_on,
      orderDirection: p.order_direction
    };
  });

  return {
    pricing_groups: resultSet.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

const getPricingGroupFilters = async () => {
  const url = '/pricing/pricing-groups/filters';
  const response = await request().get(url);
  return response.data.data;
};

export const pricingGroupRulesService = {
  getPricingGroup,
  getPricingGroupRules,
  getPricingGroupFilters
};

import papaparse from 'papaparse';
import { PRICING_GROUPS } from '../../constants/lambda-services';
import { invoke, upload } from '../../utils/aws-sdk';
import { queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const folderName = 'draft_pricing_groups';

const getPricingGroups = async ({ page = 1, size = 50, name = '', startDate = [] }) => {
  const params = removeEmpty({
    page,
    size,
    name,
    startDate: startDate.length
      ? [startDate[0].format('YYYY-MM-DDT00:00:00'), startDate[1].format('YYYY-MM-DDT23:59:59')]
      : null
  });

  const queryParams = queryString(params);
  const url = `/pricing-groups/manage?${queryParams}`;
  const response = await request().get(url);
  return response.data.data;
};

const addPricingGroup = async (body) => {
  const response = await upload(folderName, body.file);
  body.file = response.key;
  return await invoke(PRICING_GROUPS, 'createTempPricingGroups', body);
};

const editPricingGroup = async (name, body) => {
  if (body.file) {
    const response = await upload(folderName, body.file);
    body.file = response.key;
  }

  const res = await invoke(PRICING_GROUPS, 'editTempPricingGroup', { name, body });
  return res.data;
};

const approvePricingGroup = async (name) => {
  const res = await invoke(PRICING_GROUPS, 'approveTempPricingGroup', { name });
  return res.data;
};

const rejectPricingGroup = async (name) => {
  const res = await invoke(PRICING_GROUPS, 'rejectTempPricingGroup', { name });
  return res.data;
};

// eslint-disable-next-line no-unused-vars
const getFileChunk = async (file) => {
  const opt = { type: 'text/csv;charset=utf-8;' };
  const files = [];

  return new Promise((resolve, reject) => {
    papaparse.parse(file, {
      header: true,
      fastMode: true,
      skipEmptyLines: true,
      chunkSize: 1024 * 1024 * 1, //  split to 1 mb
      chunk: (chunk) => {
        const data = papaparse.unparse(chunk.data);
        const blob = new Blob([data], opt);
        const file = new File([blob], 'file.csv', opt);
        files.push(file);
      },
      complete: () => resolve(files),
      error: (err) => reject(err)
    });
  });
};

export default {
  getPricingGroups,
  addPricingGroup,
  editPricingGroup,
  approvePricingGroup,
  rejectPricingGroup
};

import { queryString, removeEmpty } from '../../utils/formatters';
import request from '../../utils/request';

const getPricingGroups = async ({ size = 50, page = 1, type = '', pricingGroup = '' }) => {
  const params = removeEmpty({ size, page, pricing_group: pricingGroup, pricing_group_type: type });
  const queryParams = queryString(params);
  const url = `/pricing/pricing-groups/effective-date?${queryParams}`;
  const response = await request().get(url);
  return response.data.data;
};

export const pricingGroupService = {
  getPricingGroups
};

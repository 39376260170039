import { createReducer } from '@reduxjs/toolkit';
import {
  changeBillingCycle,
  changeBillingCycleLoading,
  clearDimWeights,
  clearOrderStatuses,
  fetchChoices,
  fetchChoicesLoading,
  fetchDimWeights,
  fetchDimWeightsLoading,
  fetchOrders,
  fetchOrdersLoading,
  fetchOrderStatuses,
  fetchOrderStatusesLoading,
  fetchSyncOrders,
  fetchSyncOrdersLoading,
  resetFilters,
  setOrderFilters
} from './orders.action';

const initialState = {
  orders: {
    data: [],
    total: 0,
    loading: false,
    filtered: false
  },
  statuses: {
    data: [],
    loading: false
  },
  weights: {
    data: [],
    loading: false
  },
  syncOrders: {
    data: [],
    loading: false
  },
  changeCycles: {
    code: null,
    loading: false
  },
  filters: {
    trackingNumber: null,
    clientId: 'All',
    firstTouchDate: [],
    status: 'All'
  },
  choices: {
    clients: [],
    statuses: [],
    loading: false
  }
};

export default createReducer(initialState, {
  [fetchOrdersLoading](state, action) {
    state.orders.loading = action.payload;
  },
  [fetchOrders](state, action) {
    const { orders, total } = action.payload;
    state.orders = {
      data: orders,
      total,
      loading: false
    };
  },
  [clearOrderStatuses](state) {
    state.statuses = initialState.statuses;
  },
  [fetchOrderStatusesLoading](state, action) {
    state.statuses.loading = action.payload;
  },
  [fetchOrderStatuses](state, action) {
    state.statuses = {
      data: action.payload,
      loading: false
    };
  },
  [clearDimWeights](state) {
    state.weights = initialState.weights;
  },
  [fetchDimWeightsLoading](state, action) {
    state.weights.loading = action.payload;
  },
  [fetchDimWeights](state, action) {
    state.weights = {
      data: action.payload,
      loading: false
    };
  },
  [fetchSyncOrdersLoading](state, action) {
    state.syncOrders.loading = action.payload;
  },
  [fetchSyncOrders](state, action) {
    state.syncOrders.data = action.payload;
    state.syncOrders.loading = false;
  },
  [changeBillingCycleLoading](state, action) {
    state.changeCycles.code = null;
    state.changeCycles.loading = action.payload;
  },
  [changeBillingCycle](state, action) {
    state.changeCycles.code = action.payload;
    state.changeCycles.loading = false;
  },
  [fetchChoicesLoading](state, action) {
    state.choices.loading = action.payload;
  },
  [fetchChoices](state, action) {
    const { clients, statuses } = action.payload;
    state.choices = {
      clients: [
        ...clients.map((item) => ({
          key: item.client_id,
          value: item.client_id,
          label: item.client_name
        }))
      ],
      statuses: [
        ...statuses.map((item) => ({
          key: item.status,
          value: item.status,
          label: item.status
        }))
      ],
      loading: false
    };
  },
  [setOrderFilters](state, action) {
    state.filters = {
      ...state.filters,
      ...action.payload
    };
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
    state.orders = initialState.orders;
  }
});
